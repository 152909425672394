<template>
  <div class="toubao">
    <!-- 顶部 -->
    <div class="title-block">
        <div class="logo left">
            <img src="../../assets/souye/paic.jpg" alt="">
        </div>
        <div class="left">
            <div class="title_name">平安团体意外险（互联网）(JS)</div>
            <div class="title_company">产品由<span>中国平安财产保险股份有限公司</span>承保并负责理赔</div>
        </div>
    </div>
    <div style="height: 90px"></div>
    <!-- 中间内容 -->
    <div class="content">
        <div class="panel">
            <div style="padding: 0 20px; overflow: hidden;">
                <!-- 投保方案 -->
                <div class="calc">
                    <div class="calc_title">
                        <div class="title_text">投保方案</div>
                    </div>
                    <div class="plan-item">
                        <div class="item-name">投保方案：</div>
                        <div class="option-container">
                            <div class="option"  @click="list.a1=1" :class="{'active':list.a1===1}">行标/工标5</div>
                            <div class="option" @click="list.a1=2" :class="{'active':list.a1===2}">工标10</div>
                            <div style="clear:both;" class="zhuyis">
                                <div style="margin-right:20px;font-size:12px;display:inline-block;">
                                    推广费：<span style="color:#FF3333;font-size:16px;">28%</span>
                                </div>
                                <div class="zhuyi">
                                    <img src="../../assets/souye/icon_remind.png" alt="" style="width:20px;height:20px;vertical-align:middle;">
                                    <span style="color:#999999;font-size:12px;margin-left:4px;">特别提示：选择不同方案会影响推广费，请认真核对。</span>                                        

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="plan-item">
                        <div class="item-name">保险期间：</div>
                        <div class="option-container">
                            <div class="option" @click="list.a2=1" :class="{'active':list.a2===1}">1年</div>
                            <div class="option" @click="list.a2=2" :class="{'active':list.a2===2}">1个月</div>
                            <div class="option" @click="list.a2=3" :class="{'active':list.a2===3}">3个月</div>
                            <div class="option" @click="list.a2=4" :class="{'active':list.a2===4}">6个月</div>
                        </div>
                    </div>  
                    <div class="plan-item">
                        <div class="item-name">伤残比例：</div>
                        <div class="option-container">
                            <div class="option" @click="list.a3=1" :class="{'active':list.a3===1}">行标十级10%起</div>
                            <div class="option" @click="list.a3=2" :class="{'active':list.a3===2}">工标10级5%起</div>
                            <div class="option active":class="{'disabled':list.a1===1}">工标10级10%起</div>
                        </div>
                    </div>
                    <div class="plan-item">
                        <div class="item-name">民航客机意外：</div>
                        <div class="option-container">
                            <div class="option" @click="list.a4=1" :class="{'active':list.a4===1}">不购买</div>
                            <div class="option" @click="list.a4=2" :class="{'active':list.a4===2}">20万</div>
                            <div class="option" @click="list.a4=3" :class="{'active':list.a4===3}">50万</div>
                            <div class="option" @click="list.a4=4" :class="{'active':list.a4===4}">100万</div>
                        </div>
                    </div>  
                    <div class="plan-item">
                        <div class="item-name">客运轨道交通意外：</div>
                        <div class="option-container">
                            <div class="option" @click="list.a5=1" :class="{'active':list.a5===1}">不购买</div>
                            <div class="option" @click="list.a5=2" :class="{'active':list.a5===2}">10万</div>
                            <div class="option" @click="list.a5=3" :class="{'active':list.a5===3}">20万</div>
                            <div class="option" @click="list.a5=4" :class="{'active':list.a5===4}">30万</div>
                        </div>
                    </div> 
                    <div class="plan-item">
                        <div class="item-name">客运轮船意外：</div>
                        <div class="option-container">
                            <div class="option" @click="list.a6=1" :class="{'active':list.a6===1}">不购买</div>
                            <div class="option" @click="list.a6=2" :class="{'active':list.a6===2}">10万</div>
                            <div class="option" @click="list.a6=3" :class="{'active':list.a6===3}">20万</div>
                            <div class="option" @click="list.a6=4" :class="{'active':list.a6===4}">30万</div>
                        </div>
                    </div> 
                    <div class="plan-item">
                        <div class="item-name">客运机动车意外：</div>
                        <div class="option-container">
                            <div class="option" @click="list.a7=1" :class="{'active':list.a7===1}">不购买</div>
                            <div class="option" @click="list.a7=2" :class="{'active':list.a7===2}">10万</div>
                            <div class="option" @click="list.a7=3" :class="{'active':list.a7===3}">20万</div>
                        </div>
                    </div> 
                </div>
                <!-- 保障权益 -->
                <div class="clause">
                    <div class="clause_title">
                        <div class="title_text">保障权益</div>
                    </div>
                    <div class="clause_item">
                        <div class="item_title" @click="yiwais=!yiwais">
                            <div class="item_name">意外伤害（身故/残疾）</div>
                            <div class="item_value">
                                <div class="accident_die">10万</div>
                                <div class="dropdown">
                                    <img src="../../assets/souye/explain.png" alt="">
                                </div>
                                <img class="arrow-down" v-if="yiwais===true" src="../../assets/souye/graphical.png" alt="">
                            </div>
                        </div>
                        <div class="item_desc" v-if="yiwais===true">在保险期间内，被保险人因遭受意外伤害事故导致身故或伤残 保险人按照保单约定给付保险金。</div>
                    </div>
                    <div class="clause_item">
                        <div class="item_title" @click="yiwaiy=!yiwaiy">
                            <div class="item_name">意外医疗</div>
                            <div class="item_value">
                                <div class="accident_die">1万</div>
                                <div class="dropdown">
                                    <img src="../../assets/souye/explain.png" alt="">
                                </div>
                                <img class="arrow-down" v-if="yiwaiy===true" src="../../assets/souye/graphical.png" alt="">
                            </div>
                        </div>
                        <div class="item_desc" v-if="yiwaiy===true">每次事故绝对免赔100元，超过部分在医保核算范围按100%赔付。</div>
                    </div>
                    <div class="clause_item">   
                        <div class="item_title" @click="yiwaiz=!yiwaiz">
                            <div class="item_name">意外住院津贴</div>
                            <div class="item_value">
                                <div class="accident_die"></div>
                                <div class="dropdown">
                                    <img src="../../assets/souye/explain.png" alt="">
                                </div>
                                <img class="arrow-down" v-if="yiwaiz===true" src="../../assets/souye/graphical.png" alt="">
                            </div>
                        </div>
                        <div class="item_desc" v-if="yiwaiz===true">每次事故最高赔偿天数90天，保险期限内累计赔偿天数180天，绝对免赔3天。</div>
                    </div>
                </div>
                <!-- 起保日期 -->
                <div class="qbrq">
                    <div class="clause_title">
                        <div class="title_text">起保日期</div>
                    </div>
                    <div class="row">
                        <span class="col-md-1 text-tit ui-sortable">起始时间</span>
                        <el-date-picker
                            v-model="currentValue"
                            type="date"
                            format="yyyy-MM-dd"  
                            value-format="yyyy-MM-dd"  
                            @change="addOneYear"
                            placeholder="选择日期">
                            </el-date-picker>
                        <span class="col-md-1 text-tit ui-sortable">截止时间</span>
                        <div class="col-md-2 ui-sortable"><input type="text" class="form-control time" disabled :placeholder="futureValue"></div>
                        <span style="text-align:left;" class="col-md-1 text-tit ui-sortable">
                            共<label>365</label>天
                        </span>
                    </div>
                </div>
                <!-- 投保人信息 -->
                <div class="clause_title">
                        <div class="title_text">投保人信息</div>
                    </div>
                <div class="insure-holder">
                    <div class="row">
                        <span class="col-md-1 text-tit apply_image_text ui-sortable">营业执照</span>
                        <el-upload
                            class="upload-demo"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                             multiple
                            :limit="3"
                            :on-exceed="handleExceed"
                            :file-list="fileList"
                            >
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload>
                        <div v-for="item in fileList" :key="item">
                            {{ fileList.name }}
                            <!-- <img :src="item.url" alt=""> -->
                        </div>
                    </div>
                    <div class="row">
                        <span class="col-md-1 text-tit ui-sortable">证件类型</span>
                        <div class=" ui-sortable">
                            <select name="" id="" class="form-control">
                                <option value="31">社会统一信用代码</option>
                                <option value="30">注册号</option>
                            </select>
                        </div>
                        <span class="col-md-1 text-tit ui-sortable">证件号码</span>
                        <div class="col-md-2 ui-sortable">
                            <input type="text" class="form-control" style="width: 190px;" placeholder="请输入证件号码">
                        </div>
                        <span class="col-md-1 text-tit apply_name_text ui-sortable" style="width: 90px;">单位名称</span>
                        <div class="col-md-4 ui-sortable">
                            <div style="width: 48%; float: left;">
                                <input type="text" placeholder="请填写单位全称或输入名称搜索" class="form-control">
                            </div>
                            <!-- <div class="sousuo">搜索</div> -->
                        </div>
                    </div>
                    <div class="row">
                        <span class="col-md-1 text-tit  ui-sortable">详细地址</span>
                        <div class="col-md-4  ui-sortable" style="width: 33.33%;">
                            <input type="text" placeholder="请输入详细地址" class="form-control w33"> 
                        </div>  
                    </div>
                </div>
                <!--发票信息  -->
                <div class="clause_title">
                    <div class="title_text">发票信息</div>
                </div>
                <div class="insure-holder">
                    <div class="row">
                        <span class="col-md-1 text-tit ui-sortable">开票类型</span>
                        <div class="ui-sortable">
                            <select name="" id="" class="form-control" @change="fapiao($event)">
                                <!-- <option v-for="(item,index) in productList" :key="index" :value="item.id" v-text="item.val"></option>  -->
                                <option value="0" text="暂不开票">暂不开票</option>
                                <option value="1" text="电子普票">电子普票</option>
                                <option value="2" text="专票">专票(免税部分开普票，专票部分预计占总保费40%以内)</option>
                            </select>
                        </div>
                        <div v-if="dzfp===1 || dzfp===2">
                            <span class="col-md-1 text-tit ui-sortable">发票抬头</span>
                            <div class="col-md-2 ui-sortable" style="width: 185px;"><input style="width: 185px;" type="text" class="form-control" placeholder="请输入发票抬头"></div>
                            <span class="col-md-1 text-tit ui-sortable">企业税号</span>
                            <div class="col-md-2 ui-sortable" style="width: 185px;">
                                <input type="text" style="width: 185px;" class="form-control" placeholder="请输入纳税人号码">
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if=" dzfp===2">
                        <span class="col-md-1 text-tit ui-sortable">开户银行</span>
                        <div class="col-md-2 ui-sortable" style="width: 185px;"><input style="width: 180px;" type="text" class="form-control" placeholder="请输入开户行"></div>
                        <span class="col-md-1 text-tit ui-sortable">开户账号</span>
                        <div class="col-md-2 ui-sortable" style="width: 185px;">
                            <input type="text" style="width: 185px;" class="form-control" placeholder="请输入开户行帐号">
                        </div>
                        <span class="col-md-1 text-tit ui-sortable">公司电话</span>
                        <div class="col-md-2 ui-sortable" style="width: 185px;">
                            <input type="text" style="width: 185px;" class="form-control" placeholder="请输入公司电话">
                        </div>
                    </div>
                    <div class="row"v-if=" dzfp===2">
                        <span class="col-md-1 text-tit ui-sortable">公司地址</span>
                        <div class="col-md-2 ui-sortable" style="width: 300px;">
                            <input style="width: 545px;" type="text" class="form-control" placeholder="专票上的公司地址">
                        </div>
                    </div>
                </div>
                <!-- 联系人信息 -->
                <div class="clause_title">
                    <div class="title_text">联系人信息</div>
                </div>
                <div class="insure-holder">
                    <div class="row">
                        <span class="col-md-1 text-tit ui-sortable">联系人姓名</span>
                        <div class="col-md-2 ui-sortable" style="width: 185px;"><input type="text" class="form-control" placeholder="请输入联系人姓名" style="width: 185px;"></div>
                        <span class="col-md-1 text-tit ui-sortable">手机号码</span>
                        <div class="col-md-2 ui-sortable" style="width: 185px;"><input style="width: 185px;" type="text" class="form-control" placeholder="请输入手机号码"></div>
                        <span class="col-md-1 text-tit ui-sortable">电子邮箱</span>
                        <div class="col-md-2 ui-sortable" style="width: 185px;"><input type="text" style="width: 185px;" class="form-control" placeholder="请输入电子邮箱"></div>
                    </div>
                    <div class="row" v-if=" dzfp===2">
                        <span class="col-md-1 text-tit ui-sortable">地址</span>
                        <div class="ui-sortable">
                            <select name="" id="" class="form-control" style="width: 150px;margin-right: 5px; margin-bottom: 10px;">
                                <option value="0">暂不开票</option>
                                <option value="1">电子普票</option>
                                <option value="2">专票(免税部分开普票，专票部分预计占总保费40%以内)</option>
                            </select>
                            <select name="" id="" class="form-control" style="width: 150px;margin-right: 5px;margin-bottom: 10px;">
                                <option value="0">暂不开票</option>
                                <option value="1">电子普票</option>
                                <option value="2">专票(免税部分开普票，专票部分预计占总保费40%以内)</option>
                            </select>
                            <select name="" id="" class="form-control" style="width: 150px;margin-bottom: 10px;">
                                <option value="0">暂不开票</option>
                                <option value="1">电子普票</option>
                                <option value="2">专票(免税部分开普票，专票部分预计占总保费40%以内)</option>
                            </select>
                        </div>
                        <div class="col-md-4 ui-sortable">
                            <input type="text" class="form-control" placeholder="请输入详细地址" style="width: 420px;margin-left: 30px;">
                        </div>
                    </div>
                    <div class="row contact-address-tip" style="margin-top: -10px">
                        <div class="col-md-11 ui-sortable">
                            <div class="upload-tip">
                                <div class="upload-tip-icon">
                                    <img src="../../assets/souye/icon_remind.png" alt="">
                                </div>
                                <span class="id_card_image_upload_tip">保司将根据联系人地址直接邮寄发票，请填写正确地址（随意填写会导致发票丢失）</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 人员信息 -->
                <div class="clause_title">
                    <div class="title_text">人员信息</div>
                    <div style="margin-bottom: 10px; margin-left: 4.5%;">
                        <span class="dianjiyangshi">下载模板</span>  
                        <span class="dianjiyangshi">职业类别表</span>  
                    </div>
                </div>
                <div class="biaoge">
                    <div class="biaogr-top">
                        <div class="zyfa">职业方案1</div>
                        <div class="qxzsj" @click="zhiyfa=true"><input type="text" placeholder="请选择"></div>
                        <div class="jq"><input type="text" placeholder="加费（元）"></div>
                        <div class="renshu">共<span>0</span>人</div>
                        <div class="meiren">245元/人</div>
                        <span class="dianjiyangshi" style="margin-top: 0;">点击上传</span> 
                        <span class="dianjiyangshi" style="margin-top: 0;" @click="tjbbr=true">添加被保人</span> 
                        <span class="sanchu" style="margin-top: 0;">删除</span> 
                    </div>
                    <div class="insured-calc-block">
                        <div class="plan-item">
                            <div class="item-name">保障内容：</div> 
                            <div class="option-container">
                                <div class="option">10万+1万</div>
                                <div class="option">20万+2万</div>
                                <div class="option">30万+3万</div>
                                <div class="option">40万+4万</div>
                                <div class="option">50万+5万</div>
                                <div class="option">60万+6万</div>
                                <div class="option">70万+7万</div>
                                <div class="option">80万+8万</div>
                                <div class="option">90万+9万</div>
                                <div class="option">100万+10万</div>
                            </div>
                        </div>                        
                        <div class="plan-item">
                            <div class="item-name">意外住院津贴：</div> 
                            <div class="option-container">
                                <div class="option">不购买</div>
                                <div class="option">50元/天</div>
                                <div class="option">100元/天</div>
                                <div class="option">150元/天</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="biaoges" >
                    <el-table
                        :data="tableData"
                        :header-cell-style="{'text-align':'center'}"
                        border
                        style="width: 100%">
                        <el-table-column
                            prop="date"
                            align="left"
                            width="135"
                            label="姓名">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            align="center"
                            width="240"
                            label="	证件类型">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            align="center"
                            width="240"
                            label="证件号码">
                        </el-table-column>
                        <el-table-column
                            prop="baofei"
                            width="135"
                            align="center"
                            label="性别">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            width="135"
                            align="center"
                            label="生日">
                        </el-table-column>
                        <el-table-column
                            prop="baofei"
                            align="center"
                            width="135"
                            label="操作">
                        </el-table-column>
                        </el-table>
                </div>
                <!--  -->
                <div class="add_person_info">
                    <img src="../../assets/souye/icon_add_person_info.png" alt="">添加新职业方案
                </div>
            </div>
        </div>
    </div>
    <!-- 底部 -->
    <div class="immediatelyJoin-immediatelyJoin"></div>
    <div class="immediatelyJoin">
        <div class="proposal_btn">提交订单</div>
        <div class="proposal_btn" style="background-color: #f59c1a;">暂存订单</div>
        <div class="dibu">
            保险费：￥
            <span>0元/人起</span>元
        </div>
    </div>
    <!-- 点击人员信息-职业方案1弹窗 -->
    <div class="menban" v-if="zhiyfa===true">
        <div class="position_search_dialog">
            <div class="position_search_dialog_title">选择职业信息
                <i class="close_search_pane" @click="zhiyfa=false"></i>
            </div>
            <div class="position_search_ctrl_wrap">
                <div style="overflow: hidden;">
                    <input type="text" class="position_search_input" placeholder="请输入职业名称">
                    <span class="search_position_btn">搜索</span>
                </div>
                <div class="search_position_select_wrap">
                    <select name="" id="">
                        <option>请选择</option>
                        <option value="1">一般职业</option>
                    </select>
                    <select name="" id="">
                        <option>请选择</option>
                        <option value="1">一般职业</option>
                    </select>
                    <select name="" id="">
                        <option>请选择</option>
                        <option value="1">一般职业</option>
                    </select>
                 </div>
                 <div class="position_edit_wrap">
                    <div class="level_str">所选职业类别：
                        <span style="color: rgb(51, 51, 51);"></span>
                    </div>
                 </div>
                 <div class="search_position_result_text">
                    搜索结果：<span class="search_position_count">0</span>条
                 </div>
            </div>
            <div class="search_position_result_wrap">
                <div v-if="false" class="no_position_result">
                    <img src="../../assets/souye/nodata.png" alt="">
                    <p style="margin-top: 10px;font-size: 16px;color: #333;">抱歉，没有找到职业信息</p>
                </div>
                <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
                    <li v-for="i in count" class="infinite-list-item" :key="i">
                        <div class="icon_position"></div> 
                        <div class="position_content">
                            <div class="position_text">建筑公司负责人（2类）</div>
                            <div class="parent_position_text">所属大分类：建筑行业</div>
                            <div class="parent_position_text">所属中分类：一般外勤人员</div>
                        </div>   
                        <!-- actim 点击后添加 -->
                        <div class="icon_check actim"></div>
                    </li>
                </ul>
            </div>
            <div class="search_position_dialog_foot">
                <div class="ok-btn position-btn">确定</div>
                <div class="cancel-btn position-btn" @click="zhiyfa=false">取消</div>
            </div>
        </div>
    </div>
    <!-- 点击人员信息-添加被保人 -->
    <div class="menban" v-if="tjbbr===true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" @click="tjbbr=false"><span >× </span></button>
                    <h4 class="modal-title">添加被保人</h4>
                </div>
                <div class="form-horizontal">
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="" class="col-sm-3 control-label ui-sortable">姓&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp名</label>
                            <div class="col-sm-8 ui-sortable">
                                <input type="text" placeholder="请填写姓名" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="" class="col-sm-3 control-label ui-sortable">证件类型</label>
                            <div class="col-sm-8 ui-sortable">
                                <select name="" id="" class="form-control">
                                    <option value="0">身份证</option>
                                    <option value="1">护照</option>
                                    <option value="2">香港居民居住证</option>
                                    <option value="3">澳门居民居住证</option>
                                    <option value="4">台湾居民居住证</option>
                                    <option value="5">港澳通行证</option>
                                    <option value="6">台胞证</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="" class="col-sm-3 control-label ui-sortable">证件号码</label>
                            <div class="col-sm-8 ui-sortable">
                                <input type="text" placeholder="请填写姓名" class="form-control">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="" class="col-sm-3 control-label ui-sortable">姓&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp别</label>
                            <div class="col-sm-8 ui-sortable">
                                <select name="" id="" class="form-control">
                                    <option value="0">男</option>
                                    <option value="1">女</option>   
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="" class="col-sm-3 control-label ui-sortable">出生日期</label>
                            <div class="col-sm-8 ui-sortable">
                                <el-date-picker
                                v-model="value1"
                                type="date"
                                placeholder="请选择出生日期">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-info save_insured">保存</button>
                        <button class="btn btn-default-modal"  @click="tjbbr=false">关闭</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            yiwais:false,
            yiwaiy:false,
            yiwaiz:false,
            currentValue: new Date(), // 当前选择的日期，默认为今天  
            futureValue: null, // 一年后的日期  
            fileList: [
                {
                    name: 'food.jpeg', 
                    url: ""
                },
            ],
            dzfp:0,
            zhiyfa:false,
            tjbbr:false,
            count:28,
            value1:'',
            list:{
                a1:1,
                a2:1,
                a3:1,
                a4:1,
                a5:1,
                a6:1,
                a7:1,
            }
        }
    },
    mounted() {  
        // 在组件挂载后，计算一年后的日期并赋值给 futureValue（如果需要的话）  
        this.addOneYear();  
    }, 
    computed:{
        // 接收上一级传的值
        paramValue() {  
            return this.$route.params.param;  
        } ,


    },
    methods: {  
        // 选择日期 
        addOneYear() {  
        // 获取当前选择的日期对象  
        const currentDate = new Date(this.currentValue);  
        // 设置年份为当前年份加1  
        currentDate.setFullYear(currentDate.getFullYear() + 1);  
        // 将计算后的日期转换为字符串格式，并赋值给 futureValue  
        this.futureValue = this.formatDate(currentDate);  
        },  
        formatDate(date) {  
        // 格式化日期为 "yyyy-MM-dd" 格式  
        const year = date.getFullYear();  
        const month = String(date.getMonth() + 1).padStart(2, '0');  
        const day = String(date.getDate()).padStart(2, '0');  
        return `${year}-${month}-${day}`;  
        }, 
        // 上传
        // handleRemove(file, fileList) {
        //     console.log(file, fileList);
        // },
        // handlePreview(file) {
        //     console.log(file);
        // },
        // handleExceed(files, fileList) {
        //     this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        // },
        // beforeRemove(file, fileList) {
        //     return this.$confirm(`确定移除 ${ file.name }？`);
        // } 
        fapiao(event){
            var _this = this
            this.currentId = event.target.value; //获取option对应的value值
            if(this.currentId==0){
                this.dzfp=0
            }else if(this.currentId==1){
                this.dzfp=1
            }else if(this.currentId==2){
                this.dzfp=2
            }
        }
    }, 
}
</script>

<style scoped lang="scss">
.left{
    float: left;
}
.toubao{
    background: #d9e0e7;
    color: #707478;
    // 顶部显示
    .title-block {
        background: white;
        position: fixed;
        z-index: 2;
        width: 100%;
        height: 90px;
        border-bottom: solid 1px #F0EFF5;
        .logo {
            width: 60px;
            height: 60px;
            margin: 15px 20px 15px 46px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .title_name {
            margin-top: 22px;
            font-size: 16px;
            font-weight: 400;
            color: #333;
        }
        .title_company {
            margin-top: 5px;
            font-size: 12px;
            font-weight: 400;
            color: #999;
            span{
                color: #151515;
            }
        }
    }
    // 投保方案
    .content{
        padding: 20px 25px;
        .panel{
            border-radius: 3px;
            margin-bottom: 20px;
            background-color: #fff;
            .calc{
                background: white;
                overflow: hidden;
                .calc_title{
                    padding: 17px 0;
                    border-bottom: 1px solid #F0EFF5;
                    .title_text{
                        height: 14px;
                        line-height: 14px;
                        padding-left: 10px;
                        border-left: solid 4px red;
                        color: #333;
                        font-weight: 400;
                        font-size: 14px;
                    }
                }
                .plan-item {
                    padding-top: 10px;
                    vertical-align: top;
                    overflow: hidden;
                    .item-name{
                        width: 120px;
                        font-size: 12px;
                        color: #333333;
                        font-weight: 400;
                        padding: 5px 0;
                        line-height: 20px;
                        float: left;
                        position: relative;
                        cursor: default;
                    }
                    .option-container{
                        width: calc(100% - 160px);
                        margin-left: 10px;
                        overflow: hidden;
                        float: left;
                        .option{
                            min-width: 100px;
                            border-radius: 4px;
                            text-align: center;
                            font-size: 12px;
                            line-height: 20px;
                            color: #666666;
                            box-sizing: border-box;
                            border: 1px solid #DCDCDC;
                            margin-right: 15px;
                            margin-bottom: 10px;
                            cursor: pointer;
                            padding: 5px 15px;
                            float: left;
                        }
                        .active{
                            background-color: white;
                            border: 1px solid #fc6703;
                            color: #fc6703;
                            background-image: url(../../assets/souye/icon.png);
                            background-position: 108% -90%;
                            background-size: 25px 25px;
                            background-repeat: no-repeat;
                        }
                        .zhuyi{
                            float: left;
                            display: flex;
                            align-items: center;
                            background-color: #FFF7F8;
                            color: #666666;
                            font-size: 14px;
                            padding: 9px;
                            border-radius: 10px;
                        }
                        .zhuyis{
                            float: left;
                            display: flex;
                            align-items: center;
                        }
                        .disabled{
                            background: #DCDCDC;
                            border: #DCDCDC solid 1px;
                            color: white;
                            cursor: not-allowed;
                        }
                    }
                    input{
                        width: calc(100% - 220px);
                        line-height: 30px;
                        height: 30px;
                        border: solid 1px #DCDCDC;
                        outline: none;
                        border-radius: 4px;
                        padding: 0 5px;
                        margin-left: 10px;
                    }
                }
            }
            //投保人信息 
            .clause_title{
                padding: 17px 0;
                border-bottom: 1px solid #F0EFF5;
                border-top: 1px solid #F0EFF5;
                display: flex;
                align-items: center;
                .title_text{
                    height: 14px;
                    line-height: 14px;
                    padding-left: 10px;
                    border-left: solid 4px red;
                    color: #333;
                    font-weight: 400;
                    font-size: 16px;
                }
            }
            //保障权益
            .clause{
                background: white;

                .clause_item {
                    .item_title{
                        padding: 9px 16px;
                        overflow: hidden;
                        border-bottom: 1px solid #F0EFF5;
                        .item_name{
                        float: left;
                        width: 50%;
                        font-size: 12px;
                        color: #333;
                        }
                        .item_value{
                            float: right;
                            position: relative;
                            padding-right: 20px;
                            text-align: right;
                            .accident_die{
                                padding: 2px 4px 1px 4px;
                                font-size: 14px;
                                color: #fc6703;
                                border-radius: 4px;
                            }
                            .dropdown{
                                position: absolute;
                                right: 0;
                                top: 2px;
                                height: 18px;
                                width: 18px;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .arrow-down{
                                position: absolute;
                                width: 14px;
                                height: 8px;
                                z-index: 100;
                                bottom: -9px;
                                right: 2px;
                                display: none;
                            }
                        }
                    }
                    .item_desc{
                        padding: 5px 16px;
                        line-height: 20px;
                        font-size: 12px;
                        color: #999;
                        background-color: #F0EFF5;
                    }

                }
            }
            // 起保日期
            .qbrq{
                overflow: hidden;
                background: white;

                .row {
                    margin: 10px 0;
                    .form-control{
                        background: #fff;
                        opacity: .6;
                        border: 1px solid #e2e7eb;
                        font-size: 12px;
                        outline: none;
                    }
                }
            }
            //人员信息
            .insure-holder{
                .row{
                    margin: 10px -10px;
                    overflow: hidden;
                    .info_title{
                        margin-top: 20px;
                        font-size: 16px;
                        clear: both;
                        em{
                            width: 5px;
                            background-color: red;
                            height: 16px;
                            float: left;
                            margin-top: 3px;
                            margin-right: 5px;
                        }
                    }
                    .col-md-2 {
                        width: 207px;
                    }
                    .form-control{
                        float: left;
                        width: 207px;
                        border-color: #fff;
                        border: 1px solid #e2e7eb;
                        box-shadow: none;
                        font-size: 12px;
                        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                        outline: none;
                        border-radius: 5px;
                    }
                    .sousuo{
                        float: left;
                        width: 53px;
                        height: 33px;
                        background-color: #fc6703;
                        border-radius: 3px;
                        margin-left: 10px;
                        text-align: center;
                        line-height: 33px;
                        color: #fff;
                        font-size: 14px;
                    }
                    .w33{
                        width: 100%;
                    }
                }
                .contact-address-tip{
                    margin-left: 140px;
                    .col-md-11{
                        float: left;
                        .upload-tip{
                            padding: 12px 14px;
                            line-height: 20px;
                            font-size: 14px;
                            color: #999999;
                            box-sizing: border-box;
                            background: #FFF7F8;
                            border-radius: 6px;
                            display: inline-block;
                            .upload-tip-icon{
                                width: 20px;
                                height: 20px;
                                font-size: 0;
                                margin-right: 10px;
                                float: left;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            span{
                                line-height: 20px;
                                font-size: 14px;
                                color: #999999;
                            }
                        }
                    }
                }
            }
            .biaoge{
                width: 1000px;
                height: 135px;
                background-color: rgb(229, 242, 255);
                margin-left: 4%;
                padding: 15px 10px;
                border: 1px solid #ccc;
                .biaogr-top{
                    display: flex;
                    align-items: center;
                    .zyfa {
                        margin-left: 26px;
                    }
                    .qxzsj{
                        width: 320px;
                        margin-left: 20px;
                        input{
                            width: 100%;
                            background: white ;
                            height: 34px;
                            font-size: 12px;
                            line-height: 34px;
                            background-image: url(../../assets/souye/icon_dropdown.png) ;
                            background-position-y: 13px ;
                            background-position-x: 298px ;
                            background-size: 14px 8px ;
                            background-repeat: no-repeat ;
                            opacity: 1 ;
                            border: solid 1px #fc6703;
                            outline: none;
                            padding: 5px;
                            box-sizing: border-box;
                            border-radius: 2px;
                        }
                    }
                    .jq{
                        width: 70px;
                        input{
                            width: 100%;
                            background: white;
                            height: 34px;
                            margin-left: 14px;
                            outline: none;
                            font-size: 12px;
                            border: solid 1px #fc6703;
                            padding: 5px;
                            box-sizing: border-box;
                            border-radius: 2px;
                        }
                    }
                    .renshu{
                        color: #242a30;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    .meiren{
                        color: #242a30;
                        margin-right: 5px;
                    }
                    .sanchu{
                        color: #fff;
                        background: #f59c1a;
                        border-color: #f59c1a;
                        padding: 6px 12px;
                        margin-bottom: 0;
                        font-size: 14px;
                        text-align: center;
                        cursor: pointer;
                        border: 1px solid transparent;
                        border-radius: 5px;
                    }
                    .sanchu:hover{
                        background: #c47d15;
                        border-color: #c47d15;
                        
                    }
                }
                .insured-calc-block{
                    margin-top: 20px;
                    .plan-item{
                        padding-top: 10px;
                        vertical-align: top;
                        position: relative;
                        overflow: hidden;
                        .item-name{
                            font-size: 14px;
                            width: 120px;
                            color: #333333;
                            font-weight: 400;
                            text-align: left;
                            margin-left: 24px;
                            padding: 5px 0;
                            line-height: 20px;
                            float: left;
                            position: relative;
                            cursor: default;
                        }
                        .option-container{
                            width: calc(100% - 160px);
                            margin-left: 10px;
                            overflow: hidden;
                            float: left;
                            .option{
                                min-width: 100px;
                                height: 30px;
                                line-height: 26px;
                                border-radius: 4px;
                                text-align: center;
                                font-size: 12px;
                                color: #666666;
                                box-sizing: border-box;
                                border: 1px solid #DCDCDC;
                                background: white;
                                margin-right: 15px;
                                margin-bottom: 10px;
                                cursor: pointer;
                                padding: 0 15px;
                                float: left;
                                user-select: none;
                            }
                        }
                    }
                }
            }
            .biaoges{
                margin-left: 4%;
            }
            .add_person_info{
                width: 168px;
                height: 36px;
                margin: 50px 60px;
                padding: 0 20px;
                height: 36px;
                min-width: 160px;
                text-align: center;
                background: #fc6703;
                font-size: 14px;
                color: white;
                border-radius: 4px;
                display: flex;
                align-items: center;
                img{
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }
        }
    }
    // 底部
    .immediatelyJoin-immediatelyJoin{
        height: 40px;
        opacity: 0.15;
        position: fixed;
        width: 100%;
        bottom: 60px;
        left: 0px;
        background-image: linear-gradient(-180deg, rgba(146, 171, 196, 0.00) 0%, #92ABC4 100%);
    }
    .immediatelyJoin {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: white;
        z-index: 2;
        .proposal_btn{
            width: 15%;
            float: right;
            height: 100%;
            background-color: #49b6d6;
            color: #FFF;
            font-size: 16px;
            line-height: 60px;
            text-align: center;
            cursor: pointer;
            user-select: none;

        }
        .dibu{
            float: right;
            margin-right: 10%;
            color: #666;
            font-size: 16px;
            margin-top: 12px;
            span{
                margin-top: -4px;
                margin:  0 5px;
                font-size: 24px;
                color: #ff1515;
            }
        }
    }
    // 弹窗
    .menban{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        z-index: 99;
        // 点击人员信息-职业方案1弹窗
        .position_search_dialog{
            width: 578px;   
            position: fixed;
            top: 50%;
            left: 50%;
            height: 640px;
            transform: translate(-50%, -50%);
            box-shadow: 0 0 16px 0;
            border-radius: 4px;
            background-color: white;
            // display: none;
            z-index: 15;
            .position_search_dialog_title{
                color: #333;
                overflow: hidden;
                padding: 18px 10px 18px 30px;
                line-height: 24px;
                font-size: 16px;
                font-weight: bold;
                border-bottom: 1px solid #DCDCDC;
                position: relative;
                box-sizing: border-box;
                .close_search_pane {
                    position: absolute;
                    background: url(../../assets/souye/close.png) no-repeat;
                    width: 16px;
                    height: 16px;
                    background-position: center center;
                    background-size: 100% 100%;
                    right: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                    cursor: pointer;
                }
            }
            .position_search_ctrl_wrap{
                .position_search_input {
                    width: 390px;
                    border: 1px solid #DCDCDC;
                    outline: none;
                    height: 30px;
                    line-height: 20px;
                    text-indent: 15px;
                    border-radius: 5px;
                    font-size: 14px;
                    margin: 15px 10px 15px 30px;
                    float: left;
                }
                .search_position_btn {
                    width: 70px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    font-size: 14px;
                    color: white;
                    border-radius: 5px;
                    float: left;
                    margin-top: 15px;
                    background-color: #fc6703;
                    cursor: pointer;
                }
                .search_position_select_wrap{
                    overflow: hidden;
                    padding: 0 0 0 30px;
                    border-bottom: solid 1px #DCDCDC;
                    user-select: none;
                    select{
                        border: solid 1px #DCDCDC;
                        border-radius: 4px;
                        margin: 7px 12px 7px 0;
                        width: 160px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 14px;
                        appearance: none;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        background-image: url(../../assets/souye/icon_dropdown.png);
                        background-size: 10px 5px;
                        background-repeat: no-repeat;
                        background-position: calc(100% - 5px) center;
                        padding-left: 5px;
                        padding-right: 15px;
                        float: left;
                        color: #858585;
                    }
                }
                .position_edit_wrap{
                    margin-top: 6px;
                    width: calc(100% - 70px);
                    height: 44px;
                    background: #F1F8FF;
                    border-radius: 4px;
                    clear: both;
                    margin-left: 30px;
                    box-sizing: border-box;
                    .level_str{
                        font-size: 12px;
                        height: 30px;
                        line-height: 30px;
                        margin: 7px 12px 7px 18px;
                        float: left;
                    }
                }
                .search_position_result_text{
                    font-size: 14px;
                    color: #666666;
                    height: 20px;
                    line-height: 20px;
                    margin: 18px 0 9px 0;
                    padding-left: 30px;
                }
            }
            .search_position_result_wrap{
                position: absolute;
                top: 256px;
                bottom: 80px;
                left: 0;
                width: 100%;
                overflow-y: scroll;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                .no_position_result{
                    // display: none;
                    margin-top: 50px;
                    text-align: center;
                    img{
                        width: 175px;
                        margin: 0 auto;
                    }
                }
                .infinite-list{
                    width: 508px;
                    margin: 0 auto;
                    .infinite-list-item{
                        border-bottom: 1px solid #ccc;
                        position: relative;
                        overflow: hidden;
                        user-select: none;
                        .icon_position{
                            float: left;
                            margin: 12px 8px 0 30px;
                            width: 12px;
                            height: 12px;
                            background-image: url(../../assets/souye/icon_position.png);
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }
                        .position_content{
                            float: left;
                            width: calc(100% - 120px);
                            .position_text{
                                font-size: 14px;
                                color: #333333;
                                margin-top: 8px;
                            }
                            .parent_position_text{
                                font-size: 12px;
                                color: #666666;
                                margin-top: 2px;
                            }
                            .parent_position_text{
                                font-size: 12px;
                                color: #666666;
                                margin-top: 2px;
                            }
                        }
                        .icon_check{
                            width: 24px;
                            height: 24px;
                            position: absolute;
                            right: 30px;
                            top: 50%;
                            transform: translateY(-50%);
                            background-size: 100% 100%;
                            background-image: url(../../assets/souye/icon_position_check_default.png);
                        }
                        .actim{
                            background-image: url(../../assets/souye/icon_position_check_active.png);
                        }
                    }
                }
            }
            .search_position_dialog_foot{
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 80px;
                border-top: solid 1px #DCDCDC;
                text-align: right;
                .position-btn {
                    display: inline-block;
                    margin-top: 16px;
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    color: white;
                    border-radius: 4px;
                    width: 150px;
                    user-select: none;
                }
                .ok-btn {
                    margin-right: 15px;
                    background: #fc6703;
                }
                .cancel-btn {
                    margin-right: 20px;
                    background: #FF4700;
                }
            }
        }
        // 点击人员信息-添加被保人
        .modal-dialog{
            width: 600px;
            margin: 30px auto;
            .modal-content{
                border-radius: 3px;
                position: relative;
                background-color: #fff;
                .modal-header{
                    padding: 12px 15px;
                    border-bottom-color: #e2e7eb;
                    border-bottom: 1px solid #e5e5e5;
                    .close{
                        padding: 0;
                        cursor: pointer;
                        background: transparent;
                        border: 0;
                        float: right;
                        font-size: 21px;
                        font-weight: bold;
                        line-height: 1;
                        color: #000;
                        opacity: .2;
                    }
                    .close:hover{
                        opacity: 1;
                    }
                    .modal-title{
                        font-weight: 500;
                        color: #242a30;
                        font-size: 18px;
                    }
                }
                .form-horizontal{
                    .modal-body{
                        padding: 15px;
                        position: relative;
                        overflow: hidden;
                        .form-group{
                            margin-right: -15px;
                            margin-left: -15px;
                            margin-bottom: 15px;
                            overflow: hidden;
                            .col-sm-3 {
                                width: 25%;
                                position: relative;
                                min-height: 1px;
                                padding-right: 15px;
                                padding-left: 15px;
                                float: left;
                                box-sizing: border-box;
                            }
                            .control-label {
                                padding-top: 7px;
                                margin-bottom: 0;
                                text-align: right;
                                
                            }
                            label {
                                font-weight: 500;
                                max-width: 100%;
                            }
                            .col-sm-8 {
                                width: 66.66666667%;
                                float: left;
                                position: relative;
                                min-height: 1px;
                                padding-right: 15px;
                                padding-left: 15px;
                                box-sizing: border-box;
                            }
                            .form-control{
                                height: 34px;
                                box-sizing: border-box;
                                border: 1px solid #e2e7eb;
                                box-shadow: none;
                                font-size: 12px;
                                display: block;
                                width: 100%;
                                height: 34px;
                                padding: 6px 12px;
                                color: #555;
                                background-color: #fff;
                                outline: none;
                            }
                        }
                    }
                    .modal-footer{
                        color: #707478;
                        border-top-color: #e2e7eb;
                        padding: 14px 15px 15px;
                        text-align: right;
                        border-top: 1px solid #e5e5e5;
                        .btn-info {
                            background: #fc6703 !important;
                            border-color: #fc6703 !important;
                        }
                        .btn {
                            font-weight: 300;
                            border-radius: 3px;
                            display: inline-block;
                            padding: 6px 12px;
                            margin-bottom: 0;
                            font-size: 14px;
                            line-height: 1.42857143;
                            text-align: center;
                            white-space: nowrap;
                            vertical-align: middle;
                            cursor: pointer;
                            border: 1px solid transparent;
                        }
                        .btn.btn-info {
                            color: #fff;
                        }
                        .btn-default-modal{
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}
.dianjiyangshi{
    background-color: #fc6703 ;
    display: inline-block;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
    color: #fff;
    margin-top: 10px;
}
.text-tit {
    line-height: 35px;
    text-align: right;
}
.col-md-1 {
    width: 8.33333333%;
}
.col-md-1{
    position: relative; 
    min-height: 1px; 
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    border: none;
    
}
.col-md-2{
    float: left;
    padding-right: 24px;
    width: 13.66666667%;
}
.col-md-4 {
    float: left;
    width: 33.33333333%;
}
.form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;  
    line-height: 1.42857143;
    color: #555;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    background-color: #fff;
}
:deep .el-date-editor.el-input{
    width: 200px;
    height: 30px;
    float: left;
    .el-input__inner{
        height: 30px;
        border: 1px solid #e2e7eb;
        outline: none;
        background-color: #fff;
        
    }
    .el-input__prefix{
        display: none;

    }
    i{
        display: none;
    }
}
:deep .el-upload-list{
    display: none;

}
:deep .upload-demo{
    height: 50px;
    width: 80px;
    overflow: hidden;
}
// 修改表格样式
:deep .has-gutter .is-leaf{
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  background-color: #fff;
  height: 45px;
}

:deep .el-table .el-table__cell{
  margin: 0;
  padding: 0;
}
:deep .el-table__body-wrapper .el-table__cell{
  font-size: 12px;
  a{
    color: #428bca;
  }
}
:deep .el-table .cell  {
  padding: 0 5px;

}
:deep .el-table__row .cell{
  height: 60px;
  // 网格布局让元素的上下居中
  display: grid;  
  align-items: center;
  
}
:deep .el-table th.el-table__cell>.cell{
  padding: 0 5px;
}
:deep .el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: rgb(244, 244, 244) !important;
} 
// 日期
:deep .el-date-editor.el-input{
    width: 370px;
}
</style>