<template>
  <div class="bkg-mask">
    <div class="banner2"></div>
    <div class="mask" v-if="tjlxi"></div>
    <div class="frame">
      <div class="content">
        <div class="side-nav-container">
          <div class="title">
            <p class="title-1">订单管理中心</p>
            <p>Personal Center</p>
          </div>
          <div class="side-nav">
            <!-- 我的业绩 -->
            <div class="nav-block-item">
              <div class="nav-block-item-title">
                <img class="icon" src="../../assets/gerenzhongxin/info.png" alt="">我的业绩
              </div>
              <div class="nav-item">
                <a href="javascript:;" @click="gern=0" :class="{'active':gern===0}">我的业绩</a>
              </div>
              <div class="nav-item">
                <a href="javascript:;" @click="gern=1" :class="{'active':gern===1}">我的联系人</a>
              </div>
            </div>
            <!-- 我的订单 -->
            <div class="nav-block-item">
              <div class="nav-block-item-title">
                <img class="icon" src="../../assets/gerenzhongxin/insurance.png" alt="">我的订单
              </div>
              <div class="nav-item">
                <a href="javascript:;" @click="gern=2" :class="{'active':gern===2}">全部订单</a>
              </div>
              <div class="nav-item">
                <a href="javascript:;" @click="gern=3" :class="{'active':gern===3}">报价单</a>
              </div>
              <div class="nav-item">
                <a href="javascript:;" @click="gern=4" :class="{'active':gern===4}">续保提醒</a>
              </div>
              <div class="nav-item">
                <a href="javascript:;" @click="gern=5" :class="{'active':gern===5}">保单回执</a>
              </div>
            </div>
            <!-- 保全服务 -->
            <div class="nav-block-item">
              <div class="nav-block-item-title">
                <img class="icon" src="../../assets/gerenzhongxin/edit.png" alt="">保全服务
              </div>
              <div class="nav-item">
                <a href="javascript:;" @click="gern=6" :class="{'active':gern===6}">批单记录</a>
              </div>
              <div class="nav-item">
                <a href="javascript:;" @click="gern=7" :class="{'active':gern===7}">批单申请</a>
              </div>
              <div class="nav-item">
                <a href="javascript:;" @click="gern=8" :class="{'active':gern===8}">注销/退保</a>
              </div>
            </div>
            <!-- 佣金管理 -->
            <div class="nav-block-item">
              <div class="nav-block-item-title">
                <img class="icon" src="../../assets/gerenzhongxin/commission.png" alt="">佣金管理
              </div>
              <div class="nav-item">
                <a href="javascript:;" @click="gern=9" :class="{'active':gern===9}">佣金记录</a>
              </div>
            </div>
            <!-- 线下转账 -->
            <div class="nav-block-item">
              <div class="nav-block-item-title">
                <img class="icon" src="../../assets/gerenzhongxin/remit.png" alt="">线下转账
              </div>
              <div class="nav-item">
                <a href="javascript:;" @click="gern=10" :class="{'active':gern===10}">申请记录</a>
              </div>
            </div>
          </div>
        </div>
        <div class="content-body" v-if="gern==0">
          <div class="count-frame">
            <div class="welcome">
              <span class="ml-10">您好！</span>
              <span class="ml-5">保镜科技</span>
              <span>您近期的业务更新如下：</span>
            </div>
            <!-- 分割线 -->
            <div class="welcome-sp"></div>
            <div class="user-info">
              <div class="header clearfix">
                <div class="flex">
                  <div class="header-avatar"><img src="../../assets/gerenzhongxin/default_avatar.png" alt=""></div>
                  <div class="header-user">
                    <p>保镜科技</p>
                    <p><span class="header-tag">渠道</span></p>
                  </div>
                </div>
                <div class="flex">
                  <p class="commission-ico"><span>总佣金</span></p>
                  <div class="total_income">{{zyj}}<span class="list_number_1">元</span></div>
                </div>
              </div>
            </div>
            <div class="count-info">
              <div class="count_tit">
                <ul class="totalRegion">
                  <li @click="wdyj(0)" :class="{'active':grtab===0}">全部数据</li>
                  <li @click="wdyj(1)" :class="{'active':grtab===1}">本月</li>
                  <li @click="wdyj(2)" :class="{'active':grtab===2}">上个月</li>
                  <li @click="wdyj(3)" :class="{'active':grtab===3}">近三个月</li>
                </ul>
              </div>
              <div class="total">
                <div class="total-pane">
                  <div class="list">
                    <div class="list-title">
                      <span class="addOrder-ico"></span>
                      <span>新增订单</span>
                    </div>
                    <div class="list-number">{{xzdd}} <span class="list_number_1">份</span></div>
                  </div>
                  <div class="list-space"></div>
                  <div class="list">
                    <div class="list-title">
                      <span class="addOrder-ico icon1"></span>
                      <span>订单总保费</span>
                    </div>
                    <div class="list-number">{{xzdd}}<span class="list_number_1">份</span></div>
                  </div>
                  <div class="list-space"></div>
                  <div class="list">
                    <div class="list-title">
                      <span class="addOrder-ico icon2"></span>
                      <span>总佣金</span>
                    </div>
                    <div class="list-number">{{zyj}}<span class="list_number_1">份</span></div>
                  </div>
                  <div class="list-space"></div>
                  <div class="list clearfix" >
                    <div class="list-title">
                      <span class="addOrder-ico icon3"></span>
                      <span>在途佣金</span>
                    </div>
                    <div class="list-number">{{ ztyj}}<span class="list_number_1">份</span></div>
                  </div>
                </div>
              </div>
              <div style="clear:both"></div>
            </div>
            <div style="height: 20px;"></div>
          </div>
          <div style="height: 20px; clear: both;"></div>
          <div class="detail-frame">
            <div class="order">
              <div class="list-table">
                <el-table
                  :data="tableData"
                  :header-cell-style="{'text-align':'center'}"
                  border
                  style="width: 100%">
                  <el-table-column
                    prop="date"
                    width="200"
                    align="left"
                    label="订单信息">
                    <template #default="scope">
                      <div v-for="item in scope.row.date" :key="item"><a href="javascript:;">{{ item }}</a></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    width="240"
                    align="center"
                    label="产品名称">
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    align="center"
                    width="250"
                    label="投、被保人">
                  </el-table-column>
                  <el-table-column
                    prop="baofei"
                    align="center"
                    width="70"
                    label="保费(元)">
                  </el-table-column>
                  <el-table-column
                    prop="shijain"
                    align="center"
                    width="200"
                    label="时间信息">
                    <template #default="scope">
                      <div v-for="item in scope.row.shijain" :key="item"><a href="javascript:;">{{ item }}</a></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="zuangtai"
                    width="62"
                    label="状态	">
                  </el-table-column>
                  <el-table-column
                    prop="chaozuo"
                    align="center"
                    label="操作	">
                    <template #default="scope">
                      <div v-for="item in scope.row.chaozuo" :key="item"><a href="javascript:;">{{ item }}</a></div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <div class="content-body" v-if="gern==1">
          <div class="order">
            <div class="order_header">
              <div class="add-container">
                <div class="title fl">
                  <img class="fl" src="../../assets/gerenzhongxin/my_contactor.png" alt="">
                  <span class="fl">我的联系人</span>
                </div>
                <div class="card-add fr">
                  <a href="javascript:;" @click="tjlxi=true">
                    <img class="fl" src="../../assets/gerenzhongxin/icon-add-card.png" alt="">
                    <span class="fl">添加联系人</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="list-table">
              <el-table
                  :data="tableDatas"
                  :header-cell-style="{'text-align':'center'}"
                  border
                  style="width: 100%">
                  <el-table-column
                    prop="date"
                    width="200"
                    align="left"
                    label="联系人姓名">
                    <template #default="scope">
                      <div v-for="item in scope.row.date" :key="item"><a href="javascript:;">{{ item }}</a></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    width="240"
                    align="center"
                    label="电话号码">
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    align="center"
                    width="250"
                    label="电子邮箱">
                  </el-table-column>
                  <el-table-column
                    prop="baofei"
                    align="center"
                    width="70"
                    label="地址信息">
                  </el-table-column>
                  <el-table-column
                    prop="chaozuo"
                    align="center"
                    label="操作	">
                    <template #default="scope">
                      <div v-for="item in scope.row.chaozuo" :key="item"><a href="javascript:;">{{ item }}</a></div>
                    </template>
                  </el-table-column>
                </el-table>
            </div>
          </div>
        </div>
        <div class="content-body" v-if="gern==2">
          <div class="order">
            <div class="order-header">
              <div class="search-name-container">
                <div class="fl">
                  <input type="text" class="search-name-input fl" placeholder="请输入产品名或投保人名字或保单号">
                  <button class="search-name-btn fl"></button>
                </div>
                <div class="fr filter-container" @click="zanshi=!zanshi" v-if="zanshi">展示筛选条件</div>
                <div class="fr filter-containers" @click="zanshi=!zanshi" v-if="zanshi===false">收起筛选条件</div>
              </div>
              <div class="operation" v-if="zanshi">
                <div class="form-search-container">
                  <div class="clearfix">
                    <div class="fl form-item">
                      <span class="label fl">时间区间:</span>
                      <div class="icon-arrow-wrap fl">
                        <select name="全部">
                          <option>本月</option>
                          <option>当日</option>
                          <option>上个月</option>
                          <option>近三个月</option>
                          <option>全部</option>
                          <option>自定义时间区间</option>
                        </select>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <span class="label fl">下单时间：</span>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单开始时间">
                        </el-date-picker>
                      </div>
                      <div class="fl separator">-</div>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单结束时间">
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <button class="fl btn search">查询</button>
                      <button class="fl btn reset">重置</button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="status-bar">
                <span class="status" @click="qvantab=1" :class="{'active':qvantab===1}">全部</span>
                <span class="status" @click="qvantab=2" :class="{'active':qvantab===2}">已承保</span>
                <span class="status" @click="qvantab=3" :class="{'active':qvantab===3}">核保中</span>
              </div>
              <div class="list-table">
                <el-table
                    :data="tableDataliat"
                    :header-cell-style="{'text-align':'center'}"
                    height="600px"
                    :class="{'h500':zanshi}"
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="date"
                      width="200"
                      align="left"
                      label="订单信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.date" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      width="230"
                      align="center"
                      label="产品名称">
                    </el-table-column>
                    <el-table-column
                      prop="address"
                      align="center"
                      width="250"
                      label="投、被保人">
                    </el-table-column>
                    <el-table-column
                      prop="baofei"
                      align="center"
                      width="65"
                      label="保费(元)">
                    </el-table-column>
                    <el-table-column
                      prop="shijain"
                      align="center"
                      width="200"
                      label="时间信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.shijain" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="zuangtai"
                      width="62"
                      label="状态	">
                    </el-table-column>
                    <el-table-column
                      prop="chaozuo"
                      align="center"
                      label="操作	">
                      <template >
                        <template>
                          <div><a href="javascript:;">电子版保单</a></div>
                          <div><a href="javascript:;">电子发票</a></div>
                          <div><a href="javascript:;">投保确认书</a></div>
                          <div><a href="javascript:;">导出人员</a></div>
                          <div><a href="javascript:;" @click="kdhz=1">回传快递信息</a></div>
                        </template>
                      </template>
                    </el-table-column>
                  </el-table>
              </div>
              <div class="page-total">共 6 页 / 60 条数据</div>
              <div class="page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="60">
                </el-pagination>
              </div>
          </div>
        </div>
        <div class="content-body" v-if="gern==3">
          <div class="order">
            <div class="order-header">
              <div class="search-name-container">
                <div class="fl">
                  <input type="text" class="search-name-input fl" placeholder="请输入客户名字">
                  <button class="search-name-btn fl"></button>
                </div>
                <div class="fr filter-container" @click="zanshi=!zanshi" v-if="zanshi">展示筛选条件</div>
                <div class="fr filter-containers" @click="zanshi=!zanshi" v-if="zanshi===false">收起筛选条件</div>
              </div>
              <div class="operation" v-if="zanshi">
                <div class="form-search-container">
                  <div class="clearfix">
                    <div class="fl form-item">
                      <span class="label fl">时间区间:</span>
                      <div class="icon-arrow-wrap fl">
                        <select name="全部">
                          <option>本月</option>
                          <option>当日</option>
                          <option>上个月</option>
                          <option>近三个月</option>
                          <option>全部</option>
                          <option>自定义时间区间</option>
                        </select>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <span class="label fl">下单时间：</span>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单开始时间">
                        </el-date-picker>
                      </div>
                      <div class="fl separator">-</div>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单结束时间">
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <button class="fl btn search">查询</button>
                      <button class="fl btn reset">重置</button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="list-table">
                <el-table
                    :data="tableDataliat"
                    :header-cell-style="{'text-align':'center'}"
                    height="650px"
                    :class="{'h550':zanshi}"
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="date"
                      width="200"
                      align="left"
                      label="报价单号">
                      <template #default="scope">
                        <div v-for="item in scope.row.date" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      width="230"
                      align="center"
                      label="产品名称">
                    </el-table-column>
                    <el-table-column
                      prop="address"
                      align="center"
                      width="250"
                      label="客户名字">
                    </el-table-column>
                    <el-table-column
                      prop="baofei"
                      align="center"
                      width="70"
                      label="保费(元)">
                    </el-table-column>
                    <el-table-column
                      prop="shijain"
                      align="center"
                      width="200"
                      label="录入时间">
                      <template #default="scope">
                        <div v-for="item in scope.row.shijain" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="chaozuo"
                      align="center"
                      label="操作	">
                      <template #default="scope">
                        <div v-for="item in scope.row.chaozuo" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                  </el-table>
              </div>
              <div class="page-total">共 6 页 / 74 条数据</div>
              <div class="page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="74">
                </el-pagination>
              </div>
          </div>
        </div>
        <div class="content-body" v-if="gern==4">
          <div class="order">
            <div class="order-header">
              <div class="search-name-container" style="height: 52px;">
                <div class="fl">
                  <input type="text" class="search-name-input fl" placeholder="请输入投被保人名字">
                  <div class="form-item fl">
                    <div class="icon-arrow-wrap fl">
                        <select name="全部" style="width: 120px;">
                          <option>全部</option>
                          <option>未到期保单</option>
                          <option>已到期保单</option>
                        </select>
                      </div>
                  </div>
                  <button class="search-name-btn fl" style="margin-left: 20px;"></button>
                </div>
              </div>
              </div>
              <div class="list-table">
                <el-table
                    :data="tableDataliat"
                    :header-cell-style="{'text-align':'center'}"
                    height="650px"
                    :class="{'h550':zanshi}"
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="date"
                      width="200"
                      align="left"
                      label="订单信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.date" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      width="230"
                      align="center"
                      label="产品名称">
                    </el-table-column>
                    <el-table-column
                      prop="address"
                      align="center"
                      width="250"
                      label="投、被保人">
                    </el-table-column>
                    <el-table-column
                      prop="baofei"
                      align="center"
                      width="70"
                      label="保费(元)">
                    </el-table-column>
                    <el-table-column
                      prop="shijain"
                      align="center"
                      width="200"
                      label="保障期限">
                      <template #default="scope">
                        <div v-for="item in scope.row.shijain" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="zuangtai"
                      width="62"
                      label="其他信息	">
                    </el-table-column>
                    <el-table-column
                      prop="chaozuo"
                      align="center"
                      label="操作	">
                      <template #default="scope">
                        <div v-for="item in scope.row.chaozuo" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                  </el-table>
              </div>
              <div class="page-total">共 6 页 / 74 条数据</div>
              <div class="page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="33">
                </el-pagination>
              </div>
          </div>
        </div>
        <div class="content-body" v-if="gern==5">
          <div class="order">
            <div class="order-header">
              <div class="search-name-container">
                <div class="fl">
                  <input type="text" class="search-name-input fl" placeholder="请输入产品名或投保人名字或保单号">
                  <button class="search-name-btn fl"></button>
                </div>
                <div class="fr filter-container" @click="zanshi=!zanshi" v-if="zanshi">展示筛选条件</div>
                <div class="fr filter-containers" @click="zanshi=!zanshi" v-if="zanshi===false">收起筛选条件</div>
              </div>
              <div class="operation" v-if="zanshi">
                <div class="form-search-container">
                  <div class="clearfix">
                    <div class="fl form-item">
                      <span class="label fl">时间区间:</span>
                      <div class="icon-arrow-wrap fl">
                        <select name="全部">
                          <option>本月</option>
                          <option>当日</option>
                          <option>上个月</option>
                          <option>近三个月</option>
                          <option>全部</option>
                          <option>自定义时间区间</option>
                        </select>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <span class="label fl">下单时间：</span>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单开始时间">
                        </el-date-picker>
                      </div>
                      <div class="fl separator">-</div>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单结束时间">
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <button class="fl btn search">查询</button>
                      <button class="fl btn reset">重置</button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="list-table">
                <el-table
                    :data="tableDataliat"
                    :header-cell-style="{'text-align':'center'}"
                    height="650px"
                    :class="{'h550':zanshi}"
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="date"
                      width="200"
                      align="left"
                      label="订单信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.date" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      width="230"
                      align="center"
                      label="产品名称">
                    </el-table-column>
                    <el-table-column
                      prop="address"
                      align="center"
                      width="250"
                      label="投、被保人">
                    </el-table-column>
                    <el-table-column
                      prop="baofei"
                      align="center"
                      width="70"
                      label="保费(元)">
                    </el-table-column>
                    <el-table-column
                      prop="shijain"
                      align="center"
                      width="200"
                      label="时间信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.shijain" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="zuangtai"
                      width="62"
                      label="是否上传	">
                    </el-table-column>
                    <el-table-column
                      prop="chaozuo"
                      align="center"
                      label="操作	">
                      <template #default="scope">
                        <div v-for="item in scope.row.chaozuo" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                  </el-table>
              </div>
              <div class="page-total">共 6 页 / 74 条数据</div>
              <div class="page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="74">
                </el-pagination>
              </div>
          </div>
        </div>
        <div class="content-body" v-if="gern==6">
          <div class="order">
            <div class="order-header">
              <div class="search-name-container">
                <div class="fl">
                  <input type="text" class="search-name-input fl" placeholder="请输入产品名或投保人名字或保单号">
                  <button class="search-name-btn fl"></button>
                </div>
                <div class="fr filter-container" @click="zanshi=!zanshi" v-if="zanshi">展示筛选条件</div>
                <div class="fr filter-containers" @click="zanshi=!zanshi" v-if="zanshi===false">收起筛选条件</div>
              </div>
              <div class="operation" v-if="zanshi">
                <div class="form-search-container">
                  <div class="clearfix">
                    <div class="fl form-item">
                      <span class="label fl">时间区间:</span>
                      <div class="icon-arrow-wrap fl">
                        <select name="全部">
                          <option>本月</option>
                          <option>当日</option>
                          <option>上个月</option>
                          <option>近三个月</option>
                          <option>全部</option>
                          <option>自定义时间区间</option>
                        </select>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <span class="label fl">下单时间：</span>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单开始时间">
                        </el-date-picker>
                      </div>
                      <div class="fl separator">-</div>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单结束时间">
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <button class="fl btn search">查询</button>
                      <button class="fl btn reset">重置</button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="status-bar">
                <span class="status" @click="qvantab=1" :class="{'active':qvantab===1}">全部</span>
                <span class="status" @click="qvantab=2" :class="{'active':qvantab===2}">已承保</span>
                <span class="status" @click="qvantab=3" :class="{'active':qvantab===3}">核保中</span>
              </div>
              <div class="list-table">
                <el-table
                    :data="tableDataliat"
                    :header-cell-style="{'text-align':'center'}"
                    height="600px"
                    :class="{'h500':zanshi}"
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="date"
                      width="200"
                      align="left"
                      label="订单信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.date" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      width="230"
                      align="center"
                      label="产品名称">
                    </el-table-column>
                    <el-table-column
                      prop="address"
                      align="center"
                      width="250"
                      label="投、被保人">
                    </el-table-column>
                    <el-table-column
                      prop="baofei"
                      align="center"
                      width="70"
                      label="保费(元)">
                    </el-table-column>
                    <el-table-column
                      prop="shijain"
                      align="center"
                      width="200"
                      label="时间信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.shijain" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="zuangtai"
                      width="62"
                      label="状态	">
                    </el-table-column>
                    <el-table-column
                      prop="chaozuo"
                      align="center"
                      label="操作	">
                      <template #default="scope">
                        <div v-for="item in scope.row.chaozuo" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                  </el-table>
              </div>
              <div class="page-total">共 6 页 / 60 条数据</div>
              <div class="page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="60">
                </el-pagination>
              </div>
          </div>
        </div>
        <div class="content-body" v-if="gern==7">
          <div class="order">
            <div class="order-header">
              <div class="search-name-container">
                <div class="fl">
                  <input type="text" class="search-name-input fl" placeholder="请输入产品名或投保人名字或保单号">
                  <button class="search-name-btn fl"></button>
                </div>
                <div class="fr filter-container" @click="zanshi=!zanshi" v-if="zanshi">展示筛选条件</div>
                <div class="fr filter-containers" @click="zanshi=!zanshi" v-if="zanshi===false">收起筛选条件</div>
              </div>
              <div class="operation" v-if="zanshi">
                <div class="form-search-container">
                  <div class="clearfix">
                    <div class="fl form-item">
                      <span class="label fl">时间区间:</span>
                      <div class="icon-arrow-wrap fl">
                        <select name="全部">
                          <option>本月</option>
                          <option>当日</option>
                          <option>上个月</option>
                          <option>近三个月</option>
                          <option>全部</option>
                          <option>自定义时间区间</option>
                        </select>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <span class="label fl">下单时间：</span>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单开始时间">
                        </el-date-picker>
                      </div>
                      <div class="fl separator">-</div>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单结束时间">
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <button class="fl btn search">查询</button>
                      <button class="fl btn reset">重置</button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="list-table">
                <el-table
                    :data="tableDataliat"
                    :header-cell-style="{'text-align':'center'}"
                    height="650px"
                    :class="{'h550':zanshi}"
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="date"
                      width="200"
                      align="left"
                      label="订单信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.date" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      width="230"
                      align="center"
                      label="产品名称">
                    </el-table-column>
                    <el-table-column
                      prop="address"
                      align="center"
                      width="250"
                      label="投、被保人">
                    </el-table-column>
                    <el-table-column
                      prop="baofei"
                      align="center"
                      width="70"
                      label="保费(元)">
                    </el-table-column>
                    <el-table-column
                      prop="shijain"
                      align="center"
                      width="200"
                      label="时间信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.shijain" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="zuangtai"
                      width="62"
                      label="状态	">
                    </el-table-column>
                    <el-table-column
                      prop="chaozuo"
                      align="center"
                      label="操作	">
                      <template>
                        <router-link tag="div" target="_blank" :to="{path:'/sqpg',query:{id:1}}"><a href="javascript:;">申请批改</a></router-link>
                        <!-- <div><a href="javascript:;">申请批改</a></div> -->
                      </template>
                    </el-table-column>
                  </el-table>
              </div>
              <div class="page-total">共 6 页 / 74 条数据</div>
              <div class="page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="74">
                </el-pagination>
              </div>
          </div>
        </div>
        <div class="content-body" v-if="gern==8">
          <div class="order">
            <div class="order-header">
              <div class="search-name-container">
                <div class="fl">
                  <input type="text" class="search-name-input fl" placeholder="请输入产品名或投保人名字或保单号">
                  <button class="search-name-btn fl"></button>
                </div>
                <div class="fr filter-container" @click="zanshi=!zanshi" v-if="zanshi">展示筛选条件</div>
                <div class="fr filter-containers" @click="zanshi=!zanshi" v-if="zanshi===false">收起筛选条件</div>
              </div>
              <div class="operation" v-if="zanshi">
                <div class="form-search-container">
                  <div class="clearfix">
                    <div class="fl form-item">
                      <span class="label fl">时间区间:</span>
                      <div class="icon-arrow-wrap fl">
                        <select name="全部">
                          <option>本月</option>
                          <option>当日</option>
                          <option>上个月</option>
                          <option>近三个月</option>
                          <option>全部</option>
                          <option>自定义时间区间</option>
                        </select>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <span class="label fl">下单时间：</span>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单开始时间">
                        </el-date-picker>
                      </div>
                      <div class="fl separator">-</div>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单结束时间">
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <button class="fl btn search">查询</button>
                      <button class="fl btn reset">重置</button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="list-table">
                <el-table
                    :data="tableDataliat"
                    :header-cell-style="{'text-align':'center'}"
                    height="650px"
                    :class="{'h550':zanshi}"
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="date"
                      width="200"
                      align="left"
                      label="订单信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.date" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      width="230"
                      align="center"
                      label="产品名称">
                    </el-table-column>
                    <el-table-column
                      prop="address"
                      align="center"
                      width="250"
                      label="投、被保人">
                    </el-table-column>
                    <el-table-column
                      prop="baofei"
                      align="center"
                      width="70"
                      label="保费(元)">
                    </el-table-column>
                    <el-table-column
                      prop="shijain"
                      align="center"
                      width="200"
                      label="时间信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.shijain" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="zuangtai"
                      width="62"
                      label="状态	">
                    </el-table-column>
                    <el-table-column
                      prop="chaozuo"
                      align="center"
                      label="操作	">
                      <template #default="scope">
                        <div v-for="item in scope.row.chaozuo" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                  </el-table>
              </div>
              <div class="page-total">共 6 页 / 74 条数据</div>
              <div class="page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="74">
                </el-pagination>
              </div>
          </div>
        </div>        
        <div class="content-body" v-if="gern==9">
          <div class="order">
            <div class="order-header">
              <div class="search-name-container">
                <div class="fl">
                  <input type="text" class="search-name-input fl" placeholder="请输入产品名或投保人名字或保单号">
                  <button class="search-name-btn fl"></button>
                </div>
                <div class="fr filter-container" @click="zanshi=!zanshi" v-if="zanshi">展示筛选条件</div>
                <div class="fr filter-containers" @click="zanshi=!zanshi" v-if="zanshi===false">收起筛选条件</div>
              </div>
              <div class="operation" v-if="zanshi">
                <div class="form-search-container">
                  <div class="clearfix">
                    <div class="fl form-item">
                      <span class="label fl">时间区间:</span>
                      <div class="icon-arrow-wrap fl">
                        <select name="全部">
                          <option>本月</option>
                          <option>当日</option>
                          <option>上个月</option>
                          <option>近三个月</option>
                          <option>全部</option>
                          <option>自定义时间区间</option>
                        </select>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <span class="label fl">下单时间：</span>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单开始时间">
                        </el-date-picker>
                      </div>
                      <div class="fl separator">-</div>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单结束时间">
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <button class="fl btn search">查询</button>
                      <button class="fl btn reset">重置</button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="list-table">
                <el-table
                    :data="tableDataliat"
                    :header-cell-style="{'text-align':'center'}"
                    height="650px"
                    :class="{'h550':zanshi}"
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="date"
                      width="200"
                      align="left"
                      label="订单信息">
                      <template #default="scope">
                        <div v-for="item in scope.row.date" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      width="230"
                      align="center"
                      label="保单信息">
                    </el-table-column>
                    <el-table-column
                      prop="address"
                      align="center"
                      width="250"
                      label="产品名称">
                    </el-table-column>
                    <el-table-column
                      prop="baofei"
                      align="center"
                      width="70"
                      label="投、被保人">
                    </el-table-column>
                    <el-table-column
                      prop="shijain"
                      align="center"
                      width="200"
                      label="保费(元)">
                      <template #default="scope">
                        <div v-for="item in scope.row.shijain" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="zuangtai"
                      width="62"
                      label="佣金比例	">
                    </el-table-column>
                    <el-table-column
                      prop="chaozuo"
                      align="center"
                      label="佣金（元）	">
                      <template #default="scope">
                        <div v-for="item in scope.row.chaozuo" :key="item"><a href="javascript:;">{{ item }}</a></div>
                      </template>
                    </el-table-column>
                  </el-table>
              </div>
              <div class="page-total">共 6 页 / 74 条数据</div>
              <div class="page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="74">
                </el-pagination>
              </div>
          </div>
        </div>
        <div class="content-body" v-if="gern==10">
          <div class="order">
            <div class="order-header">
              <div class="operation">
                <div class="form-search-container">
                  <div class="clearfix">
                    <div class="fl form-item">
                      <span class="label fl">时间区间:</span>
                      <div class="icon-arrow-wrap fl">
                        <select name="全部" style="width: 240px;">
                          <option>本月</option>
                          <option>当日</option>
                          <option>上个月</option>
                          <option>近三个月</option>
                          <option>全部</option>
                          <option>自定义时间区间</option>
                        </select>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <span class="label fl">下单时间：</span>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单开始时间">
                        </el-date-picker>
                      </div>
                      <div class="fl separator">-</div>
                      <div class="fl icon-arrow-wrap ">
                        <el-date-picker
                          v-model="csjs"
                          type="date"
                          placeholder="录单结束时间">
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="fl form-item">
                      <button class="fl btn search">查询</button>
                      <button class="fl btn reset">重置</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div class="type">
                <span @click="shenqin=1" :class="{'active':shenqin===1}">全部</span>
                <span @click="shenqin=2" :class="{'active':shenqin===2}">待审核</span>
                <span @click="shenqin=3" :class="{'active':shenqin===3}">审核通过</span>
                <span @click="shenqin=4" :class="{'active':shenqin===4}">审核驳回</span>
              </div>
            <div class="list-table">
              <el-table
                  :data="tableDataliat"
                  :header-cell-style="{'text-align':'center'}"
                  height="650px"
                  :class="{'h550':zanshi}"
                  border
                  style="width: 100%">
                  <el-table-column
                    prop="date"
                    width="200"
                    align="left"
                    label="投保人">
                    <template #default="scope">
                      <div v-for="item in scope.row.date" :key="item"><a href="javascript:;">{{ item }}</a></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    width="230"
                    align="center"
                    label="申请金额	">
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    align="center"
                    width="250"
                    label="订单号">
                  </el-table-column>
                  <el-table-column
                    prop="baofei"
                    align="center"
                    width="70"
                    label="订单类型">
                  </el-table-column>
                  <el-table-column
                    prop="shijain"
                    align="center"
                    width="200"
                    label="支付凭证">
                    <template #default="scope">
                      <div v-for="item in scope.row.shijain" :key="item"><a href="javascript:;">{{ item }}</a></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="zuangtai"
                    width="62"
                    label="申请时间	">
                  </el-table-column>
                  <el-table-column
                    prop="chaozuo"
                    align="center"
                    label="审核状态	">
                    <template #default="scope">
                      <div v-for="item in scope.row.chaozuo" :key="item"><a href="javascript:;">{{ item }}</a></div>
                    </template>
                  </el-table-column>
                </el-table>
            </div>
          </div>
        </div>
        <div class="content-body" v-if="gern==11">
          <div class="password-container">
            <div class="title">
              <img class="fl" src="../../assets/gerenzhongxin/title.png" alt="">
              <span class="fl">账号设置</span>
            </div>
            <div class="pass-item">
              <div class="label fl">渠道名称</div>
              <div class="right-info fl">辰星保险科技</div>
            </div>
            <div class="pass-item">
              <div class="label fl">认证状态</div>
              <div class="right-info fl">未认证<a href="javascript:;" style="color: #fc6703;">（前往认证）</a></div>
            </div>
            <div class="pass-item">
              <div class="label fl">账号</div>
              <div class="right-info fl">辰星保险科技</div>
            </div>
            <div class="pass-item">
              <div class="label fl">密码</div>
              <div class="right-info fl">******</div>
              <div class="fr oper-btn" v-if="xiugai===false" @click="xiugai=!xiugai">编辑</div>
              <div class="fr oper-btns" v-if="xiugai" @click="xiugai=!xiugai">收起</div>
            </div>
            <div class="js-modify-form" v-if="xiugai">
              <div class="modify-item">
                <div class="modify-label fl">旧密码</div>
                <input type="password" placeholder="请输入旧密码" class="fl pass-input">
              </div>
              <div class="modify-item">
                <div class="modify-label fl">新密码</div>
                <input type="password" placeholder="请输入新密码" class="fl pass-input">
              </div>
              <div class="modify-item">
                <div class="modify-label fl">确认新密码</div>
                <input type="password" placeholder="请再次输入新密码" class="fl pass-input">
              </div>
            </div>
            <div class="pass-item">
              <div class="label fl">手机号码</div>
              <div class="right-info fl">
                <input type="number" placeholder="请设置联系号码">
              </div>
            </div>
            <div class="modify-confirm" @click="baocun">保存</div>
          </div>
        </div>
        <!-- 我的联系人-添加联系人弹窗 -->
        <div class="main" v-if="tjlxi">
          <div class="title">
            <img class="fl" src="../../assets/gerenzhongxin/add.png" alt="">
            <span class="fl">添加联系人</span>
          </div>
          <div class="line"></div>
          <div class="form">
            <div class="form-row">
              <label for="">姓名：</label>
              <div class="input-div">
                <input type="text" placeholder="请输入联系人姓名"  v-model="form.name">
              </div>
            </div>
            <div class="form-row">
              <label for="">电话号码:</label>
              <div class="input-div">
                <input type="text" placeholder="请输入联系人电话号码"  v-model="form.dianhua">
              </div>
            </div>
            <div class="form-row">
              <label for="">电子邮箱：</label>
              <div class="input-div">
                <input type="text" placeholder="请输入联系人电子邮箱"  v-model="form.youxaing">
              </div>
            </div>
            <div class="form-row">
              <label for="">地址信息：</label>
              <div class="input-div select-div">
                <select name="" id="" style="width: 30%;">
                  <option value="" data-text="—— 省 ——">—— 省 ——</option>
                  <option value="北京市" data-text="北京市">北京市</option>
                  <option value="天津市" data-text="天津市">天津市</option>
                  <option value="河北省" data-text="河北省">河北省—</option>
                </select>
                <select name="" id="" style="width: 30%;">
                  <option value="" data-text="—— 省 ——">—— 市 ——</option>
                  <option value="北京市" data-text="北京市">北京市</option>
                  <option value="天津市" data-text="天津市">天津市</option>
                  <option value="河北省" data-text="河北省">河北省—</option>
                </select>
                <select name="" id="" style="width: 30%;">
                  <option value="" data-text="—— 省 ——">—— 区 ——</option>
                  <option value="北京市" data-text="北京市">北京市</option>
                  <option value="天津市" data-text="天津市">天津市</option>
                  <option value="河北省" data-text="河北省">河北省—</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <label for="">&nbsp;</label>
              <div class="input-div">
                <textarea placeholder="请输入详细地址" v-model="form.xiangqing"></textarea>
              </div>
            </div>
            <div class="form-row">
              <label for="">是否默认：</label>
              <div class="input-div">
                <div class="radio">
                  <label for="" @click="form.omren=1" :class="{'labels':form.omren===1}">默认</label>
                </div>
                <div class="radio">
                  <label for="" @click="form.omren=2" :class="{'labels':form.omren===2}">不默认</label>
                </div>
              </div>
            </div>
            <div class="alert-button">
              <button class="queren" @click="queren">确认添加</button>
              <button class="qvxiao" @click="tjlxi=false">取消</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我的订单-全部订单-操作-回传快递信息 -->
    
    <div class="menban" v-if="kdhz===1">
      <div class="main_add_express">
        <div class="title">
          <img class="fl" src="../../assets/gerenzhongxin/add.png" alt="">
          <span class="fl">回传快递信息</span>
        </div>
        <div class="line"></div>
        <div style="height: 20px;"></div>
        <div class="form">
          <div class="form-row">
            <div class="div-lable">快递材料：</div>
            <div class="div-input">
              <input type="text" value="原件材料" disabled>
            </div>
          </div>
          <div class="form-row">
            <div class="div-lable">快递材料：</div>
            <div class="div-input">
              <input type="text" placeholder="请输入寄件的快递公司">
            </div>
          </div>
          <div class="form-row">
            <div class="div-lable">快递材料：</div>
            <div class="div-input">
              <input type="text" placeholder="请输入寄件的快递公司">
            </div>
          </div>
          <div class="form-button">
            <button style="background: #FF8320;margin-left: 100px;">确认回传</button>
            <button style="background: #fc6703;margin-left: 40px;" @click="kdhz=0">取消</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      kdhz:0,
      gern:0,
      qvantab:1,
      grtab:0,
      zyj:112229.25,
      xzdd:102,
      baofei: 456519.01,
      ztyj:0,
      zanshi:false,
      tjlxi:false,
      shenqin:1,
      xiugai:false,
      form:{
        name:'',
        dianhua:'',
        youxaing:'',
        dizhi:'',
        xiangqing:'',
        omren:0,
      },
      csjs:'',//开始时间
      jssj:'',//开始时间
      tableData: [
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","电子发票","导出人员"],

        }, 
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
      ],
      tableDataliat: [
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        }, 
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        }, 
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
        {
          date: ["订单号：2024013010224613171","保单号：P2429000039301"],
          name: '平安团意险-工版（互联网）（GD）B',
          address: '投保人：新乡市矿山起重机制造有限公司',
          baofei: '4515',
          shijain:["录单时间：2024-01-30 10:22:46","承保时间：2024-01-02 21:51:52"],
          zuangtai:"核保通过",
          chaozuo:["前往支付","修改订单"],

        },
      ],
      v1:this.$route.query.a
    }
  },
  created(){
    this.fuzhi()
  },

  methods:{
    // 修改账号判断gern是否等于11
    fuzhi(){
      if(this.v1!=11){
        this.gern=0 
      }else if(this.v1==11){
        this.gern=this.v1
      }else{
        this.gern=this.v1
      }
      
    },
    wdyj(i){
      this.grtab=i
      if(this.grtab===0){
        this.zyj=112229.25;
        this.xzdd=102;
        this.baofei=456519.01;
        this.ztyj=0;
      }else if(this.grtab===1){
        this.zyj=0;
        this.xzdd=0;
        this.baofei=0;
        this.ztyj=0;
      }else if(this.grtab===2){
        this.zyj=1;
        this.xzdd=1035;
        this.baofei=207;
        this.ztyj=0;
      }
      else if(this.grtab===3){
        this.zyj=2;
        this.xzdd=9310;
        this.baofei=2855;
        this.ztyj=0;
      }
    },
    queren(){
      console.log(this.form);
    },
    // 修改密码
    baocun(){
      console.log(this.$route.query.a);
    }
  }
}
</script>

<style  scoped lang="scss">

.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.fl{
  float: left;
}
.fr{
  float: right;
}
.bkg-mask {
  width: 100%;
  height: 100%;
  .banner2{
    width: 100%;
    // background: #F0EFF5;
    background-image: linear-gradient(0deg, rgba(98, 201, 255, 0.00) 0%, #fc6703 100%);
    height: 400px;
    line-height: 80px;
    color: #fff;
    position: fixed;
    top: 0;
    z-index: -1;
  }
  .mask{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: #666;
    opacity: 0.5;
  }
}

.frame{
  min-height: 840px;
  // background-color: #F0EFF5;
  .content{
    width: 1370px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    padding-top: 90px;
    .side-nav-container{
      float: left;
      background: #FFFFFF;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);
      width: 180px;
      border-radius: 6px;
      min-height: 840px;
      overflow: hidden;
      margin-bottom: 30px;
      .title{
        width: 100%;
        height: 100px;
        background: url(https://img0.baidu.com/it/u=2349203975,1621864254&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500) no-repeat center;
        background-size: 100% 100%;
        text-align: center;
        color: #ffffff;
        line-height: 25px;
        padding-top: 35px;
        font-size: 12px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        box-sizing: border-box;
        .title-1{
          font-size: 24px;
        }
      }
      .side-nav{
        width: 100%;
        .nav-block-item{
          .nav-block-item-title{
            width: 100%;
            height: 50px;
            line-height: 50px;
            padding-left: 46px;
            box-sizing: border-box;
            position: relative;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            letter-spacing: -0.75px;
            .icon{
              width: 20px;
              height: 20px;
              position: absolute;
              left: 16px;
              top: 50%;
              margin-top: -10px;
            }
          }
          .nav-item{
            a{
              display: block;
              width: 100%;
              height: 35px;
              line-height: 35px;
              box-sizing: border-box;
              padding-left: 46px;
              font-size: 14px;
              color: #666;
            }
            a:hover{
              background-color: #E5F2FF;
              color: #666666;
            }
            .active{
              background-color: #fc6703;
              color: #FFF;
            }
          }
        }
      }
    }
    .content-body{
      width: 1170px;
      float: right;
      border-radius: 4px;
      max-height: 100%;
      height: auto;
      .count-frame{
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0 4px 16px 0 rgba(0,0,0,0.06);
        border-radius: 6px;
        .welcome{
          height: 50px;
          width: 100%;
          font-size: 14px;
          line-height: 50px;
          padding-left: 25px;
          span{
            font-size: 14px;
            line-height: 50px;
          }
          .ml-10{
            margin-left: 10px;
          }
          .ml-5{
            margin: 0 5px;
            font-size: 16px;
            font-weight: bold;
          }
        }
        // 分割线
        .welcome-sp{
          margin-left: 5%;
          width: 90%;
          border-bottom: 0.5px solid #F0EFF5;
        }
        .user-info{
          background: #FFFFFF;
          font-size: 16px;
          color: #333333;
          letter-spacing: -0.66px;
          padding: 0px 0px 15px 0px;
          .header {
              height: 100px;
              padding: 20px 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #eae7e7;
              .flex {
                width: 33.2%;
                text-align: center;
                padding-top: 16px;
                height: 84px;
                .header-avatar {
                  float: left;
                  margin-left: 38px;
                  width: 60px;
                  height: 60px;
                  border-radius: 100%;
                  background: #efefef;
                  img{
                    width: 60px;
                    height: 60px;
                  }
                }
                .header-user{
                  color: #333333;
                  font-size: 18px;
                  float: left;
                  margin-left: 20px;
                  width: 200px;
                  text-align: left;    
                  .header-tag{
                    display: inline-block;
                    margin-top: 10px;
                    padding: 0 10px;
                    height: 20px;
                    line-height: 20px;
                    color: #666;
                    font-size: 12px;
                    background: #FFFFFF;
                    border: 1px solid #DCDCDC;
                    border-radius: 10px;
                  }
                
                }
                .commission-ico{
                  height: 24px;
                  background: url(../../assets/gerenzhongxin/commission.png) no-repeat;
                  background-size: contain;
                  margin-right: 9px;
                  display: inline-block;
                  margin-right: 5px;
                  vertical-align: middle;
                  text-align: center;
                  padding-left: 30px;
                }
                .total_income {
                  color: #CC0001;
                  font-size: 26px;
                  margin-top: 8px;
                  .list_number_1 {
                      font-size: 14px;
                      margin-left: 8px;
                  }
              }
            }
          }
          .clearfix::after{
            height: 100px;
            padding: 20px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eae7e7;
          }
        }
        .count-info{
          .count_tit{
            margin: 25px 25px 0;
            border-bottom: 4px solid #F0EFF5;
            border-radius: 2px;
            color: #333333;
            font-size: 16px;
            .totalRegion{
              display: flex;
              justify-content: flex-start;
              li{
                margin-right: 40px;
                position: relative;
                padding: 0 5px 8px;
                user-select: none;
                cursor: pointer;
              }
              .active::after{
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 4px;
                background: #CC0001;
                border-radius: 2px;
              }
              .active{
                color: #CC0001;
              }
            }
          }
          .total{
            position: relative;
            .total-pane{
              margin: 25px;
              .list{
                float: left;
                width: 23.5%;
                height: 116px;
                font-size: 14px;
                color: #333333;
                text-align: center;
                background: #FFFFFF;
                box-shadow: 0 4px 8px 0 rgba(0,0,0,0.08);
                border-radius: 4px;
                .list-title {
                    font-size: 14px;
                    margin-top: 20px;
                    .addOrder-ico{
                      display: inline-block;
                      width: 21px;
                      height: 21px;
                      margin-right: 5px;
                      vertical-align: middle;
                      background: url(../../assets/gerenzhongxin/add_order.png) no-repeat;
                      background-size: contain;
                    }
                    .icon1{
                      background: url(../../assets/gerenzhongxin/total_premium.png) no-repeat;
                      background-size: contain;
                    }
                    .icon2{
                      background: url(../../assets/gerenzhongxin/commission.png) no-repeat;
                      background-size: contain;
                    }
                    .icon3{
                      background: url(../../assets/gerenzhongxin/income_commission.png) no-repeat;
                      background-size: contain;
                    }
                }
                .list-number{
                  margin-top: 20px;
                  font-size: 16px;
                  color: #333333; 
                  .list_number_1 {
                      font-size: 14px;
                      margin-left: 8px;
                  }
                }
              }
              .list-space{
                width: 2%;
                height: 1px;
                float: left;
              }
            }
          }
        }

      }
      // 表格
      .detail-frame{
        height: calc(100% - 475px);
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0 4px 16px 0 rgba(0,0,0,0.06);
        border-radius: 6px;
        .order{
          width: 100%;
          padding: 20px 30px;
          background-color: #FFFFFF;
          border-radius: 4px;
          box-sizing: border-box;
          .list-table{
            border: 0.5px solid #dcdcdc;
            .list-table-content{
              overflow-y: scroll;
              width: 100%;
            }
          }
        }
      }
      //账号设置
      .password-container{
        width: 100%;
        height: 840px;
        padding: 20px 30px 20px;
        background-color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bold;
          height: 48px;
          line-height: 30px;
          border-bottom: 1px solid #f0eff5;
          box-sizing: border-box;
          padding: 9px 0;
          img{
            width: 30px;
            height: 30px;
            margin-right: 15px;
          }
        }
        .pass-item{
          height: 52px;
          line-height: 52px;
          font-size: 14px;
          color: #333333;
          border-bottom: 1px solid #f0eff5;
          .label{
            width: 222px;
            text-indent: 46px;
          }
          input{
            height: 30px;
            border: 1px solid #dcdcdc;
            border-radius: 4px;
            padding: 0 10px;
            width: 240px;
            margin-top: 10px;
            outline: none;
          }
          .oper-btn{
            font-size: 14px;
            color: #fc6703;
            background: url(../../assets/gerenzhongxin/icon_filter_down_arrow.png) no-repeat;
            background-position: right center;
            background-size: 20px 10px;
            padding-right: 30px;
            cursor: pointer;
          }
          .oper-btns{
            font-size: 14px;
            color: #fc6703;
            background: url(../../assets/gerenzhongxin/icon_filter_up_arrow.png) no-repeat;
            background-position: right center;
            background-size: 20px 10px;
            padding-right: 30px;
            cursor: pointer;
          }
        }
        .js-modify-form{
          height: 130px;
          padding-left: 222px;
          border-bottom: 1px solid #f0eff5;
          // overflow: hidden;
          .modify-item{
            overflow: hidden;
            margin-top: 12px;
            .modify-label {
              width: 80px;
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              color: #333333;
           }
           .pass-input{
              height: 30px;
              border: 1px solid #dcdcdc;
              border-radius: 4px;
              padding: 0 10px;
              width: 240px;
              outline: none;
           }
          }
        }
        .modify-confirm {
          background: #fc6703;
          border-radius: 6px;
          width: 82px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          font-size: 14px;
          color: #ffffff;
          font-weight: bold;
          margin: 28px 0 15px 120px;
          cursor: pointer;
        }
      }
    }
    // 我的订单
    .order{
      padding: 20px 30px;
      background-color: white;
      height: calc(100% - 30px);
      border-radius: 4px;
      box-sizing: border-box;
      .add-container{
        height: 30px;
        line-height: 30px;
        margin-top: 20px;
        margin-bottom: 15px;
        overflow: hidden;
        .title{
          font-size: 16px;
          color: #333333;
          img{
            width: 30px;
            height: 30px;
          }
          span{
            margin-left: 10px;
            font-weight: bold;
          }
        }
        .card-add{
          a{
            display: block;
            font-size: 14px;
            color: #fc6703;
            img{
              margin-top: 7px;
              margin-right: 8px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
    // 全部订单
    .order-header{
      .search-name-container{
        overflow: hidden;
        height: 40px;
        .search-name-input{
          width: 290px;
          height: 32px;
          line-height: 32px;
          border: 1px solid #DCDCDC;
          border-radius: 4px;
          padding: 0 10px;
          margin-right: 10px;
          font-size: 12px;
          outline: none;
        }
        .search-name-btn {
          width: 40px;
          height: 32px;
          border-radius: 4px;
          background: url(../../assets/gerenzhongxin/search.png) no-repeat;
          background-size: 18px 18px;
          background-position: center center;
          background-color: #fc6703;
          border: none;
        }
        .filter-container{
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          color: #fc6703;
          position: relative;
          padding-right: 25px;
          cursor: pointer;  
        }
        .filter-container:after{
          content: '';
          position: absolute;
          width: 18px;
          height: 18px;
          top: calc(50% - 4px);
          margin-top: -5px;
          right: 0;
          line-height: 40px;
          font-size: 14px;
          color: #fc6703;
          background: url(../../assets/gerenzhongxin/icon_filter_up_arrow.png) no-repeat;
          background-position: center center;
          background-size: 100% 100%;
        }
        .filter-containers{
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          color: #fc6703;
          position: relative;
          padding-right: 25px;
          cursor: pointer;  
        }
        .filter-containers:after{
          content: '';
          position: absolute;
          width: 18px;
          height: 19px;
          top: calc(50% - 4px);
          margin-top: -5px;
          right: 0;
          line-height: 40px;
          font-size: 14px;
          color: #fc6703;
          background: url(../../assets/gerenzhongxin/icon_filter_down_arrow.png) no-repeat;
          background-position: center center;
          background-size: 100% 100%;
        }

      }
      .operation {
        margin-top: 16px;
        margin-bottom: 10px;
        .form-search-container {
            border: 1px solid #DCDCDC;
            border-radius: 6px;
            padding: 20px 22px 0;
            background-color: #F4F3FA;
            .clearfix{
              height: 52px;
              .form-item {
                margin-right: 25px;
                margin-bottom: 20px;
                .label {
                  width: 70px;
                  height: 32px;
                  line-height: 32px;
                  font-size: 12px;
                  font-weight: bold;
                  color: #333333;
                }

                .separator{
                  padding: 0 4px;
                  height: 40px;
                  line-height: 40px;
                  font-size: 14px;
                  color: #333333;
                }
                .btn {
                  width: 100px;
                  height: 32px;
                  text-align: center;
                  line-height: 32px;
                  border-radius: 6px;
                  color: #FFF;
                  font-size: 14px;
                  margin-right: 15px;
                  cursor: pointer;
                  border: none;
                }
                .btn.search {
                    background-color: #fc6703;
                }
                .reset {
                    background-color: #FF4700;
                }
               }
            }
        }
     }
    }
    .status-bar{
      overflow: hidden;
      margin-bottom: 8px;
      .status{
        height: 32px;
        font-size: 16px;
        color: #333333;
        margin-right: 36px;
        float: left;
        cursor: pointer;
        position: relative;
        display: block;
      }
       span:hover::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: #fc6703;
            border-radius: 2px;
        }
        .active {
          color: #fc6703;
          position: relative;
        }
        .active:after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: #fc6703;
            border-radius: 2px;
        }
    }
    .page-total{
      font-size: 14px;
      color: #666666;
      letter-spacing: -0.58px;
      // height: 50xp;
      line-height: 50px;
    }
    .page{
      line-height: 35px;
      margin: 10px auto;
      text-align: center;
    }
    .type{
      margin-bottom: 8px;
      border-bottom: 2px solid #D8D8D8;
      height: 40px;
      line-height: 40px;
      span {
        font-size: 16px;
        color: #333333;
        margin-right: 36px;
        float: left;
        cursor: pointer;
        position: relative;
     }
     span:hover{
      color: #fc6703;
     }
     .active {
        color: #fc6703;
        position: relative;
    }
    }
    // 
    .main{
      background: #FFFFFF;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.08);
      border-radius: 10px;
      z-index: 9999;
      position: fixed;
      top: 20%;
      width: 600px;
      left: calc(50% - 300px);
      .title{
        height: 40px;
        line-height: 40px;
        img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
          margin-top: 10px;
        }
        span{
          margin-left: 10px;
          font-size: 16px;
          color: #333;
          font-weight: bold;
        }
      }
      .line {
          width: 100%;
          height: 1px;
          border-bottom: 0.5px solid #dcdcdc;
      }
      .form{
        width: 100%;
        text-align: center;
        margin: 0 auto;
        padding: 0 35px;
        box-sizing: border-box;
        .form-row{
          font-size: 14px;
          color: #333333;
          margin-top: 15px;
          // height: 32px;
          overflow: hidden;
          label {
            float: left !important;
            margin-right: 15px;
            box-sizing: border-box;
            width: 20%;
            text-align: left;
          }
          .input-div{
            width: 77%;
            float: left;
            input{
              background: #FFFFFF;
              border: 1px solid #DCDCDC;
              border-radius: 4px;
              height: 30px;
              line-height: 30px;
              padding-left: 5px;
              width: 100%;
              float: left;
              outline: none;
            }
            textarea {
              background: #FFFFFF;
              border: 1px solid #DCDCDC;
              border-radius: 4px;
              height: 120px;
              padding-left: 5px;
              float: left;
              width: 100%;
              outline: none;
           }
           label{
            float: left !important;
            margin-right: 15px;
           }
           label::before{
            content: '';
            background: #f4f4f4;
            border-radius: 100%;
            border: 1px solid #b4b4b4;
            display: inline-block;
            width: 1.4em;
            height: 1.4em;
            position: relative;
            margin-right: 1em;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
           }
           .labels::before{
            background-color: #3197EE;
            box-shadow: inset 0 0 0 4px #f4f4f4;

           }
          }
          .select-div{
            select{
              height: 32px;
              border: 1px solid #DCDCDC;
              border-radius: 4px;
              font-size: 12px;
              color: #333;
              background: none;
              appearance: none;
              padding: 0 10px;
              float: left;
              background-color: #FFF;
              margin-right: 3.3%;
              outline: none;
            }
          }

        }
        .alert-button{
          width: 100%;
          margin-top: 15px;
          margin-bottom: 15px;
          overflow: hidden;
          button {
            border-radius: 4px;
            font-size: 16px;
            color: #FFFFFF;
            letter-spacing: -0.21px;
            width: 150px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            display: block;
            float: left;
            font-weight: bold;
            border: none;
          }
          .queren{
            background: #FF8320;
            margin-left: 100px;
          }
          .qvxiao{
            background: #fc6703;
            margin-left: 50px;
          }
        }
      }

    }
  }
}
// 我的订单-全部订单-操作-回传快递信息
.menban{
  position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    .main_add_express{
      background: #FFFFFF;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.08);
      border-radius: 6px;
      z-index: 9999;
      position: fixed;
      top: 20%;
      width: 600px;
      left: calc(50% - 300px);
      padding-left: 20px;
      padding-right: 20px;
      .title {
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
        font-size: 16px;
        color: #333333;
        img{
          margin-top: 10px;
          width: 20px;
          height: 20px;
        }
        span{
          margin-left: 10px;
        }
      }
      .line{
        width: 100%;
        height: 1px;
        border-bottom: 0.5px solid #dcdcdc;
      }
      .form{
        width: 100%;
        .form-row{
          font-size: 14px;
          color: #333333;
          line-height: 40px;
          height: 40px;
          .div-lable{
            width: 120px;
            text-align: right;
            float: left;
          }
          .div-input{
            width: 480px;
            float: left;
            input{
              background: #FFFFFF;
              border: 1px solid #DCDCDC;
              border-radius: 4px;
              height: 30px;
              line-height: 30px;
              padding-left: 5px;
              margin-left: 5px;
              width: calc(100% - 10px);
              outline: none;
            }
          }
        }
        .form-button{
          margin-top: 20px;
          margin-bottom: 20px;
          overflow: auto;
          button{
            width: 100px;
            border-radius: 4px;
            font-size: 16px;
            color: #FFFFFF;
            letter-spacing: -0.21px;
            width: 180px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            display: block;
            border: none;
            float: left;
          }
        }
      }
    }
}
// 下拉菜单样式
.icon-arrow-wrap{
  height: 32px;
  margin-left: 8px;
  position: relative;
  select{
    width: 100%;
    height: 32px;
    border: 1px solid #DCDCDC;
    border-radius: 4px;
    font-size: 12px;
    color: #333;
    background: none;
    appearance: none;
    padding: 0 10px;
    background-color: #FFF;
    outline: none;
    position: relative;
  }

}
.icon-arrow-wrap::after{
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    top: calc(50% - 4px);
    margin-top: -5px;
    right: 12px;
    background: url(../../assets/gerenzhongxin/icon_filter_down_arrow.png) no-repeat;
    background-position: center center;
    background-size: 100% 100%;
}
// 修改表格样式
:deep .has-gutter .is-leaf{
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  background-color: #E5F2FF;
  height: 45px;
}
:deep .el-table .el-table__cell{
  margin: 0;
  padding: 0;
}
:deep .el-table__body-wrapper .el-table__cell{
  font-size: 12px;
  a{
    color: #428bca;
  }
}
:deep .el-table .cell  {
  padding: 0 5px;

}
:deep .el-table__row .cell{
  //height: 60px;
  // 网格布局让元素的上下居中
  display: grid;  
  align-items: center;
  
}
:deep .el-table th.el-table__cell>.cell{
  padding: 0 5px;
}
:deep .el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: rgb(244, 244, 244) !important;
} 
// 时间下拉菜单
:deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
    height:32px;
    width: 140px;
    font-size: 12px;
    color: #333;
    input{
      width: 100%;
      height: 100%;
    }

    .el-input__prefix,.el-input__suffix-inner{
      display: none;
    }
    .el-input__inner {
      padding-left: 10px;
    }
}
.h500{
  height: 500px !important;
}
.h550{
  height: 550px !important;
}
</style>