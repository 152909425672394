<template>
  <div  class="qywh">
    <div class="neme"> 保镜企业文化</div>
    <div style="overflow: hidden;">
        <div class="forlist">
            <div class="text">新价值管理文化体系</div>
            <img src="../../assets/gywm/whtx_zh.png" alt="">
        </div>
        <div class="forlist">
            <div class="text">新价值管理文化体系内在关联</div>
            <img src="../../assets/gywm/xjzgltx_zh.png" alt="">
        </div>
        <div class="forlist">
            <div class="text">人的修行：价值修行 专业修行</div>
            <img src="../../assets/gywm/parxx_zh.png" alt="">
        </div>
        <div class="forlist">
            <div class="text">价值最大化，是检验一切工作的唯一标准</div>
            <img src="../../assets/gywm/jzzdh_zh.png" alt="">
        </div>
    </div>
    <!-- 公共尾部 -->
    <div class="foot-container">
        <div class="link-wrap">
                保镜科技有限公司 版权所有 
            <a href="javascript:;" style="cursor:pointer;color: #ffffff;"> 京ICP备17058300号-1</a>
            <div class="beian">
                <img style="width: 16px;vertical-align: middle;" src="../../assets/souye/wangan.png" alt="">
                <span style="display: inline-block;vertical-align: middle;">京公网安备 11010502038814号 | 未经许可不得转载</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
    };
  },
  computed:{
  },
  watch:{
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.qywh{
    margin-top: 100px;
    .neme{
        width: 100%;
        height: 50px;
        margin-left:  calc(50% - 80px);
        line-height: 50px;
        font-size: 25px;
        font-weight: bold;
    }
    .forlist{
        width: 49%;
        margin: 15px 0.5% 10px;
        padding: 15px 30px;
        background-color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        float: left;
        
        .text{
            margin-bottom: 7px;
            font-size: 18px;
            line-height: 48px;
        }
        img{
            width: 100%;
            height: 300px;
        }
    }
}
/* 公共尾部 */  
.foot-container{
    width: 100%;
    text-align: center;
    background-color: #333333;
    font-size: 12px;
    color: #ffffff;
    padding: 18px 0;
    line-height: 22px;
    .link-wrap{
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        .beian{
            display: flex;
            align-items: center;
        }
    }
}
</style>
