<template>
  <div class="sqpg">
    <div class="content">
        <div class="panel">
            <div style="padding: 20px;">
                <div class="title-fix">
                    <div style="margin: 0 45px;">
                        <div class="endorse-title fl">
                            您将要对<span>新疆欣明纺织科技有限公司</span>的保单
                            <span>(10455023902422610397) </span>
                            进行批单操作。
                        </div>
                        <div class="right fr">
                            <div class="profession-btn download-template">
                                <img src="../../assets/gerenzhongxin/download.png" alt="">
                                <span>下载模板</span>
                            </div>
                            <div class="profession-btn upload-excel-btn">
                                <img src="../../assets/gerenzhongxin/upload.png" alt="">
                                <span>点击上传</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height: 60px;"></div>
                <div class="division-block" v-if="false">
                    <div class="division-block-title">批单信息</div>
                    <div style="height: 66px;"></div>
                    <div class="basic-info">
                        <div class="time-container">
                            <div class="common-item fl start-time-item">
                                <div class="common-item-label fl">联系人姓名</div>
                            </div>
                            <div class="input-container fl">
                                <input type="text" class="inp">
                            </div>
                        </div>
                        <div class="time-container">
                            <div class="common-item fl start-time-item">
                                <div class="common-item-label fl">联系人姓名</div>
                            </div>
                            <div class="input-container fl">
                                <input type="text" class="inp">
                            </div>
                        </div>
                    </div>
                    <div style="height: 24px;"></div>
                    <div class="basic-info">
                        <div class="time-container">
                            <div class="common-item fl start-time-item">
                                <div class="common-item-label fl">联系人姓名</div>
                            </div>
                            <div class="input-container fl">
                                <input type="text" class="inp">
                            </div>
                        </div>
                    </div>
                    <div style="height: 24px;"></div>
                </div>
                <div class="division-block">
                    <div class="division-block-title">本次批单人员</div>
                    <div class="profession-container">
                        <div class="common-item fr">
                            <div class="profession-btn add-member-btn">
                                <img src="../../assets/gerenzhongxin/add (1).png" alt="">
                                <span>添加人员</span>
                            </div>
                        </div>
                    </div>
                    <div class="error-tip repeat_tip" v-if="false">
                        <img src="../../assets/gerenzhongxin/icon_remind.png" alt="" class="fl">
                        <div class="error-tip-content fl">存在重复人员，已用黄色背景提示</div>
                    </div>
                    <div class="error-tip repeat_tip"  v-if="false">
                        <img src="../../assets/gerenzhongxin/icon_remind.png" alt="" class="fl">
                        <div class="error-tip-content fl">存在人员已购买团单，已用斜体加粗提示</div>
                    </div>
                    <div class="info-item-container">
                        <el-table
                            :data="tableDataliats"
                            :header-cell-style="{'text-align':'center'}"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="date"
                            width="135"
                            align="left"
                            label="批改类型">
                            </el-table-column>
                            <el-table-column
                            prop="name"
                            width="135"
                            align="center"
                            label="姓名">
                            </el-table-column>
                            <el-table-column
                            prop="address"
                            align="center"
                            width="250"
                            label="证件类型	">
                            </el-table-column>
                            <el-table-column
                            prop="baofei"
                            align="center"
                            width="170"
                            label="证件号码">
                            </el-table-column>
                            <el-table-column
                            prop="shijain"
                            align="center"
                            width="200"
                            label="出生日期">
                            </el-table-column>
                            <el-table-column
                            prop="zuangtai"
                            width="170"
                            align="center"
                            label="性别	">
                            </el-table-column>
                            <el-table-column
                            prop="zuangtai"
                            width="100"
                            align="center"
                            label="职业名称	">
                            </el-table-column>
                            <el-table-column
                            prop="chaozuo"
                            align="center"
                            label="操作	">
                            <template>
                                <div>
                                    <a style="color: #3399FF;margin-right: 10px;" href="javascript:;">替换</a>
                                    <a style="color: #FF3333;" href="javascript:;">批改</a>
                                </div>
                            </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div style="height: 24px;"></div>
                </div>
                <div class="division-block">
                    <div class="division-block-title">人员清单</div>
                    <div class="profession-container">
                        <div class="common-item fr">
                            <div class="profession-btn add-member-btn">
                                <span>导出清单</span>
                            </div>
                        </div>
                    </div>
                    <div class="profession-containers">
                        <div class="common-item fl form-item-margin">
                            <div class="common-item-label fl">人员搜索</div>
                        </div>
                        <div class="input-container fl">
                            <input type="text" class="form-control" placeholder="请输入人员姓名进行搜索">
                        </div>
                    </div>
                    <div class="info-item-container">
                        <el-table
                            :data="tableDataliat"
                            :header-cell-style="{'text-align':'center'}"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="name"
                            width="150"
                            align="center"
                            label="姓名">
                            </el-table-column>
                            <el-table-column
                            prop="zjlx"
                            align="center"
                            width="150"
                            label="证件类型	">
                            </el-table-column>
                            <el-table-column
                            prop="zjhm"
                            align="center"
                            width="170"
                            label="证件号码">
                            </el-table-column>
                            <el-table-column
                            prop="csrq"
                            align="center"
                            width="300"
                            label="出生日期">
                            </el-table-column>
                            <el-table-column
                            prop="xb"
                            width="100"
                            align="center"
                            label="性别	">
                            </el-table-column>
                            <el-table-column
                            prop="zymc"
                            width="290"
                            align="center"
                            label="职业名称	">
                            </el-table-column>
                            <el-table-column
                            prop="chaozuo"
                            align="center"
                            label="操作	">
                            <template>
                                <div>
                                    <a style="color: #3399FF;margin-right: 10px;" href="javascript:;">替换</a>
                                    <a style="color: #FF3333;" href="javascript:;">批改</a>
                                </div>
                            </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div style="height: 24px;"></div>
                </div>
                <div style="height: 40px;"></div>
                <div class="total-fare-container">
                    <div class="right-block fr">
                        <div class="operation-btn fl save-endorse-btn">提交批单</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            tableDataliat: [
                {
                    name:'迪力夏提·艾斯拉伊力',
                    zjlx:'身份证',
                    zjhm:'652922200206132591',
                    csrq:'2002-06-13',
                    xb:'男',
                    zymc:'纺织工人',
                }, 
                {
                    name:'迪力夏提·艾斯拉伊力',
                    zjlx:'身份证',
                    zjhm:'652922200206132591',
                    csrq:'2002-06-13',
                    xb:'男',
                    zymc:'纺织工人',
                },
                {
                    name:'迪力夏提·艾斯拉伊力',
                    zjlx:'身份证',
                    zjhm:'652922200206132591',
                    csrq:'2002-06-13',
                    xb:'男',
                    zymc:'纺织工人',
                },
                {
                    name:'迪力夏提·艾斯拉伊力',
                    zjlx:'身份证',
                    zjhm:'652922200206132591',
                    csrq:'2002-06-13',
                    xb:'男',
                    zymc:'纺织工人',
                },
            ],
        }
    }
}
</script>

<style scoped lang="scss">
.sqpg{
    color: #707478;
    background-color: rgb(240, 239, 245);
    .content{
        padding: 20px 25px;
        .panel{
            background-color: transparent;
            .title-fix{
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 70px;
                line-height: 70px;
                background-color: #fff;
                z-index: 10;
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
                .endorse-title{
                    font-size: 18px;
                    span{
                        color: #333;
                    }
                }
                .right{

                    .profession-btn {
                        width: 80px;
                        text-align: center;
                        border-radius: 4px;
                        background: #fc6703;
                        font-size: 14px;
                        color: #ffffff;
                        font-weight: bold;
                        display: inline-block;
                        cursor: pointer;
                        min-width: 120px;
                        height: 38px;
                        line-height: 38px;
                        padding: 0 10px;
                        box-sizing: border-box;
                        user-select: none;
                        img{
                            width: 20px;
                            height: 20px;
                            margin-right: 8px;
                            display: inline-block;
                            vertical-align: middle;
                        }
                        span{
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                    .upload-excel-btn {
                        background-color: #12ca8a;
                        margin-left: 10px;
                    }
                }
            }
            .division-block{
                background-color: #fff;
                position: relative;
                border-radius: 6px;
                overflow: hidden;
                padding: 0 64px;
                margin-bottom: 16px;
                .division-block-title{
                    width: 160px;
                    height: 44px;
                    box-sizing: border-box;
                    position: absolute;
                    left: 0;
                    top: 0;
                    line-height: 44px;
                    padding-left: 28px;
                    box-sizing: border-box;
                    font-size: 18px;
                    color: #ffffff;
                    font-weight: bold;
                    background-image: linear-gradient(-90deg, #62c9ff 0%, #3399ff 100%);
                    border-radius: 6px 0 35px 0;
                }
                .basic-info{
                    display: flex;
                    .time-container{
                        overflow: hidden;
                        margin-right: 60px;
                        .start-time-item{
                            
                            .common-item-label {
                                width: 75px;
                                height: 38px;
                                line-height: 38px;
                                font-size: 14px;
                                color: #666666;
                                margin-right: 10px;
                            }


                        }
                        .inp{
                                width: 250px;
                                height: 38px;
                                line-height: 38px;
                                box-sizing: border-box;
                                border: 1px solid #dcdcdc;
                                border-radius: 4px;
                                background-color: #fff;
                                padding: 8px 14px;
                                outline: none;
                        }
                    }
                }
                .profession-container{
                    border-bottom: none;
                    padding: 22px 0px;
                    min-height: 80px;
                    box-sizing: border-box;
                    .common-item{
                        .profession-btn {
                            min-width: 120px;
                            height: 38px;
                            line-height: 38px;
                            padding: 0 10px;
                            box-sizing: border-box;
                            user-select: none;
                            img{
                                width: 20px;
                                height: 20px;
                                margin-right: 8px;
                                display: inline-block;
                                vertical-align: middle;
                            }
                            span{
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                        .profession-btn {
                            width: 80px;
                            text-align: center;
                            border-radius: 4px;
                            background: #fc6703;
                            font-size: 14px;
                            color: #ffffff;
                            font-weight: bold;
                            display: inline-block;
                            cursor: pointer;
                        }
                    }
                }
                .error-tip {
                    width: 870px;
                    line-height: 36px;
                    padding: 0 14px;
                    background: #fff7f8;
                    border-radius: 6px;
                    margin-top: 10px;
                    overflow: hidden;
                    img{
                        width: 20px;
                        height: 20px;
                        margin-top: 8px;
                        margin-right: 10px;
                    }
                }
                .repeat_tip {
                    margin-bottom: 10px;
                }
                .info-item-container{
                    background: #fffdfd;
                    margin-bottom: 18px;
                }
                .profession-containers{
                    border-bottom: none;
                    padding-bottom: 22px;
                    min-height: 58px;
                    box-sizing: border-box; 
                    .common-item-label{
                        width: 75px;
                        height: 38px;
                        line-height: 38px;
                        font-size: 14px;
                        color: #666666;
                        margin-right: 10px;
                    }
                    .form-control{
                        width: 250px;
                        height: 38px;
                        line-height: 38px;
                        box-sizing: border-box;
                        border: 1px solid #dcdcdc;
                        border-radius: 4px;
                        background-color: #fff;
                        padding: 8px 14px;
                        outline: none;
                    }
                }
            }
            .total-fare-container{
                width: 100%;
                position: fixed;
                left: 0;
                bottom: 0;
                background-color: #fff;
                height: 70px;
                box-sizing: border-box;
                padding: 10px 26px;
                z-index: 10;
                box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.06);
                .right-block{
                    overflow: hidden;
                    .operation-btn{
                        width: 200px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 18px;
                    }
                    .operation-btn {
                        text-align: center;
                        border-radius: 4px;
                        background: #fc6703;
                        color: #ffffff;
                        font-weight: bold;
                        display: inline-block;
                        user-select: none;
                    }
                }
            }
        }
    }
}
.fl{
    float: left;
}
.fr{
    float: right;
}
// 修改表格样式
:deep .has-gutter .is-leaf{
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  background-color: #fff7f8;
  height: 45px;
}
:deep .el-table .el-table__cell{
  margin: 0;
  padding: 0;
}
:deep .el-table__body-wrapper .el-table__cell{
  font-size: 12px;
  a{
    color: #428bca;
  }
}
:deep .el-table .cell  {
  padding: 0 5px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;

}
:deep .el-table__row .cell{
  //height: 60px;
  // 网格布局让元素的上下居中
  display: grid;  
  align-items: center;
  
}
:deep .el-table th.el-table__cell>.cell{
  padding: 0 5px;
}
:deep .el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: rgb(244, 244, 244) !important;
} 
:deep .el-table__empty-text{
    display: none;
}
:deep .el-table__empty-block{
    min-height: 0 ;
}
</style>