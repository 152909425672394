<template>
  <div class="index">
    <div class="img">
        <img src="../../assets/souye/index-banner.jpg" alt="">
    </div>
    <!-- 三个模块 -->
    <div class="sange">
        <div class="zhfw">
            智 慧 服 务
        </div>
        <div class="zhfw">
            智 慧 服 务
        </div>
        <div class="zhfw">
            智 慧 服 务
        </div>
    </div>

    <!-- 分类 -->
    <div class="fl">
        <div class="fltext"v-for="item in lists" :key="item">{{item.name}}</div>
    </div>
    <!-- 分类 -->
    <div class="fls">
        <div class="fltext" v-for="item in lists" :key="item">
            <img :src="item.imag" alt="">
            <!-- 产品情况 -->
            <div class="detail">
                <div class="name">{{item.name}}</div>
                <div class="guarantee">
                    <p v-for="inem in item.leixin" :key="item">{{inem}}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- 公共尾部 -->
    <div class="foot-container">
        <div class="db-top">
            <div class="listdb">
                <h5>合作保险公司</h5>
                <a href="javascript">平安保险</a>
                <a href="javascript">众泰保险</a>
                <a href="javascript">三星保险</a>
                <a href="javascript">中国人寿保险</a>
                <a href="javascript">众安保险</a>
                <a href="javascript">安盛天平保险</a>
                <a href="javascript">华泰保险</a>
            </div>
            <div class="listdb">
                <h5>产品和定价</h5>
                <a href="javascript">全部产品</a>
                <a href="javascript">免费试用</a>
                <a href="javascript">产品动态</a>
                <a href="javascript">产品定价</a>
                <a href="javascript">价格计算器</a>
            </div>
            <div class="listdb">
                <h5>解决方案</h5>
                <a href="javascript">技术解决方案</a>
            </div>
            <div class="listdb">
                <h5>文档与社区</h5>
                <a href="javascript">文档</a>
                <a href="javascript">开发者社区</a>
                <a href="javascript">天池大赛</a>
                <a href="javascript">培训与认证</a>
            </div>
            <div class="listdb">
                <h5>权益中心</h5>
                <a href="javascript">免费试用</a>
                <a href="javascript">高校计划</a>
                <a href="javascript">企业扶持计划</a>
                <a href="javascript">推荐返现计划</a>
            </div>
            <div class="listdb">
                <h5>支持与服务</h5>
                <a href="javascript">基础服务</a>
                <a href="javascript">企业增值服务</a>
                <a href="javascript">官网公告</a>
                <a href="javascript">健康看板</a>
                <a href="javascript">信任中心</a>
            </div>
            <div class="listdb">
                <h5>关注保镜科技</h5>
                <img src="../../assets/gywm/bjkj.png" alt="">
                <p>联系我们：400102010</p>
            </div>
        </div>
        <div class="link-wrap">
            保镜科技有限公司 版权所有 
            <a href="javascript:;" style="cursor:pointer;color: #000;"> 京ICP备17058300号-1</a>
            <div class="beian">
                <img style="width: 16px;vertical-align: middle;" src="../../assets/souye/wangan.png" alt="">
                <span style="display: inline-block;vertical-align: middle;">京公网安备 11010502038814号 | 未经许可不得转载</span>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
        lists:[
            {   imag:"https://baoxian.pingan.com/uploadfile/shop/image/website/1688381838713.jpg",//imag
                name:'平 安 团 体 意 外 险 方 案',
                leixin:[
                    '投保年龄：16-65周岁',
                    "保障期限：1月",
                    '保障期限：1个月、3个月、6个月、一年',
                    '起投人数：',
                    "1-4类3人起保，含5类5人起保；"
                ],//保险分类
            },
            {   imag:"https://baoxian.pingan.com/uploadfile/shop/image/website/1663813556441.jpg",//imag
                name:'华 泰 团 体 意 外 险',
                leixin:[
                    '投保年龄：16-65周岁',
                    "保障期限：1月",
                    '6人起投',
                    "职业类别1-6类"
                ],//保险分类
            },
            {   imag:"https://baoxian.pingan.com/uploadfile/shop/image/1634717680715-qy4.jpg",//imag
                name:'三 星 - 团 体 意 外 险',
                leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '1-6类',
                        '3人起保',
                        '支持月单',
                ],//保险分类
            },
            {   imag:"https://baoxian.pingan.com/uploadfile/clause/image/website/1687153310481.jpg",//imag
                name:'众 安 雇 主 险',
                leixin:[
                        '投保年龄：18-60周岁',
                        "保障期限：1年",
                ],//保险分类
            },
            {   imag:"https://baoxian.pingan.com/uploadfile/clause/image/website/1687156309973.jpg",//imag
                name:'安 盛 天 平 团 体 意 外 险',
                leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                ],//保险分类
            },
            {   imag:"https://baoxian.pingan.com/uploadfile/shop/image/1634720695516-qy20.jpg",//imag
                name:'人 保 1 - 6 类 守 护 神 个 人 意 外 险',
                leixin:[
                        '投保年龄：18-60周岁',
                        "保障期限：1年",
                        '默认T+3生效',
                        '承保1-6类职业',
                        '可保高空',
                ],//保险分类
            }
        ]

    };
  },
  computed:{
  },
  watch:{
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.index{
    padding-top: 50px;
}
.sange{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 30px 0 0 0;
    .zhfw{
        width: 20%;
        height: 80px;
        background-color: rgb(252, 103, 3);
        color: #fff;
        text-align: center;
        line-height: 80px;
        font-size: 25px;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.3);
    }
}
.img{
    width: 100%;
    height: 400px;
    img{
        width: 100%;
        height: 100%;
    }
}
.fl{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;  
    margin: 30px 0 ;
    background-color: #fff;
    padding-top: 40px;
    padding-bottom: 20px;
    .fltext{
        height: 50PX;
        flex: 0 0 calc(33% - 7%); /* 每个子元素占据50%的宽度，减去间距 */  
        margin-bottom: 10px; /* 子元素之间的垂直间距 */  
        background-color: #f2f2f2;  
        padding: 10px;  
        box-sizing: border-box; /* 包含内边距和边框 */
        background-color: rgb(252, 103, 3);
        color: #fff;
        text-align: center;
        line-height: 30px;
        font-size: 20px;
        border-radius: 10px;
        cursor: pointer;
    }
}
/* 类表 */  
.fls{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;  
    margin: 50px 0 ;
    .fltext{
        height: 220PX;
        flex: 0 0 calc(33% - 2%); /* 每个子元素占据50%的宽度，减去间距 */  
        margin-bottom: 10px; /* 子元素之间的垂直间距 */  
        background-color: #f2f2f2;  
        padding: 10px;  
        box-sizing: border-box; /* 包含内边距和边框 */
        background-color: #fff;
        color: #000;
        font-size: 20px;
        border-radius: 10px;
        cursor: pointer;
        img{
            width: 30%;
            height: 100%;
            border-radius: 10px;
            float: left;
        }
        /* 产品情况*/  
        .detail{
            width: 240px;
            float: left;
            margin-left: 20px;
            .name{
                height: 20px;
                font-size: 14px;
            }
            .guarantee{
                width: 100%;
                font-size: 12px;
                color: #333;
                line-height: 25px;
                overflow: hidden;
                p{
                    background: url(../../assets/souye/select-1.png) no-repeat;
                    background-size: 19px 16px;
                    background-position: 0 4px;
                    padding-left: 30px;
                    overflow-wrap: break-word;
                }
            }
        }
    }
}
/* 公共尾部 */  
.foot-container{
    width: 100%;
    background-color: rgb(236, 236, 236);
    font-size: 12px;
    color: #ffffff;
    padding: 18px 50px;
    line-height: 22px;
    .db-top{
        display: flex;
        .listdb{
            margin-right: 8%;
            h5{
                color: #000;
                font-weight: bold;
                margin-bottom: 20px;
            }
            a{
                color: #666;
                line-height: 24px;
                font-size: 14px;
                margin-bottom: 8px;
                display: block;
            }
            a:hover{
                color:rgb(252, 103, 3) ;
            }
            img{
                width: 80px;
            }
            p{
                color: #000;
                margin-top: 10px;
            }
        }
    }
    .link-wrap{
        margin: 0 auto;
        display: inline-block;
        margin-left: calc(50% - 10%);
        color: #000;
        .beian{
            display: flex;
            align-items: center;
        }
    }
}
</style>
