<template>
  <div class="shouye">
    <!-- 横幅图片 -->
    <div class="imag">
        <img src="../../assets/souye/index-banner.jpg" alt="">
    </div>
    <!-- 主体 -->
    <div class="common-width">
        <div class="right-block">
            <!-- 搜索 -->
            <div class="search">
                <div class="clearfix">
                    <p for="">保险公司：</p>
                    <div class="leibiao">
                        <span @click="listTab(0)" :class="{'active':xiehuan===0}">全部公司</span>
                        <span @click="listTab(1)" :class="{'active':xiehuan===1}">平安产险</span>
                        <span @click="listTab(2)" :class="{'active':xiehuan===2}">华泰财险</span>
                        <span @click="listTab(3)" :class="{'active':xiehuan===3}">众安在线财险</span>
                        <span @click="listTab(4)" :class="{'active':xiehuan===4}">安盛天平</span>
                        <span @click="listTab(5)" :class="{'active':xiehuan===5}">人保财险</span>
                        <span @click="listTab(6)" :class="{'active':xiehuan===6}">三星财险</span>
                        <span @click="listTab(7)" :class="{'active':xiehuan===7}">太保财险</span>
                        <span @click="listTab(8)" :class="{'active':xiehuan===8}">国富人寿</span>
                    </div>
                    
                </div>
                <div class="xian"></div>
                <div class="clearfix">
                    <p for="">险种类型：</p>
                    <div class="leibiao">
                        <span @click="xianzong(0)" :class="{'active':xzlx===0}">全部险种</span>
                        <span @click="xianzong(1)" :class="{'active':xzlx===1}">团体意外险</span>
                        <span @click="xianzong(2)" :class="{'active':xzlx===2}">雇主责任险</span>
                        <span @click="xianzong(3)" :class="{'active':xzlx===3}">个人意外险</span>
                    </div>
                    
                </div>
                <div class="xian"></div>
                <div class="total">共找到<span>17</span>款产品</div>
            </div>
            <!-- 产品列表 -->
            <div class="list">
                <div class="row" v-for="(item,index) in leibiaoList" :key="index">
                    <div class="product-title">
                        <div class="fl">
                            {{item.name}}
                            <div style="font-size: 12px; color: #666; margin-top: 5px;">{{ item.tis }}</div>
                        </div>
                        <div style="clear:both"></div>
                        <div class="product-oper-btn-block">
                            <div class="qrcode-btn">
                                <img @click="shaomaxq(item)" src="../../assets/souye/icon_qrcode.png" alt="" class="botimg">
                                <div class="shaoma">扫码投保</div>
                            </div>
                            <div class="qrcode-btn" v-if="item.cpxxs===1">
                                <!-- <router-link tag="div" target="_blank" :to="{path:'/lijitoubao',query:{id:1}}">立即投保</router-link> -->
                                <img @click="shaomaxqs(item)" src="../../assets/souye/icon_info.png" alt="" class="botimg">
                                <div class="shaoma">产品信息</div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="detail-wrap">
                        <div class="product-banner">
                            <img class="product-img" :src="item.imag" alt="">
                            <div class="product-commission">
                                <span class="world">推广费</span>
                                <span class="proportion">{{item.tuiguang}}%</span>
                                <img v-if="item.tuiimg===1" src="../../assets/souye/list-nav.png" alt="" class="commission-detail-icon">
                                <div class="commission-detail-wrap">
                                    <el-table
                                    :data="item.tuigxq"
                                    :header-cell-style="{'text-align':'center'}"
                                    border
                                    style="width: 100%">
                                    <el-table-column
                                        prop="name"
                                        width="177"
                                        align="center"
                                        label="计划方案">
                                    </el-table-column>
                                    <el-table-column
                                        prop="age"
                                        width="47"
                                        align="center"
                                        label="推广费">
                                    </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>
                        <!-- 产品情况 -->
                        <div class="detail">
                            <div class="guarantee">
                                <p v-for="inem in item.leixin">{{inem}}</p>
                            </div>
                        </div>
                        <div class="safeguard-content-container">
                            <div class="title">保障内容</div>
                            <div class="item" v-for="inne in item.baozhang">
                                <div class="fl type">{{inne.name}}</div>
                                <div class="fc-666">{{inne.jinge}}</div>
                            </div>
                            <!-- 上传指引 -->
                            <div class="lima-notice" v-if="item.xq===1">
                                <p class="fc-blue">本产品投保成功后，需补传</p>
                                <p class="no-break fc-blue">
                                    <span class="fc-red big-font">"投保回执-盖章件"</span>
                                    <span class="fc-red">（上传）</span>避免影响结算。
                                </p>
                                <p class="no-break">
                                    上传指引：点击“个人中心-保单回执”→点击“上传回执”下载→上传回执
                                </p>
                                <!-- 单个 -->
                                <!-- <p class="fc-red">扫二维码  (<span>必须手机操作</span>)  出单+支付</p> -->
                            </div>
                            <div class="qrcode-notice-text lima-notice" v-if="item.xq===3">
                                <p>扫二维码  (<span>必须手机操作</span>)  出单+支付</p>
                            </div>
                            <div class="lima-notice middle-font"  v-if="item.xq===4">
                                <strong>友情提示</strong><br>
                                1）投保须有劳动合同及工资发放记录；<br>
                                2）索赔时，须有出险前3个月内的社保缴纳记录，若没有则按70%赔付。                                
                            </div>
                        </div>
                        <div class="insure fr">
                            <img :src="item.icon" alt="" class="company-logo">
                            <p class="price">¥<span> {{item.jingge}}</span>起</p>
                            <p>
                                <!-- <router-link tag="a" target="_blank" :to="{path:'/lijitoubao',query:{id:1}}" class="btn_insure">立即投保</router-link> -->
                                <!-- <router-link tag="a" target="_blank" :to="{path:'/kuaishubj',query:{id:1}}" class="btn-quickoffer btn_insure" v-if="item.ksbj===1">快速报价</router-link> -->
                                <a href="javascript:;" class="btn_insure" @click="litb(item)">立即投保</a>
                                <a href="javascript:;" class="btn-quickoffer btn_insure"  @click="ksbj(item)" v-if="item.ksbj===1">快速报价</a>
                            </p>
                        </div>
                    </div>
                    <div style="border-top: 1px solid #F0EFF5; color: red; padding: 0 24px; line-height: 30px; font-size: 12px;">{{item.zuyi}}</div>
                        <!-- 点击出现弹窗 -->
                    <div class="qrcode-dialog-mask" v-show="ewxq===true">
                        <div class="qrcode-dialog">
                            <div class="qrcode-dialog-title">
                                扫码投保
                                <div class="close-qrcode-dialog" @click="ewxq=false"></div>
                            </div>
                            <div class="qrcode-dialog-content">
                                <div class="qrcode-img">
                                    <img src="../../assets/souye/erweima.png" alt="">
                                </div>
                                <div class="qrcode-img-text">{{smxq.name}}</div>
                            </div>
                            <div class="qrcode-url">
                                <a href="javascript:;" class="qrcode-url-text">{{smxq.ewlj}}</a>
                                <button @click="fuzhi">复制</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- 左侧小图标 -->
        <div class="right-tips">
            <div class="clearfix">
                <img src="../../assets/souye/index-title.png" alt="">
            </div>
            <div class="tip-item">
                <div class="js-toggle-tip">
                    <div class="xts">小贴士</div>
                    <div class="question-content">
                        如何查看
                        <span>保险公司账户、保司快递地址</span>
                    </div>
                </div>
                <div class="where-tip-container"> 
                    <img class="fl" src="../../assets/souye/step.png" alt="">
                    <div class="fl info">
                        <div class="first">点击产品板块右上角的<span>“产品信息”</span>按钮，进入产品详情页。</div>
                        <div class="two">在产品详情页<span>“对公转账”“快递地址”</span>的切换栏中查看公司帐户信息。</div>
                    </div>
                </div>
                <div style="height: 15px;"></div>
            </div>
        </div>
    </div>
    <!-- 公共尾部 -->
    <div class="foot-container">
        <div class="link-wrap">
            保镜科技有限公司 版权所有 
        <a href="javascript:;" style="cursor:pointer;color: #ffffff;"> 京ICP备17058300号-1</a>
        <div class="beian">
            <img style="width: 16px;vertical-align: middle;" src="../../assets/souye/wangan.png" alt="">
            <span style="display: inline-block;vertical-align: middle;">京公网安备 11010502038814号 | 未经许可不得转载</span>
        </div>
    </div>
    </div>
    <div :class="fzcgs">复制成功</div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            xiehuan:0,
            xzlx:0,
            ewxq:false,
            fzcgs:'fzcg',
            smxq:[],//二维码详情
            leibiaoList:[
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安团体意外险方案(JS)(互联网)",//名称
                    imag:require("@/assets/souye/list1.jpeg"),//imag
                    tuiguang: 28,//推广费
                    tuiimg:0,//推广费后面是否显示图标
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1月",
                        '保障期限：1个月、3个月、6个月、一年',
                        '起投人数：',
                        "1-4类3人起保，含5类5人起保；"
                    ],//保险分类
                    baozhang:[
                        {
                            name:"意外身故伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"住院津贴",
                            jinge:'：最高150/天'
                        },
                        {
                            name:"伤残标准",
                            jinge:''
                        },
                        {
                            name:"行标10级10%",
                            jinge:''
                        },
                        {
                            name:"工标10级5%",
                            jinge:''
                        },                     
                        {
                            name:"工标10级10%",
                            jinge:''
                        },
                    ],//保障内容
                    cpxxs:0,//是否有点击查看详情小图标
                    tuigxq:[
                        {
                            name:"平安团意险-工标版",
                            age:26,
                        },
                        {
                            name:"平安团意险-工标版（互联网）（GD）B",
                            age:30,
                        }
                    ],
                    icon:require("@/assets/souye/paic.jpg"),//公司图标
                    jingge:'79',//保险价格
                    xq:0,//提示是否显示
                    zuyi:'投保过程中 ---必须上传（真实盖章件照片）避免影响结算',//底部注意事项
                    ksbj:1,//是否显示快速报价
                    erweima:require('../../assets/souye/erweima.png'),//二维码
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安团意险-工标版（互联网）（GD）AB自选套餐",
                    imag:require('../../assets/souye/list2.png'),
                    tuiguang: 30,
                    tuiimg:1,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '行标投保：3人起，含5类5人起',
                        '工标投保：5人起，含工标且涉高最低10人起'
                    ],
                    baozhang:[
                        {
                            name:"意外身故残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                        {
                            name:"扩展猝死保障",
                            jinge:'：最高30万'
                        },
                        {
                            name:"扩展公共交通意外",
                            jinge:'：最高50万'
                        },
                    ], 
                    tuigxq:[
                        {
                            name:"平安团意险-工标版（互联网）（GD）A",
                            age:"26%",
                        },
                        {
                            name:"平安团意险-工标版（互联网）（GD）B",
                            age:"30%",
                        }
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'106',
                    xq:1,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:6,//保险公司
                    xzzlTab:2,//保险类型
                    name:"三星-雇主险1-5类ABC自选套餐",
                    imag:require('../../assets/souye/list3.png'),
                    tuiguang: 35,
                    tuiimg:1,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '1-5类',
                        '3人起保'
                    ],
                    baozhang:[
                        {
                            name:"意外身故残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医药补偿",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外每日住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    tuigxq:[
                        {
                            name:"三星-雇主险1-5类A",
                            age:"20%",
                        },
                        {
                            name:"三星-雇主险1-5类B",
                            age:"28%",
                        },
                        {
                            name:"三星-雇主险1-5类C",
                            age:"35%",
                        }
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/samsunganycar.jpg'),
                    jingge:'99.27',
                    xq:0,
                    zuyi:'投保过程中 ---必须上传（真实盖章件照片）避免影响结算 人资拒保关键字：人力、人资、人咨、人士、人仕、劳动、劳务、劳派、派遣、工派、用工、企管、人管、管协、协管、分包、外包、人力资源、企业管理、企业咨询、劳联，产联',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:6,//保险公司
                    xzzlTab:1,//保险类型
                    name:"三星-团体意外险1-6类ABC自选套餐",
                    imag:require('../../assets/souye/sanx.png'),
                    tuiguang: 35,
                    tuiimg:1,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '1-6类',
                        '3人起保',
                        '支持月单',
                    ],
                    baozhang:[
                        {
                            name:"意外身故残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医药补偿",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外每日住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    tuigxq:[
                        {
                            name:"三星-团体意外险1-6类A",
                            age:"20%",
                        },
                        {
                            name:"三星-团体意外险1-6类B",
                            age:"28%",
                        },
                        {
                            name:"三星-团体意外险1-6类C",
                            age:"35%",
                        }
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/samsunganycar.jpg'),
                    jingge:'70',
                    xq:0,
                    zuyi:'投保过程中 ---必须上传（真实盖章件照片）避免影响结算 人资拒保关键字：人力、人资、人咨、人士、人仕、劳动、劳务、劳派、派遣、工派、用工、企管、人管、管协、协管、分包、外包、人力资源、企业管理、企业咨询、劳联，产联',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:2,//保险类型
                    name:"平安雇主责任险（广东企业版)",
                    imag:require('../../assets/souye/list4.jpeg'),
                    tuiguang: 24,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '起保人数：5人（5类职业最低10人）',
                        '职业类别：1-5类'
                    ],
                    baozhang:[
                        {
                            name:"身故、伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"误工费",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:0,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'208',
                    xq:1,
                    zuyi:'仅广东地区企业承保',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安团体意外险（在线）(广东)",
                    imag:require('../../assets/souye/list4.jpeg'),
                    tuiguang: 28,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '起保人数：5人',
                        '职业类别：1-5类'
                    ],
                    baozhang:[
                        {
                            name:"身故、伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:0,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'98',
                    xq:1,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:4,//保险公司
                    xzzlTab:1,//保险类型
                    name:"安盛天平团体意外险优选版（互联网）",
                    imag:require('../../assets/souye/list4.png'),
                    tuiguang: 28,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                    ],
                    baozhang:[
                        {
                            name:"意外身故、伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医药补偿",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/list4h.jpg'),
                    jingge:'26',
                    xq:0,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安团意险--特惠版（GD）",
                    imag:require('../../assets/souye/list4.jpeg'),
                    tuiguang: 18,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '起保人数：5人',
                        '职业类别：1-5类'
                    ],
                    baozhang:[
                        {
                            name:"身故、伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:0,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'85',
                    xq:1,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:5,//保险公司
                    xzzlTab:3,//保险类型
                    name:"人保1-6类守护神个人意外险（互联网专属）",
                    tis:"本产品每人限购一份，多投无效。",
                    imag:require('../../assets/souye/list5.png'),
                    tuiguang: 22,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：18-60周岁',
                        "保障期限：1年",
                        '默认T+3生效',
                        '承保1-6类职业',
                        '可保高空',
                    ],
                    baozhang:[
                        {
                            name:"意外身故、残疾",
                            jinge:'：最高50万'
                        },
                        {
                            name:"急性病身故(含猝死)",
                            jinge:' ：最高5万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                        {
                            name:"航空意外",
                            jinge:'：最高200万'
                        },
                        {
                            name:"火车意外",
                            jinge:'：最高50万'
                        },
                        {
                            name:"轮船意外",
                            jinge:'：最高50万'
                        },
                        {
                            name:"公共汽车意外",
                            jinge:'：最高20万'
                        },
                        {
                            name:"自驾车意外",
                            jinge:'：最高20万'
                        },
                        {
                            name:"扩展节假日保额加倍",
                            jinge:''
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/list5h.jpg'),
                    jingge:'100',
                    xq:3,
                    zuyi:'',
                    ksbj:0,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:8,//保险公司
                    xzzlTab:1,//保险类型
                    name:"国富无忧团体意外险1-6类自选套餐",
                    tis:"",
                    imag:require('../../assets/souye/list6.png'),
                    tuiguang: 28,
                    tuiimg:1,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '承保1-6类',
                        '支持月单',
                    ],
                    baozhang:[
                        {
                            name:"意外身故残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    tuigxq:[
                        {
                            name:"国富无忧团体意外险1-6类普通版",
                            age:"28%",
                        },
                        {
                            name:"国富无忧团体意外险1-6类优选版",
                            age:"28%",
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/list6h.jpg'),
                    jingge:'52.5',
                    xq:0,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:7,//保险公司
                    xzzlTab:1,//保险类型
                    name:"太保-团体意外险1-5类",
                    tis:"",
                    imag:require('../../assets/souye/list7.png'),
                    tuiguang: 22,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                    ],
                    baozhang:[
                        {
                            name:"意外身故、残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"住院津贴",
                            jinge:'：100元/天'
                        },
                        {
                            name:"伤残比例",
                            jinge:'：行标10级10%起'
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/list7h.jpg'),
                    jingge:'91',
                    xq:1,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:7,//保险公司
                    xzzlTab:2,//保险类型
                    name:"太保雇主责任险方案（在线）(SZ)",
                    tis:"",
                    imag:require('../../assets/souye/list8.png'),
                    tuiguang: 28,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        "职业类别:1-4类 5人起保",
                    ],
                    baozhang:[
                        {
                            name:"意外身故、残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"误工费",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/list7h.jpg'),
                    jingge:'73.7',
                    xq:1,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安团体意外险方案(JS)(互联网)工标10",
                    tis:"",
                    imag:require('../../assets/souye/list9.png'),
                    tuiguang: 28,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        "1-4类3人起保，含5类5人起保",
                    ],
                    baozhang:[
                        {
                            name:"意外身故残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：150元/天'
                        },
                        {
                            name:"扩展民航客机意外保障",
                            jinge:''
                        },
                        {
                            name:"扩展轨道交通意外保障",
                            jinge:''
                        },
                        {
                            name:"扩展客运轮船意外保障",
                            jinge:''
                        },
                        {
                            name:"扩展客运机动车意外保障",
                            jinge:''
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'122',
                    xq:0,
                    zuyi:'拒保地区：天津、北京密云区、平谷区、河北廊坊市、河北秦皇岛市青龙族自治县、山东德州市、临沂市、河南信阳市、辽宁铁岭市、湖北黄冈市。',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:3,//保险公司
                    xzzlTab:2,//保险类型
                    name:"众安雇主险自选ABCD套餐",
                    tis:"",
                    imag:require('../../assets/souye/zhangan.png'),
                    tuiguang: 32,
                    tuiimg:1,
                    leixin:[
                        '投保年龄：18-60周岁',
                        "保障期限：1年",
                    ],
                    baozhang:[
                        {
                            name:"意外身故及残疾",
                            jinge:'：最高60万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高6万'
                        },
                        {
                            name:"误工费",
                            jinge:'：150元/天'
                        },
                        {
                            name:"住院津贴",
                            jinge:'：150元/天'
                        },
                    ],
                    tuigxq:[
                        {
                            name:"众安雇主险A款",
                            age:"20%",
                        },
                        {
                            name:"众安雇主险B款",
                            age:"24%",
                        },
                        {
                            name:"众安雇主险C款",
                            age:"28%",
                        },
                        {
                            name:"众安雇主险D款",
                            age:"32%",
                        }
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/zhongan.jpg'),
                    jingge:'48',
                    xq:0,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安（境外务工）团意",
                    tis:"",
                    imag:require('../../assets/souye/jinwai.png'),
                    tuiguang: 20,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                    ],
                    baozhang:[
                        {
                            name:"境外意外伤害（身故/残疾)",
                            jinge:'：最高150万'
                        },
                        {
                            name:"境外意外医疗",
                            jinge:' ：最高50万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：350元/天'
                        },
                        {
                            name:"突发疾病身故",
                            jinge:'：最高50万'
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'333',
                    xq:0,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:3,//保险公司
                    xzzlTab:1,//保险类型
                    name:"众安团体意外险（互联网）",
                    tis:"",
                    imag:require('../../assets/souye/list10.png'),
                    tuiguang: 26,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                    ],
                    baozhang:[
                        {
                            name:"意外身故、伤残",
                            jinge:'：最高80万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高8万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                        {
                            name:"民航客机意外",
                            jinge:'：最高500万'
                        },
                        {
                            name:"客运轨道交通意外",
                            jinge:'：最高100万'
                        },
                        {
                            name:"客运轮船意外",
                            jinge:'：最高100万'
                        },
                        {
                            name:"客运机动车意外",
                            jinge:'：最高50万'
                        },
                    ],
                    cpxxs:0,
                    icon:require('../../assets/souye/zhongan.jpg'),
                    jingge:'40',
                    xq:0,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:2,//保险公司
                    xzzlTab:1,//保险类型
                    name:"华泰团体意外险123",
                    tis:"",
                    imag:require('../../assets/souye/list9.png'),
                    tuiguang: 28,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        "6人起投",
                        "职业类别1-6类",
                    ],
                    baozhang:[
                        {
                            name:"身故、伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:0,
                    icon:require('../../assets/souye/htbx.jpg'),
                    jingge:'150',
                    xq:4,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
            ],
            leibiaoLists:[
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安团体意外险方案(JS)(互联网)",//名称
                    imag:require("@/assets/souye/list1.jpeg"),//imag
                    tuiguang: 28,//推广费
                    tuiimg:0,//推广费后面是否显示图标
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1月",
                        '保障期限：1个月、3个月、6个月、一年',
                        '起投人数：',
                        "1-4类3人起保，含5类5人起保；"
                    ],//保险分类
                    baozhang:[
                        {
                            name:"意外身故伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"住院津贴",
                            jinge:'：最高150/天'
                        },
                        {
                            name:"伤残标准",
                            jinge:''
                        },
                        {
                            name:"行标10级10%",
                            jinge:''
                        },
                        {
                            name:"工标10级5%",
                            jinge:''
                        },                     
                        {
                            name:"工标10级10%",
                            jinge:''
                        },
                    ],//保障内容
                    cpxxs:0,//是否有点击查看详情小图标
                    icon:require("@/assets/souye/paic.jpg"),//公司图标
                    jingge:'79',//保险价格
                    xq:0,//提示是否显示
                    zuyi:'投保过程中 ---必须上传（真实盖章件照片）避免影响结算',//底部注意事项
                    ksbj:1,//是否显示快速报价
                    erweima:require('../../assets/souye/erweima.png'),//二维码
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安团意险-工标版（互联网）（GD）AB自选套餐",
                    imag:require('../../assets/souye/list2.png'),
                    tuiguang: 30,
                    tuiimg:1,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '行标投保：3人起，含5类5人起',
                        '工标投保：5人起，含工标且涉高最低10人起'
                    ],
                    baozhang:[
                        {
                            name:"意外身故残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                        {
                            name:"扩展猝死保障",
                            jinge:'：最高30万'
                        },
                        {
                            name:"扩展公共交通意外",
                            jinge:'：最高50万'
                        },
                    ], 
                    tuigxq:[
                        {
                            name:"平安团意险-工标版（互联网）（GD）A",
                            age:"26%",
                        },
                        {
                            name:"平安团意险-工标版（互联网）（GD）B",
                            age:"30%",
                        }
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'106',
                    xq:1,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:6,//保险公司
                    xzzlTab:2,//保险类型
                    name:"三星-雇主险1-5类ABC自选套餐",
                    imag:require('../../assets/souye/list3.png'),
                    tuiguang: 35,
                    tuiimg:1,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '1-5类',
                        '3人起保'
                    ],
                    baozhang:[
                        {
                            name:"意外身故残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医药补偿",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外每日住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    tuigxq:[
                        {
                            name:"三星-雇主险1-5类A",
                            age:"20%",
                        },
                        {
                            name:"三星-雇主险1-5类B",
                            age:"28%",
                        },
                        {
                            name:"三星-雇主险1-5类C",
                            age:"35%",
                        }
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/samsunganycar.jpg'),
                    jingge:'99.27',
                    xq:0,
                    zuyi:'投保过程中 ---必须上传（真实盖章件照片）避免影响结算 人资拒保关键字：人力、人资、人咨、人士、人仕、劳动、劳务、劳派、派遣、工派、用工、企管、人管、管协、协管、分包、外包、人力资源、企业管理、企业咨询、劳联，产联',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:6,//保险公司
                    xzzlTab:1,//保险类型
                    name:"三星-团体意外险1-6类ABC自选套餐",
                    imag:require('../../assets/souye/sanx.png'),
                    tuiguang: 35,
                    tuiimg:1,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '1-6类',
                        '3人起保',
                        '支持月单',
                    ],
                    baozhang:[
                        {
                            name:"意外身故残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医药补偿",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外每日住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    tuigxq:[
                        {
                            name:"三星-团体意外险1-6类A",
                            age:"20%",
                        },
                        {
                            name:"三星-团体意外险1-6类B",
                            age:"28%",
                        },
                        {
                            name:"三星-团体意外险1-6类C",
                            age:"35%",
                        }
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/samsunganycar.jpg'),
                    jingge:'70',
                    xq:0,
                    zuyi:'投保过程中 ---必须上传（真实盖章件照片）避免影响结算 人资拒保关键字：人力、人资、人咨、人士、人仕、劳动、劳务、劳派、派遣、工派、用工、企管、人管、管协、协管、分包、外包、人力资源、企业管理、企业咨询、劳联，产联',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:2,//保险类型
                    name:"平安雇主责任险（广东企业版)",
                    imag:require('../../assets/souye/list4.jpeg'),
                    tuiguang: 24,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '起保人数：5人（5类职业最低10人）',
                        '职业类别：1-5类'
                    ],
                    baozhang:[
                        {
                            name:"身故、伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"误工费",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:0,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'208',
                    xq:1,
                    zuyi:'仅广东地区企业承保',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安团体意外险（在线）(广东)",
                    imag:require('../../assets/souye/list4.jpeg'),
                    tuiguang: 28,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '起保人数：5人',
                        '职业类别：1-5类'
                    ],
                    baozhang:[
                        {
                            name:"身故、伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:0,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'98',
                    xq:1,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:4,//保险公司
                    xzzlTab:1,//保险类型
                    name:"安盛天平团体意外险优选版（互联网）",
                    imag:require('../../assets/souye/list4.png'),
                    tuiguang: 28,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                    ],
                    baozhang:[
                        {
                            name:"意外身故、伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医药补偿",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/list4h.jpg'),
                    jingge:'26',
                    xq:0,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安团意险--特惠版（GD）",
                    imag:require('../../assets/souye/list4.jpeg'),
                    tuiguang: 18,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '起保人数：5人',
                        '职业类别：1-5类'
                    ],
                    baozhang:[
                        {
                            name:"身故、伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:0,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'85',
                    xq:1,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:5,//保险公司
                    xzzlTab:3,//保险类型
                    name:"人保1-6类守护神个人意外险（互联网专属）",
                    tis:"本产品每人限购一份，多投无效。",
                    imag:require('../../assets/souye/list5.png'),
                    tuiguang: 22,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：18-60周岁',
                        "保障期限：1年",
                        '默认T+3生效',
                        '承保1-6类职业',
                        '可保高空',
                    ],
                    baozhang:[
                        {
                            name:"意外身故、残疾",
                            jinge:'：最高50万'
                        },
                        {
                            name:"急性病身故(含猝死)",
                            jinge:' ：最高5万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                        {
                            name:"航空意外",
                            jinge:'：最高200万'
                        },
                        {
                            name:"火车意外",
                            jinge:'：最高50万'
                        },
                        {
                            name:"轮船意外",
                            jinge:'：最高50万'
                        },
                        {
                            name:"公共汽车意外",
                            jinge:'：最高20万'
                        },
                        {
                            name:"自驾车意外",
                            jinge:'：最高20万'
                        },
                        {
                            name:"扩展节假日保额加倍",
                            jinge:''
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/list5h.jpg'),
                    jingge:'100',
                    xq:3,
                    zuyi:'',
                    ksbj:0,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:8,//保险公司
                    xzzlTab:1,//保险类型
                    name:"国富无忧团体意外险1-6类自选套餐",
                    tis:"",
                    imag:require('../../assets/souye/list6.png'),
                    tuiguang: 28,
                    tuiimg:1,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        '承保1-6类',
                        '支持月单',
                    ],
                    baozhang:[
                        {
                            name:"意外身故残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    tuigxq:[
                        {
                            name:"国富无忧团体意外险1-6类普通版",
                            age:"28%",
                        },
                        {
                            name:"国富无忧团体意外险1-6类优选版",
                            age:"28%",
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/list6h.jpg'),
                    jingge:'52.5',
                    xq:0,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:7,//保险公司
                    xzzlTab:1,//保险类型
                    name:"太保-团体意外险1-5类",
                    tis:"",
                    imag:require('../../assets/souye/list7.png'),
                    tuiguang: 22,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                    ],
                    baozhang:[
                        {
                            name:"意外身故、残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"住院津贴",
                            jinge:'：100元/天'
                        },
                        {
                            name:"伤残比例",
                            jinge:'：行标10级10%起'
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/list7h.jpg'),
                    jingge:'91',
                    xq:1,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:7,//保险公司
                    xzzlTab:2,//保险类型
                    name:"太保雇主责任险方案（在线）(SZ)",
                    tis:"",
                    imag:require('../../assets/souye/list8.png'),
                    tuiguang: 28,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        "职业类别:1-4类 5人起保",
                    ],
                    baozhang:[
                        {
                            name:"意外身故、残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"误工费",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/list7h.jpg'),
                    jingge:'73.7',
                    xq:1,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安团体意外险方案(JS)(互联网)工标10",
                    tis:"",
                    imag:require('../../assets/souye/list9.png'),
                    tuiguang: 28,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        "1-4类3人起保，含5类5人起保",
                    ],
                    baozhang:[
                        {
                            name:"意外身故残疾",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：150元/天'
                        },
                        {
                            name:"扩展民航客机意外保障",
                            jinge:''
                        },
                        {
                            name:"扩展轨道交通意外保障",
                            jinge:''
                        },
                        {
                            name:"扩展客运轮船意外保障",
                            jinge:''
                        },
                        {
                            name:"扩展客运机动车意外保障",
                            jinge:''
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'122',
                    xq:0,
                    zuyi:'拒保地区：天津、北京密云区、平谷区、河北廊坊市、河北秦皇岛市青龙族自治县、山东德州市、临沂市、河南信阳市、辽宁铁岭市、湖北黄冈市。',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:3,//保险公司
                    xzzlTab:2,//保险类型
                    name:"众安雇主险自选ABCD套餐",
                    tis:"",
                    imag:require('../../assets/souye/zhangan.png'),
                    tuiguang: 32,
                    tuiimg:1,
                    leixin:[
                        '投保年龄：18-60周岁',
                        "保障期限：1年",
                    ],
                    baozhang:[
                        {
                            name:"意外身故及残疾",
                            jinge:'：最高60万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高6万'
                        },
                        {
                            name:"误工费",
                            jinge:'：150元/天'
                        },
                        {
                            name:"住院津贴",
                            jinge:'：150元/天'
                        },
                    ],
                    tuigxq:[
                        {
                            name:"众安雇主险A款",
                            age:"20%",
                        },
                        {
                            name:"众安雇主险B款",
                            age:"24%",
                        },
                        {
                            name:"众安雇主险C款",
                            age:"28%",
                        },
                        {
                            name:"众安雇主险D款",
                            age:"32%",
                        }
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/zhongan.jpg'),
                    jingge:'48',
                    xq:0,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:1,//保险公司
                    xzzlTab:1,//保险类型
                    name:"平安（境外务工）团意",
                    tis:"",
                    imag:require('../../assets/souye/jinwai.png'),
                    tuiguang: 20,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                    ],
                    baozhang:[
                        {
                            name:"境外意外伤害（身故/残疾)",
                            jinge:'：最高150万'
                        },
                        {
                            name:"境外意外医疗",
                            jinge:' ：最高50万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：350元/天'
                        },
                        {
                            name:"突发疾病身故",
                            jinge:'：最高50万'
                        },
                    ],
                    cpxxs:1,
                    icon:require('../../assets/souye/paic.jpg'),
                    jingge:'333',
                    xq:0,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:3,//保险公司
                    xzzlTab:1,//保险类型
                    name:"众安团体意外险（互联网）",
                    tis:"",
                    imag:require('../../assets/souye/list10.png'),
                    tuiguang: 26,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                    ],
                    baozhang:[
                        {
                            name:"意外身故、伤残",
                            jinge:'：最高80万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高8万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                        {
                            name:"民航客机意外",
                            jinge:'：最高500万'
                        },
                        {
                            name:"客运轨道交通意外",
                            jinge:'：最高100万'
                        },
                        {
                            name:"客运轮船意外",
                            jinge:'：最高100万'
                        },
                        {
                            name:"客运机动车意外",
                            jinge:'：最高50万'
                        },
                    ],
                    cpxxs:0,
                    icon:require('../../assets/souye/zhongan.jpg'),
                    jingge:'40',
                    xq:0,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
                {
                    bxgsTab:2,//保险公司
                    xzzlTab:1,//保险类型
                    name:"华泰团体意外险123",
                    tis:"",
                    imag:require('../../assets/souye/list9.png'),
                    tuiguang: 28,
                    tuiimg:0,
                    leixin:[
                        '投保年龄：16-65周岁',
                        "保障期限：1年",
                        "6人起投",
                        "职业类别1-6类",
                    ],
                    baozhang:[
                        {
                            name:"身故、伤残",
                            jinge:'：最高100万'
                        },
                        {
                            name:"意外医疗",
                            jinge:' ：最高10万'
                        },
                        {
                            name:"意外住院津贴",
                            jinge:'：100元/天'
                        },
                    ],
                    cpxxs:0,
                    icon:require('../../assets/souye/htbx.jpg'),
                    jingge:'150',
                    xq:4,
                    zuyi:'',
                    ksbj:1,
                    erweima:require('../../assets/souye/erweima.png'),
                    ewlj:'https://groupinsure.zhongbaounion.com/Webapp/Groupinsure/productInfo?product=PAIC-LIMA2-JSTUANTIYIWAI2AUTO&channel_id=2756d0b2000357a0&user_channel=ff6335bd55068630'
                },
            ],
            filteredArray:[]
        }
    },
    methods:{
        listTab(i){
            this.xiehuan=i
            this.lbsxTab()
        },
        xianzong(i){
            this.xzlx=i
            this.lbsxTab()
        },
        // 列表筛选
        lbsxTab(){
            if(this.xiehuan===0&&this.xzlx===0){
                this.leibiaoList=this.leibiaoLists
            }else if(this.xiehuan!=0&&this.xzlx===0){
                this.filteredArray = this.leibiaoLists.filter(item => {  
                    return item.bxgsTab === this.xiehuan;  
                });  
                this.leibiaoList=this.filteredArray
            }else if(this.xiehuan===0&&this.xzlx!=0){
                this.filteredArray = this.leibiaoLists.filter(item => {  
                    return item.xzzlTab === this.xzlx;  
                });  
                this.leibiaoList=this.filteredArray
            }else if(this.xiehuan!=0&&this.xzlx!=0){
                this.filteredArray = this.leibiaoLists.filter(item => {  
                    return item.xzzlTab === this.xzlx&&item.bxgsTab === this.xiehuan;  
                });  
                this.leibiaoList=this.filteredArray
            }

        },
        //点击复制
        fuzhi(){
            this.fzcgs='fzcgss'
            setTimeout(()=>{
                this.fzcgs='fzcg'
            },1000)
        },
        // 点击扫码显示信息
        shaomaxq(name){
            console.log(name);
            this.ewxq=true
            this.smxq=name

        },
        shaomaxqs(name){
            this.$router.push({name: 'lijitoubao', params: { param: name } });  
           
        },
        litb(neme){
            this.$router.push({name: 'lijitoubao', params: { param: neme } })
        },
        ksbj(neme){
            this.$router.push({name: 'kuaishubj', params: { param: neme } })
        }

    }
}
</script>

<style scoped lang="scss">
.shouye{
    margin-top: 60px;
    background: rgb(242, 242, 242);
}
.imag{
    width: 100%;
    height: 400px;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
   
    background-image: linear-gradient(0deg, rgba(98, 201, 255, 0.00) 0%, #3399FF 100%);
    img{
        z-index: 999;
        position: absolute;
        width: 100%;
        height: 400px;
        left: 0;
        top: 0;
        // margin-left: -1300px;
    }
}
.fl{
    float: left;
}
.fr{
    float: right;
}
// 主体
.common-width{
    width: 100%;
    margin: 0 auto;
    min-height: calc(100% - 300px);
    text-align: left;
    margin-bottom: 20px;
    .right-block{
        width: 100%;
        .search{
            background-color: #fff;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
            border-radius: 10px;
            overflow: hidden;
            text-align: left;
            .clearfix{
                overflow: hidden;
                font-size: 14px;
                color: #666666;
                line-height: 30px;
                padding-top: 12px;
                display: flex;
                flex-direction: row;
                white-space: nowrap;
                align-content: flex-start;
                flex-wrap: nowrap;
                align-items: flex-start;
                p{
                    font-size: 14px;
                    color: #333333;
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    margin-left: 24px;
                    margin-right: 24px;
                }
                .leibiao{
                    height: 42px;
                    overflow: hidden;
                    span{
                        background: #ffffff;
                        border: 1px solid #dcdcdc;
                        border-radius: 4px;
                        // float: left;
                        font-size: 12px;
                        width: 100px;
                        height: 30px;
                        text-align: center;
                        line-height: 30px;
                        margin-right: 12px;
                        cursor: pointer;
                        display: inline-block;
                        margin-bottom: 10px;
                    }
                    span:hover{
                        background: rgb(252, 103, 3);
                        border-radius: 4px;
                        color: #fff;
                        border: 1px solid #dfefff;
                    }
                    .active{
                        background: rgb(252, 103, 3);
                        border-radius: 4px;
                        color: #fff;
                        border: 1px solid #dfefff;
                    }
                }
   
            }
            .xian{
                width: 100%;
                height: 1px;
                border-bottom: 1px solid #F0EFF5;
            }
            .total{
                font-size: 14px;
                color: #666666;
                letter-spacing: -0.58px;
                height: 50px;
                line-height: 50px;
                padding-left: 24px;
                span{
                    color: rgb(252, 103, 3);
                }
            }
        }
        .list{
            width: 100%;
            min-height: 280px;
            .row{
                width: 100%;
                position: relative;
                margin-top: 14px;
                border-bottom: 1px solid #f0eff5;
                background: #ffffff;
                box-shadow: 0 0 16px 0 rgba(121, 121, 121, 0.06);
                border-radius: 10px;
                margin-right: 24px;
                overflow: hidden;
                .product-title{
                    font-size: 18px;
                    color: #333;
                    border-bottom: 1px solid #f0eff5;
                    padding: 8px 24px;
                    clear:both;
                    padding-right: 200px;
                    position: relative;
                    .fl{
                        float: left;
                    }
                    .product-oper-btn-block{
                        right: 0;
                        top: 50%;
                        height: 20px;
                        position: absolute;
                        transform: translateY(-50%);
                        .qrcode-btn{
                            width: 20px;
                            height: 20px;
                            position: relative;
                            float: left;
                            margin-right: 32px;
                            cursor: pointer;
                            .botimg{
                                width: 100%;
                                height: 100%;
                            }

                            .shaoma{
                                background: #ffffff;
                                box-shadow: 0 2px 8px -2px rgba(0,0,0,0.20);
                                border-radius: 6px;
                                position: absolute;
                                font-size: 12px;
                                color: #333333;
                                top: 25px;
                                left: -30px;
                                width: 80px;
                                height: 30px;
                                line-height: 30px;
                                text-align: center;
                                display: none;
                                z-index: 23;
                            }
                            .botimg:hover + .shaoma{
                                display: block;
                            }
                        }

                    }
                }
                .detail-wrap{
                    min-height: 235px;
                    overflow: hidden;
                    position: relative;
                    .product-banner{
                        position: relative;
                        float: left;
                        margin: 0 24px;
                        padding-top: 16px;
                        .product-img{
                            width: 150px;
                            height: 150px;
                            display: block;
                            border-radius: 6px;
                        }
                        .product-commission {
                            height: 36px;
                            background: rgba(255, 255, 255, 0.9);
                            border-radius: 4px;
                            font-size: 12px;
                            color: #333;
                            width: 100%;
                            background: #e4f1ff;
                            border-radius: 6px;
                            height: 40px;
                            margin-top: 5px;
                            cursor: default;
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .world{
                                width: 36px;
                                font-weight: bold;
                            }
                            .proportion {
                                font-size: 16px;
                                color: rgb(252, 103, 3);
                                margin: 0 5px;
                            }
                            .commission-detail-icon{
                                width: 18px;
                                height: 18px;
                            }
                            .commission-detail-wrap{
                                position: absolute;
                                bottom: 55px;
                                right: 0;
                                transform: translateX(50%);
                                width: 150%;
                                height: auto;
                                display: none;
                                background: white;
                                z-index: 30;
                                border-radius: 8px;
                                padding: 11px;
                                box-shadow: 0 4px 12px 0 rgba(0,0,0,0.40);
                            }
                            .commission-detail-icon:hover + .commission-detail-wrap{
                                display: block;
                            }
                        }
                    }
                }
                // 产品情况
                .detail{
                    width: 180px;
                    float: left;
                    padding: 16px 0px 20px;
                    .guarantee{
                        width: 100%;
                        font-size: 12px;
                        color: #333;
                        line-height: 25px;
                        overflow: hidden;
                        p{
                            background: url(../../assets/souye/select-1.png) no-repeat;
                            background-size: 19px 16px;
                            background-position: 0 4px;
                            padding-left: 30px;
                            overflow-wrap: break-word;
                        }
                    }
                }
                .safeguard-content-container{
                    font-size: 12px;
                    color: #666666;
                    float: left;
                    padding-top: 16px;
                    padding-bottom: 16px;
                    width: 22%;
                    margin-left: 15px;
                    .title{
                        font-size: 14px;
                        color: #333;
                        font-weight: bold;
                    }
                    .item{
                        position: relative;
                        padding-left: 10px;
                        line-height: 30px;
                        display: flex;
                        width: 100%;
                        .type{
                            margin-left: 5px;
                            color: #333;
                            margin-right: 10px;
                            max-width: 49%;
                        }
                            .fc-666{
                                color: #666666;
                            }
                        
                    }
                    .item::before{
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 13px;
                        width: 6px;
                        height: 6px;
                        border-radius: 6px;
                        background-color: rgb(252, 103, 3);
                    }
                    .lima-notice{
                        position: absolute;
                        right: 22%;
                        top: 50%;
                        width: 350px;
                        font-size: 14px;
                        transform: translateY(-50%);
                        .fc-blue {
                            color: rgb(252, 103, 3);
                        }
                        .big-font {
                            font-size: 24px;
                            font-weight: 600;
                        }
                        .fc-red {
                            color: red;
                        }
                        .no-break{
                            word-break: keep-all;
                            white-space: nowrap;
                        }
                    }
                }
                .lima-notice{
                    width: 198px;
                    font-size: 14px;
                    color: #666666;
                    box-sizing: border-box;
                    position: relative;
                    .company-logo{
                        width: 70px;
                        height: 70px;
                        display: block;
                        margin: 34px auto 10px;
                        .price {
                            font-size: 16px;
                            letter-spacing: -0.5px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            color: #ff3333;
                            margin-bottom: 14px;
                        }
                    }
                }
                
                .qrcode-notice-text {
                    top: 50%;
                    transform: translateY(-50%);
                    font-weight: 500;
                    p{
                        font-size: 20px;
                        font-weight: bold;
                        span{
                            color: red;
                            font-size: 14px;
                        }
                    }
                }
                // 
                .middle-font{
                    font-size: 14px;
                    color: red;
                }
                .insure{
                    width: 198px;
                    font-size: 14px;
                    color: #666666;
                    box-sizing: border-box;
                    position: relative;
                    .company-logo{
                        width: 70px;
                        height: 70px;
                        display: block;
                        margin: 34px auto 10px;
                    }
                    .price {
                        font-size: 16px;
                        letter-spacing: -0.5px;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        color: #ff3333;
                        margin-bottom: 14px;
                        span{
                            margin-right: 3px;
                            font-size: 24px;
                        }
                    }
                    .btn_insure{
                        color: #ffffff;
                        width: 120px;
                        margin: 0 auto;
                        height: 45px;
                        line-height: 45px;
                        display: block;
                        text-align: center;
                        background: rgb(252, 103, 3);
                        box-shadow: 0 2px 4px 0 rgba(51, 153, 255, 0.3);
                        border-radius: 6px;
                        border: none;
                    }
                    .btn-quickoffer{
                        margin: 10px auto;
                    }
                }
                .insure::before{
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 1000px;
                    width: 1px;
                    background-color: #f0eff5;
                }
                // 点击二维码出现弹窗
                .qrcode-dialog-mask{
                    background: rgba(0, 0, 0, 0.1);
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 10000;
                    
                    .qrcode-dialog{
                        width: 474px;
                        height: auto;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: #FFFFFF;
                        box-shadow: 0 0 16px 0 rgba(0,0,0,0.10);
                        border-radius: 4px;
                        z-index: 10001;
                        .qrcode-dialog-title{
                            padding-top: 14px;
                            padding-bottom: 16px;
                            line-height: 30px;
                            position: relative;
                            text-align: center;
                            font-size: 18px;
                            color: #333333;
                            .close-qrcode-dialog{
                                width: 28px;
                                height: 28px;
                                background-size: 16px 16px;
                                background-image: url(../../assets/souye/icon_close.png);
                                background-repeat: no-repeat;
                                background-position: center;
                                right: 24px;
                                top: 15px;
                                position: absolute;
                            }
                        }
                        .qrcode-dialog-content{
                            text-align: center;
                            .qrcode-img{
                                width: 280px;
                                height: 280px;
                                margin-top: 26px;
                                margin-bottom: 20px;
                                display: inline-block;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .qrcode-img-text{
                                font-size: 14px;
                                color: #333333;
                                margin-bottom: 50px;
                            }
                        }
                        .qrcode-url {
                            padding: 0 15px;
                            word-break: break-all;
                            margin-top: -30px;
                            a{
                                font-size: 12px;
                                color: #666;
                            }
                            button{
                                color: #ffffff;
                                width: 120px;
                                margin: 0 auto;
                                height: 45px;
                                line-height: 45px;
                                display: block;
                                text-align: center;
                                font-size: 16px;
                                margin: 10px auto;
                                background: #rgb(252, 103, 3)99ff;
                                box-shadow: 0 2px 4px 0 rgba(51, 153, 255, 0.3);
                                border-radius: 6px;
                                border: none;
                            }

                        }

                    }
                }
            }
            .row:hover{
                border: 3px solid rgb(252, 103, 3);
            }
        }
    }
    // 左侧小图标right-tips
    .right-tips{
        height: 50px;
        display: inline-block;
        position: fixed;
        text-align: left;
        right: 0;
        top:180px;

        .clearfix{
            cursor: pointer;
            text-align: center;
            width: 50px;
            right: 0;
            position: fixed;
            img{
                width: 40px;
                height: 40px;
            }
        }
        .tip-item{
            width: 230px;
            background-color: #fff;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
            border-radius: 10px;
            padding: 10px 10px;
            font-size: 16px;
            color: #333333;
            font-weight: bold;
            box-sizing: border-box;
            margin-right: 60px;
            transition: 2s;
            display: none;
            .js-toggle-tip{
                 margin-bottom: 10px;
                .question-content{
                    font-size: 14px;
                    color: #333333;
                    text-align: left;
                    font-weight: 400;
                    span{
                        color: #ff4800;
                    }
                }
            }
            .where-tip-container{
                background: #f0eff5;
                border-radius: 6px;
                padding: 16px;
                overflow: hidden;
                img{
                    width: 16px;
                }
                .info{
                    width: 130px;
                    margin-left: 10px;
                    font-weight: 400;
                }
                .first{
                    font-size: 12px;
                    color: #666666;
                    padding-top: 7px;

                }
                .two{
                    font-size: 12px;
                    color: #666666;
                    padding-top: 7px; 
                }
                span{
                    color: #ff4800;
                }
            }
        }
        .clearfix:hover + .tip-item{    
            display: block;  
            
        }
    }
}
// 公共尾部
.foot-container{
    width: 100%;
    text-align: center;
    background-color: #333333;
    font-size: 12px;
    color: #ffffff;
    padding: 18px 0;
    line-height: 22px;
    .link-wrap{
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        .beian{
            display: flex;
            align-items: center;
        }
    }
}

// 复制
.fzcg{
    z-index: 99999;
    position: fixed;
    bottom: 25%;
    width: 85%;
    margin-left: 7.5%;
    font-size: 18px;
    text-align: center;
    background-color: rgba(0, 0, 0,0.9);
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    display: none;
}
.fzcgss{
    z-index: 99999;
    position: fixed;
    bottom: 25%;
    width: 85%;
    margin-left: 7.5%;
    font-size: 18px;
    text-align: center;
    background-color: rgba(0, 0, 0,0.9);
    color: #fff;
    border-radius: 10px;
    padding: 10px;
}
// 修改表格样式
:deep .has-gutter .is-leaf{
  color: #666;
  font-size: 12px;
  background-color: #E5F2FF;
  height: 40px;
}
:deep .el-table .el-table__cell{
  margin: 0;
  padding: 0;
}
:deep .el-table__body-wrapper .el-table__cell{
  font-size: 12px;
  a{
    color: #428bca;
  }
}
:deep .el-table .cell  {
  padding: 0 5px;

}
:deep .el-table__row .cell{
//   height: 50px;
  // 网格布局让元素的上下居中
  display: grid;  
  align-items: center;
  
}
:deep .el-table th.el-table__cell>.cell{
  padding: 0 5px;
}
:deep .el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: rgb(244, 244, 244) !important;
} 
</style>