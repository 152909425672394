import { render, staticRenderFns } from "./kuaishubj.vue?vue&type=template&id=bf1cccba&scoped=true"
import script from "./kuaishubj.vue?vue&type=script&lang=js"
export * from "./kuaishubj.vue?vue&type=script&lang=js"
import style0 from "./kuaishubj.vue?vue&type=style&index=0&id=bf1cccba&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf1cccba",
  null
  
)

export default component.exports