<template>
    <div class="index">
        <!-- 头部 -->
        <div class="salesman-header-container">
            <div class="header">
                <div class="nav-l">
                    <div class="clearfix">
                        <!-- <a href="">
                            <img src="../../assets/souye/iconlog.png" alt="">
                        </a> -->
                    </div>
                    <ul class="nav">
                        <router-link to="/home/index" tag="li"><a href="javascript:;"  @click="tops=1" :class="{'beijing':tops===1}">首页</a></router-link>
                        <router-link to="/home/geren" tag="li" ><a href="javascript:;"  @click="tops=2" :class="{'beijing':tops===2}">个人中心</a></router-link>
                        <router-link to="/home/shouye" tag="li" ><a href="javascript:;"  @click="tops=3" :class="{'beijing':tops===3}">保险商城</a></router-link>
                        <router-link to="/home/geren" tag="li" ><a href="javascript:;"  @click="tops=4" :class="{'beijing':tops===4}">理陪服务</a></router-link>
                        <router-link to="/home/gywm" tag="li" ><a href="javascript:;"  @click="tops=5" :class="{'beijing':tops===5}">关于我们</a></router-link>
                        <router-link to="/home/qywh" tag="li" ><a href="javascript:;"  @click="tops=6" :class="{'beijing':tops===6}">企业文化</a></router-link>
                        <router-link to="/home/hzsy" tag="li" ><a href="javascript:;"  @click="tops=7" :class="{'beijing':tops===7}">合作双赢</a></router-link>
                        <router-link to="/home/jrwm" tag="li" ><a href="javascript:;"  @click="tops=8" :class="{'beijing':tops===8}">招聘</a></router-link>
                        <router-link to="/home/shouye" tag="li" ><a href="javascript:;"  @click="tops=9" :class="{'beijing':tops===9}">智慧保险</a></router-link>
                    </ul>
                </div>
                <div class="nav-r">
                    <router-link to="/home/xiaoxi" tag="div" class="icon"><a href="javascript:;" @click="tops=3"><img src="../../assets/souye/notice.png" alt=""></a></router-link>
                    <!-- <div class="icon"><a href="javascript:;"><img src="../../assets/souye/notice.png" alt=""></a></div> -->
                    <div class="nav-rr">
                        <div class="nav-text">欢迎<span>保镜科技</span>登录投保管理平台!</div>
                        <div class="touxaing">
                            <div>
                                <img src="../../assets/souye/default_avatar.png" alt="" class="imag">
                            </div>
                            <div class="account-detail">
                                <div class="detail">
                                    <div class="detailimg"><img src="../../assets/souye/default_avatar.png" alt=""></div>
                                    <div class="detailtext">
                                        <div>辰星保险科技</div>
                                        <span>渠道</span>
                                    </div>
                                </div>
                                <div class="zhanghao" @click="sezhi">
                                    <img src="../../assets/souye/setting.png" alt="">
                                    <span>账号设置</span>
                                </div>
                                <div class="zhanghao" @click="tuichu">
                                    <img src="../../assets/souye/logout.png" alt="">
                                    <span>退出登录</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <keep-alive>
            <router-view :key="$route.fullPath"></router-view>
        </keep-alive>
    </div>
</template>

<script>
export default {
    data(){
        return{
            tops:1,
        }
    },

    methods:{
        // 退出登录
        tuichu(){
            this.$router.push('/login');  
        } ,
        // 账号设置
        sezhi(){
            this.tops=0
            this.$router.push({path:'/home/geren',query:{a:11}});  
        }
    }
}
</script>

<style scoped lang="scss">
.index{
    width: 100%;
}
// 头部
.salesman-header-container{
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
    .header{
        margin: 0px auto;
        height: 60px;
        padding: 0 2vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nav-l{
            display: flex;
            .clearfix{
                img{
                    height: 50px;
                }
            }
            .nav{
                display: flex; 
                align-items: center;
                li{
                    position: relative;
                    word-break: keep-all;  
                    overflow-wrap: normal;
                    padding: 0 25px;
                    // text-align: center;
                    a{
                        display: block;
                        font-size: 16px;
                        color: #333333;
                        // margin: 0 25px;
                    }
                    a:hover{
                        color: rgb(252, 103, 3);
                    }
                }
                .beijing{
                    // a{
                        color: rgb(252, 103, 3);
                    // }
                }
                .beijing:after{
                    content: "";
                    position: absolute;
                    bottom: -15px;
                    left: 25px;
                    width: calc(100% - 50px);
                    margin: 0 auto;
                    border-bottom: 4px solid rgb(252, 103, 3);
                    border-radius: 2px;
                }
            }
        }
        .nav-r{
            display: flex;
            align-items: center;
            .icon{
                margin: 0 5px;
                img{
                    width: 24px;
                    height: 24px;
                }
            }
            .nav-rr{
                display: flex;
                align-items: center;
                .nav-text{
                    font-size: 12px;
                    color: #999;
                    word-break: keep-all;  
                    overflow-wrap: normal;
                    span{
                        color: #2a2a2a;
                        font-size: 14px;
                    }
                }
                .touxaing{
                    height: 60px;
                    margin-left: 5px;
                    position: relative;
                    cursor: pointer;
                    .imag{
                        height: 30px;
                        width: 30px;
                        margin-top: 15px;
                    }
                    .account-detail{
                        position: absolute;
                        display: none;
                        width: 190px;
                        background: #ffffff;
                        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        z-index: 99999999;
                        right: 0;
                        top: 55px;
                        font-size: 14px;
                        color: #333333;
                        .detail{
                            width: 100%;
                            height: 85px;
                            display: flex;
                            align-items: center;
                            .detailimg{ 
                                padding: 0 10px 0 15px;
                                img{
                                    width: 45px;
                                    height: 45px;
                                }
                            }
                            .detailtext{
                                div{
                                    height: 20px;
                                    line-height: 20px;
                                    overflow: hidden;
                                }
                                span{
                                    font-size: 12px;
                                    color: #666666;
                                    background: #ffffff;
                                    border: 1px solid #dcdcdc;
                                    border-radius: 20px;
                                    height: 20px;
                                    padding: 0 12px;
                                    line-height: 20px;
                                }
                            }
                        }
                        .zhanghao{
                            height: 40px;
                            padding-left: 34px;
                            border-radius: 6px;
                            font-size: 14px;
                            color: #666666;
                            display: flex;
                            align-items: center;

                            img{
                                width: 18px;
                                height: 18px;
                                margin-right: 8px;
                            }
                        }
                    }
                }
                .touxaing:hover{
                    .account-detail {
                        display: block;
                    }
                }
            }

        }
    }
}
</style>