import Vue from 'vue'
import VueRouter from 'vue-router'
import home from "../views/home/index.vue"
import login from "../views/login/index.vue"
import lijitoubao from "../components/xiangqing/lijitoubao.vue"
import toubao from "../components/xiangqing/toubao.vue"
import kuaishubj from "../components/xiangqing/kuaishubj.vue"
import sqpg from "../components/xiangqing/sqpg.vue"



// 引入二级路由
import shouye from "../components/shouGe/shouye.vue"
import geren from "../components/shouGe/gerenZhong.vue"
import xiaoxi from "../components/shouGe/xiaoxi.vue"
import index from "../components/index/index.vue"
import gywm from "../components/guanyvwomen/index.vue"
import qywh from "../components/qywh/index.vue"
import jrwm from "../components/jrwm/index.vue"
import hzsy from "../components/hzsy/index.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: '/login',
    name: 'login',
    component:login,
    meta: { title: '产品业务管理平台-登录' }  
  },
  {
    path: '/home',
    name: 'home',
    component:home,
    children:[
      {
        path:'/home',
        redirect:"index"
      },
      {
        path:'index',
        component:index,
        meta: { title: '产品业务管理平台-首页' }  
      },
      {
        path:'shouye',
        component:shouye,
        meta: { title: '产品业务管理平台-智慧保险' }  
      },      
      {
        path:'jrwm',
        component:jrwm,
        meta: { title: '产品业务管理平台-关于我们' }  
      },
      {
        path:'hzsy',
        component:hzsy,
        meta: { title: '产品业务管理平台-合作双赢' }  
      },
      {
        path:'gywm',
        component:gywm,
        meta: { title: '产品业务管理平台-招聘' }  
      },
      {
        path:'qywh',
        component:qywh,
        meta: { title: '产品业务管理平台-企业文化' }  
      },
      {
        path:'geren',
        component:geren,
        meta: { title: '产品业务管理平台-个人中心' }  
      },
      {
        path:'xiaoxi',
        component:xiaoxi,
        meta: { title: '产品业务管理平台-服务' }  ,
      },

    ]
  },
  {
    path: '/lijitoubao',
    name: 'lijitoubao',
    meta: { title: '平安团体意外险方案(JS)(互联网)' },
    component:lijitoubao,
  },
  {
    path: '/toubao/:param',
    name: 'toubao',
    meta: { title: '平安团体意外险方案(JS)(互联网)' },
    component:toubao,
  },
  {
    path: '/kuaishubj',
    name: 'kuaishubj',
    meta: { title: '平安团体意外险方案(JS)(互联网)' },
    component:kuaishubj,
  },
  {
    path: '/sqpg',
    name: 'sqpg',
    meta: { title: '申请批改' },
    component:sqpg,
  },
  {
    path:'*',
    redirect:"home"
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
