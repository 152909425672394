<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {  
  // 根据路由反馈的标题，让页面显示不同的标题
  watch: {  
    '$route'(to, from) {  
      document.title = to.meta.title;  
    }  
  }  
}  
</script>
<style lang="scss">
body{
  background: rgb(240, 239, 245);
}
</style>
