<template>
  <div class="ksbj">
    <div class="baojia1">
        <div class="wrap">
            <div class="bj1_img">
                <img src="../../assets/souye/paic.jpg" alt="">
            </div>
            <div class="bj1_info">
                <h3 class="product_name">平安团体意外险（互联网）(JS)</h3>
                <div class="desc">产品由中国平安财产保险股份有限公司承保并负责理赔</div>
            </div>
        </div>
    </div>
    <div class="baojia2 ">
        <div class="wrap">
            <div class="bj_type">投保方案</div>
            <div style="background-color: #F0EFF5; height: 1px;"></div>
            <div class="calc-block">
                <div class="plan-item">
                    <div class="item-name">投保方案：</div>
                    <div class="option-container">
                        <div class="option" @click="list.a1=1" :class="{'active':list.a1===1}">行标/工标5</div>
                        <div class="option" @click="list.a1=2" :class="{'active':list.a1===2}">工标10</div>
                        <div style="clear:both;display: flex; align-items: center;" >
                            <div style="margin-right:20px;font-size:12px;display:inline-block;">推广费：
                                <span style="color:#FF3333;font-size:16px;">28%</span>
                            </div>
                            <div style="display: flex;height: 38px;background-color: #FFF7F8;color: #666666;font-size: 14px;padding: 9px;border-radius: 10px; box-sizing: border-box;align-items: center;">
                                <img src="../../assets/souye/icon_remind.png" alt="" style="width:20px;height:20px;vertical-align:middle;">
                                <span style="color:#999999;font-size:12px;margin-left:4px;">特别提示：选择不同方案会影响推广费，请认真核对。</span>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="plan-item">
                    <div class="item-name">伤残比例：</div>
                    <div class="option-container">
                        <div class="option" @click="list.a2=1" :class="{'active':list.a2===1}">行标十级10%起</div>
                        <div class="option" @click="list.a2=2" :class="{'active':list.a2===2}">工标10级5%起</div>
                        <div class="option active" :class="{'disabled':list.a1===1}">工标10级10%起</div>
                    </div>
                </div>
                <div class="plan-item">
                    <div class="item-name">民航客机意外：</div>
                    <div class="option-container">
                        <div class="option" @click="list.a3=1" :class="{'active':list.a3===1}">不购买</div>
                        <div class="option" @click="list.a3=2" :class="{'active':list.a3===2}">20万</div>
                        <div class="option" @click="list.a3=3" :class="{'active':list.a3===3}">50万</div>
                        <div class="option" @click="list.a3=4" :class="{'active':list.a3===4}">100万</div>
                    </div>
                </div>
                <div class="plan-item">
                    <div class="item-name">客运轨道交通意外：</div>
                    <div class="option-container">
                        <div class="option" @click="list.a4=1" :class="{'active':list.a4===1}">不购买</div>
                        <div class="option" @click="list.a4=2" :class="{'active':list.a4===2}">10万</div>
                        <div class="option" @click="list.a4=3" :class="{'active':list.a4===3}">20万</div>
                        <div class="option" @click="list.a4=4" :class="{'active':list.a4===4}">30万</div>
                    </div>
                </div>
                <div class="plan-item">
                    <div class="item-name">客运轮船意外：</div>
                    <div class="option-container">
                        <div class="option" @click="list.a5=1" :class="{'active':list.a5===1}">不购买</div>
                        <div class="option" @click="list.a5=2" :class="{'active':list.a5===2}">10万</div>
                        <div class="option" @click="list.a5=3" :class="{'active':list.a5===3}">20万</div>
                        <div class="option" @click="list.a5=4" :class="{'active':list.a5===4}">30万</div>
                    </div>
                </div>
                <div class="plan-item">
                    <div class="item-name">客运机动车意外：</div>
                    <div class="option-container">
                        <div class="option" @click="list.a6=1" :class="{'active':list.a6===1}">不购买</div>
                        <div class="option" @click="list.a6=2" :class="{'active':list.a6===2}">10万</div>
                        <div class="option" @click="list.a6=3" :class="{'active':list.a6===3}">20万</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="baojia3">
        <div class="wrap">
            <div class="bj_type">投保人信息</div>
        </div>
        <div class="hline"></div>
        <div class="wrap">
            <div class="input-row">
                <div class="input-item">
                    <div class="label">企业名称</div>
                    <div class="info"><input type="text" placeholder="请填写投保人名称"></div>
                </div>
                <div class="input-item">
                    <div class="label">证件类型</div>
                    <div class="info">
                        <select name="" id="" class="noarrow">
                            <option value="0">社会统一信用代码</option>
                            <option value="1">注册号</option>
                        </select>
                    </div>
                </div>
                <div class="input-item">
                    <div class="label">企业名称</div>
                    <div class="info"><input type="text" placeholder="请填写投保人名称"></div>
                </div>

            </div>
        </div>
    </div>
    <div class="baojia4">
        <div class="wrap">
            <div class="bj_type">投保人信息</div>
        </div>
        <div class="hline"></div>
        <div class="list_item">
            <div class="item">
                <div class="position_info" v-for="item in counts" :key="item">
                    <div class="input-row">
                        <div class="input-item">
                            <div class="label">职业1</div>
                            <div class="info"><a href="javascript:;" @click="counts-=1"><img src="../../assets/souye/icon_delete.png" alt=""></a></div>
                        </div>
                    </div>
                    <div class="input-row">
                        <div class="input-item" style="width: 600px;">
                            <div class="label">职业名称</div>
                            <div class="info" @click="zhiyfa=true"><input type="text" name="" id="" class="select_position" placeholder="点击选择"></div>
                        </div>
                        <div class="input-item">
                            <div class="label">投保人数</div>
                            <div class="info"><input type="text" placeholder="请输入人数" class="pr12"></div>
                        </div>
                        <div class="input-item">
                            <div class="label">加费金额</div>
                            <div class="info"><input type="text" placeholder="请输入人数" class="pr12"> </div>
                        </div>
                    </div>
                    <div class="input-row">
                        <div class="input-item">
                            <div class="label">职业名称</div>
                            <div class="info">
                                <span class="per_premium">待完善</span>
                            </div>
                        </div>
                    </div>
                    <div class="calc-block">
                        <div class="plan-item">
                            <div class="item-name">保障内容：</div>
                            <div class="option-container">
                                <option value="0" class="option" @click="list.a7=1" :class="{'active':list.a7===1}">10万+1万</option>
                                <option value="1" class="option" @click="list.a7=2" :class="{'active':list.a7===2}">20万+2万</option>
                                <option value="2" class="option" @click="list.a7=3" :class="{'active':list.a7===3}">30万+3万</option>
                                <option value="3" class="option" @click="list.a7=4" :class="{'active':list.a7===4}">40万+4万</option>
                                <option value="4" class="option" @click="list.a7=5" :class="{'active':list.a7===5}">50万+5万</option>
                                <option value="5" class="option" @click="list.a7=6" :class="{'active':list.a7===6}">60万+6万</option>
                                <option value="6" class="option" @click="list.a7=7" :class="{'active':list.a7===7}">70万+7万</option>
                                <option value="7" class="option" @click="list.a7=8" :class="{'active':list.a7===8}">80万+8万</option>
                                <option value="8" class="option" @click="list.a7=9" :class="{'active':list.a7===9}">90万+9万</option>
                                <option value="9" class="option" @click="list.a7=10" :class="{'active':list.a7===10}">100万+10万</option>
                            </div>
                        </div>
                    </div>
                    <div class="calc-block">
                        <div class="plan-item">
                            <div class="item-name">意外住院津贴：</div>
                            <div class="option-container">
                                <option value="0" class="option" @click="list.a8=1" :class="{'active':list.a8===1}">不购买</option>
                                <option value="0" class="option" @click="list.a8=2" :class="{'active':list.a8===2}">50元/天</option>
                                <option value="1" class="option" @click="list.a8=3" :class="{'active':list.a8===3}">100元/天</option>
                                <option value="2" class="option" @click="list.a8=4" :class="{'active':list.a8===4}">150元/天</option>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add_more">
            <a href="javascript:;" @click="counts+=1">
                <img src="../../assets/souye/icon_add.png" alt="">添加岗位
            </a>
        </div>
        <div style="height: 60px;"></div>
    </div>
    <div class="baofei_heji">
        <div class="premium-block">保费合计：<span class="red">人员信息待完善</span></div>
        <div class="save_offer" @click="qwsxx=true">生成报价单</div>
    </div>
    <!-- 点击人员信息-职业方案1弹窗 -->
    <div class="menban" v-if="zhiyfa===true">
        <div class="position_search_dialog">
            <div class="position_search_dialog_title">选择职业信息
                <i class="close_search_pane" @click="zhiyfa=false"></i>
            </div>
            <div class="position_search_ctrl_wrap">
                <div style="overflow: hidden;">
                    <input type="text" class="position_search_input" placeholder="请输入职业名称">
                    <span class="search_position_btn">搜索</span>
                </div>
                <div class="search_position_select_wrap">
                    <select name="" id="">
                        <option>请选择</option>
                        <option value="1">一般职业</option>
                    </select>
                    <select name="" id="">
                        <option>请选择</option>
                        <option value="1">一般职业</option>
                    </select>
                    <select name="" id="">
                        <option>请选择</option>
                        <option value="1">一般职业</option>
                    </select>
                 </div>
                 <div class="position_edit_wrap">
                    <div class="level_str">所选职业类别：
                        <span style="color: rgb(51, 51, 51);"></span>
                    </div>
                 </div>
                 <div class="search_position_result_text">
                    搜索结果：<span class="search_position_count">0</span>条
                 </div>
            </div>
            <div class="search_position_result_wrap">
                <div v-if="false" class="no_position_result">
                    <img src="../../assets/souye/nodata.png" alt="">
                    <p style="margin-top: 10px;font-size: 16px;color: #333;">抱歉，没有找到职业信息</p>
                </div>
                <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
                    <li v-for="i in count" class="infinite-list-item" :key="i">
                        <div class="icon_position"></div> 
                        <div class="position_content">
                            <div class="position_text">建筑公司负责人（2类）</div>
                            <div class="parent_position_text">所属大分类：建筑行业</div>
                            <div class="parent_position_text">所属中分类：一般外勤人员</div>
                        </div>   
                        <!-- actim 点击后添加 -->
                        <div class="icon_check actim"></div>
                    </li>
                </ul>
            </div>
            <div class="search_position_dialog_foot">
                <div class="ok-btn position-btn">确定</div>
                <div class="cancel-btn position-btn" @click="zhiyfa=false">取消</div>
            </div>
        </div>
    </div>
    <div class="menban" v-if="qwsxx===true">
        <div class="alert-dialog">
            <div class="alert-dialog-title">提示</div>
            <div class="alert-dialog-content">
                <div class="alert-dialog-content-text">请完善信息</div>
            </div>
            <div class="alert-dialog-footer">
                <div class="qunren " @click="qwsxx=false">确定</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            zhiyfa:false,
            qwsxx:false,
            count:2,
            counts:1,
            list:{
                a1:1,
                a2:1,
                a3:1,
                a4:1,
                a5:1,
                a6:1,
                a7:1,
                a8:1,
            }
        }
    },
}
</script>

<style  scoped lang="scss">
.ksbj{
    background: rgb(240, 239, 245);
    .baojia1{
        background: #fff;
        padding: 12px 0;
        min-height: 84px;
        box-sizing: border-box;
        .wrap {
            padding-left: 15px;
            padding-right: 15px;
            .bj1_img{
                width: 60px;
                float: left;
                margin-right: 10px;
                img{
                    border: 0;
                    vertical-align: middle;
                    max-width: 100%;
                }
            }
            .bj1_info{
                h3{
                    margin-bottom: 10px;
                    font-size: 16px;
                    overflow: hidden;
                }
                div{
                    color: #999;
                    line-height: 20px;
                    font-size: 12px;
                }
            }
        }
    }
    .baojia2 {
        margin: 8px 0;
        overflow: hidden;
        background: #fff;
        .wrap{
            padding: 0 15px;
            .bj_type{
                font-size: 16px;
                margin: 10px 0;
                position: relative;
                padding-left: 15px;
            }
            .bj_type::before {
                content: "";
                position: absolute;
                left: 0;
                width: 4px;
                height: 14px;
                background: #3399FF;
                top: 4px;
            }
            .calc-block {
                margin-top: 15px;
                .plan-item{
                    .item-name{
                        font-size: 14px;
                        margin: 4px 0 6px 0;
                        position: relative;
                    }
                    .option-container {
                        clear: both;
                        overflow: hidden;
                        padding-bottom: 9px;
                        .option{
                            min-width: 100px;
                            float: left;
                            text-align: center;
                            line-height: 20px;
                            border: 1px solid #dcdcdc;
                            border-radius: 2px;
                            margin: 4px 1%;
                            position: relative;
                            padding: 4px 15px;
                            cursor: pointer;
                            user-select: none;  
                            box-sizing: border-box;
                        }

                        .active{
                            border-color: #3399FF;
                            color: #3399FF;
                        }
                        .disabled{
                            background: #DCDCDC;
                            border: #DCDCDC solid 1px;
                            color: white;
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
    .baojia3{
        padding: 4px 0;
        overflow: hidden;
        margin: 8px 0;
        background: #fff;
        .wrap{
            padding: 0 15px;
            .bj_type{
                font-size: 16px;
                margin: 10px 0;
                position: relative;
                padding-left: 15px;
            }
            .bj_type::before {
                content: "";
                position: absolute;
                left: 0;
                width: 4px;
                height: 14px;
                background: #3399FF;
                top: 4px;
            }
        }
        .hline{
            height: 1px;
            background: #F0EFF5;
        }
        .wrap{
            padding: 0 15px;
           .input-row{
            overflow: hidden;
            .input-item{
                width: 300px;
                height: 40px;
                overflow: hidden;
                float: left;
                .label {
                    width: 80px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    float: left;
                }
                .info{
                    width: calc(100% - 100px);
                    height: 40px;
                    line-height: 40px;
                    float: left;
                    input{
                        width: 100%;    
                        padding: 6px;
                        min-width: 2px;
                        border: 0;
                        background-size: 12px 6px;
                        outline: none;
                    }
                    .noarrow{
                        width: 100%;
                        font-size: 14px;
                        padding: 6px;
                        min-width: 20px;
                        border: 0;
                        background-size: 12px 6px;
                        appearance: none;//去除select后面的向下指的箭头
                        outline: none;
                    }
                }
            }
           } 
        }
    }
    .baojia4{
        padding: 6px 0;
        margin: 6px 0;
        background: #fff;
        .wrap{
            padding: 0 15px;
            .bj_type{
                font-size: 16px;
                margin: 10px 0;
                position: relative;
                padding-left: 15px;
            }
            .bj_type::before {
                content: "";
                position: absolute;
                left: 0;
                width: 4px;
                height: 14px;
                background: #3399FF;
                top: 4px;
            }
        }
        .hline{
            height: 1px;
            background: #F0EFF5;
        }
        .list_item{
            .item {
                padding: 3px 15px 5px 15px;
                .position_info{
                    border-bottom: 1px solid #F0EFF5;
                    .input-row{
                        overflow: hidden;
                        .input-item{
                            width: 300px;
                            height: 40px;
                            overflow: hidden;
                            float: left;
                            .label {
                                width: 80px;
                                height: 40px;
                                line-height: 40px;
                                font-size: 14px;
                                float: left;
                            }
                            .info{
                                width: calc(100% - 100px);
                                height: 40px;
                                line-height: 40px;
                                float: left;
                                display: flex;
                                align-items: center;
                                img{
                                    width: 17px;
                                }
                                .select_position{
                                    width: 500px;
                                    border: 0;
                                    background: none;
                                    outline: 0;
                                    text-align: left;
                                    height: 40px;
                                    line-height: 40px;
                                }
                                .pr12{
                                    border: 0;
                                    background: none;
                                    outline: 0;
                                    text-align: left;
                                    height: 40px;
                                    line-height: 40px;
                                    width: 100%;
                                    padding-right: 12px;
                                }
                                .per_premium{
                                    color: #FF4700;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                    .calc-block {
                        margin-top: 15px;
                        .plan-item{
                            .item-name{
                                font-size: 14px;
                                margin: 4px 0 6px 0;
                                position: relative;
                            }
                            .option-container {
                                clear: both;
                                overflow: hidden;
                                padding-bottom: 9px;
                                .option{
                                    min-width: 100px;
                                    float: left;
                                    text-align: center;
                                    line-height: 20px;
                                    border: 1px solid #dcdcdc;
                                    border-radius: 2px;
                                    margin: 4px 1%;
                                    position: relative;
                                    padding: 4px 15px;
                                    cursor: pointer;
                                    user-select: none;
                                    box-sizing: border-box;
                                }
                                .active{
                                    border-color: #3399FF;
                                    color: #3399FF;
                                }
                            }
                        }
                    }
                }
            }
        }
        .add_more{
            padding: 11px 12%;
            margin-top: -2px;
            overflow: hidden;
            background: #fff;
            a{
                text-align: center;
                background: #fff;
                border: 1px solid #3399FF;
                border-radius: 4px;
                width: 100%;
                height: 40px;
                line-height: 4px;
                color: #3399FF;
                float: left;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 16px;
                    margin-right: 15px;
                }
            }
        }
    }
    .baofei_heji{
        background: #fff;
        box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.10);
        padding: 10px 0;
        text-align: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 22;
        left: 0;
        font-size: 14px;
        box-sizing: border-box;
        .premium-block{
            float: left;
            margin-top: 10px;
            margin-left: 15px;
            .red {
                color: #FF4700;
            }
        }
        .save_offer{
            float: right;
            line-height:40px;
            height: 40px;
            width: 120px;
            border-radius: 20px;
            overflow: hidden;
            background: #3399FF;
            text-align: center;
            font-size: 16px;
            color: white;
            margin-right: 15px;
            cursor: pointer;
            user-select: none;
        }
    }
    // 弹窗
    .menban{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        z-index: 99;
        // 点击人员信息-职业方案1弹窗
        .position_search_dialog{
            width: 578px;   
            position: fixed;
            top: 50%;
            left: 50%;
            height: 640px;
            transform: translate(-50%, -50%);
            box-shadow: 0 0 16px 0;
            border-radius: 4px;
            background-color: white;
            // display: none;
            z-index: 15;
            .position_search_dialog_title{
                color: #333;
                overflow: hidden;
                padding: 18px 10px 18px 30px;
                line-height: 24px;
                font-size: 16px;
                font-weight: bold;
                border-bottom: 1px solid #DCDCDC;
                position: relative;
                box-sizing: border-box;
                .close_search_pane {
                    position: absolute;
                    background: url(../../assets/souye/close.png) no-repeat;
                    width: 16px;
                    height: 16px;
                    background-position: center center;
                    background-size: 100% 100%;
                    right: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                    cursor: pointer;
                }
            }
            .position_search_ctrl_wrap{
                .position_search_input {
                    width: 390px;
                    border: 1px solid #DCDCDC;
                    outline: none;
                    height: 30px;
                    line-height: 20px;
                    text-indent: 15px;
                    border-radius: 5px;
                    font-size: 14px;
                    margin: 15px 10px 15px 30px;
                    float: left;
                }
                .search_position_btn {
                    width: 70px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    font-size: 14px;
                    color: white;
                    border-radius: 5px;
                    float: left;
                    margin-top: 15px;
                    background-color: #3399FF;
                    cursor: pointer;
                }
                .search_position_select_wrap{
                    overflow: hidden;
                    padding: 0 0 0 30px;
                    border-bottom: solid 1px #DCDCDC;
                    user-select: none;
                    select{
                        border: solid 1px #DCDCDC;
                        border-radius: 4px;
                        margin: 7px 12px 7px 0;
                        width: 160px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 14px;
                        appearance: none;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        background-image: url(../../assets/souye/icon_dropdown.png);
                        background-size: 10px 5px;
                        background-repeat: no-repeat;
                        background-position: calc(100% - 5px) center;
                        padding-left: 5px;
                        padding-right: 15px;
                        float: left;
                        color: #858585;
                    }
                }
                .position_edit_wrap{
                    margin-top: 6px;
                    width: calc(100% - 70px);
                    height: 44px;
                    background: #F1F8FF;
                    border-radius: 4px;
                    clear: both;
                    margin-left: 30px;
                    box-sizing: border-box;
                    .level_str{
                        font-size: 12px;
                        height: 30px;
                        line-height: 30px;
                        margin: 7px 12px 7px 18px;
                        float: left;
                    }
                }
                .search_position_result_text{
                    font-size: 14px;
                    color: #666666;
                    height: 20px;
                    line-height: 20px;
                    margin: 18px 0 9px 0;
                    padding-left: 30px;
                }
            }
            .search_position_result_wrap{
                position: absolute;
                top: 256px;
                bottom: 80px;
                left: 0;
                width: 100%;
                overflow-y: scroll;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                .no_position_result{
                    // display: none;
                    margin-top: 50px;
                    text-align: center;
                    img{
                        width: 175px;
                        margin: 0 auto;
                    }
                }
                .infinite-list{
                    width: 508px;
                    margin: 0 auto;
                    .infinite-list-item{
                        border-bottom: 1px solid #ccc;
                        position: relative;
                        overflow: hidden;
                        user-select: none;
                        .icon_position{
                            float: left;
                            margin: 12px 8px 0 30px;
                            width: 12px;
                            height: 12px;
                            background-image: url(../../assets/souye/icon_position.png);
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }
                        .position_content{
                            float: left;
                            width: calc(100% - 120px);
                            .position_text{
                                font-size: 14px;
                                color: #333333;
                                margin-top: 8px;
                            }
                            .parent_position_text{
                                font-size: 12px;
                                color: #666666;
                                margin-top: 2px;
                            }
                            .parent_position_text{
                                font-size: 12px;
                                color: #666666;
                                margin-top: 2px;
                            }
                        }
                        .icon_check{
                            width: 24px;
                            height: 24px;
                            position: absolute;
                            right: 30px;
                            top: 50%;
                            transform: translateY(-50%);
                            background-size: 100% 100%;
                            background-image: url(../../assets/souye/icon_position_check_default.png);
                        }
                        .actim{
                            background-image: url(../../assets/souye/icon_position_check_active.png);
                        }
                    }
                }
            }
            .search_position_dialog_foot{
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 80px;
                border-top: solid 1px #DCDCDC;
                text-align: right;
                .position-btn {
                    display: inline-block;
                    margin-top: 16px;
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    color: white;
                    border-radius: 4px;
                    width: 150px;
                    user-select: none;
                }
                .ok-btn {
                    margin-right: 15px;
                    background: #3399FF;
                }
                .cancel-btn {
                    margin-right: 20px;
                    background: #FF4700;
                }
            }
        }
        // 
        .alert-dialog{
            position: fixed;
            top: 30%;
            width: calc(100% - 94px);
            max-width: 450px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 99;
            box-shadow: 0 1px 4px 0 #F0EFF5;
            border-radius: 4px;
            background: white;
            .alert-dialog-title{
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                color: #333333;
                border-bottom: solid 1px #F0EFF5;
                text-align: center;
                cursor: default;
                user-select: none;
            }
            .alert-dialog-content{
                padding: 20px;
                position: relative;
                box-sizing: border-box;
                min-height: 100px;
                overflow: hidden;
                border-bottom: solid 1px #F0EFF5;
                text-align: center;
                .alert-dialog-content-text{
                    line-height: 20px;
                    width: calc(100% - 40px);
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 14px;
                    position: absolute;
                    color:#FF3333;
                }
            }
            .alert-dialog-footer{
                height: 50px;
                line-height: 30px;
                font-size: 14px;
                position: relative;
                .qunren{
                    position: absolute;
                    top: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: #999999;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>