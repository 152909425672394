<template>
  <div class="main-wrap">
    <div class="content">
        <div class="inner-body">
            <div class="l-logo">
                <img src="../../assets/souye/iconlog.png" alt="">
            </div>
            <img src="../../assets/login/login_left_banner.png" alt="">
            <div class="wrap">
                <div class="title">
                    <span class="icon-css"><span></span></span>
                    <span><span class="blue">您好，</span>欢迎登录产品业务管理平台</span>
                </div>
                <div class="content-form">
                    <div class="login-type">
                        <span class="account-login" @click="logfrom.logtab=1" :class="{'active':logfrom.logtab==1}">渠道账密登录</span>
                        <span class="staff-account-login " @click="logfrom.logtab=2" :class="{'active':logfrom.logtab==2}">员工账密登录</span>
                    </div>
                    <div class="login">
                        <div class="form-item">
                            <input type="text" class="account" placeholder="请输入登录账号" v-model="logfrom.name">
                        </div>
                        <div class="form-item">
                            <input type="password" class="pwd" placeholder="请输入登录密码" v-model="logfrom.pwd">
                        </div>
                        <div class="form-item">
                            <input type="text" class="verify" placeholder="请输入验证码" v-model="logfrom.yanzheng">
                            <span class="sendVcode"><img src="../../assets/login/verify.png" alt=""></span>
                        </div>
                        <button class="btn" @click="denglu" type="submit">登录</button>
                    </div>
                </div>
            </div>
        </div>
            <!-- 公共尾部 -->
        <div class="foot-container">
            <div class="link-wrap">
                保镜科技有限公司 版权所有 
            <a href="##" style="cursor:pointer;color: #ffffff;"> 京ICP备17058300号-1</a>
            <div class="beian">
                <img style="width: 16px;vertical-align: middle;" src="../../assets/souye/wangan.png" alt="">
                <span style="display: inline-block;vertical-align: middle;">京公网安备 11010502038814号 | 未经许可不得转载</span>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            logfrom:{
                logtab:1,
                name:'',
                pwd:'',
                yanzheng:''
            }
        }
    },
    methods:{
        denglu(){
            if(this.logfrom.name===''){
                alert("登录帐号不能为空")
            }else if(this.logfrom.pwd===''){
                alert("密码不能为空")
            }else if(this.logfrom.yanzheng===''){
                alert("验证码不能为空")
            }else if(this.logfrom.yanzheng!="3381"){
                alert("验证码错误")
            }else if(this.logfrom.yanzheng==="3381"){
                if(this.logfrom.name != "保镜科技" || this.logfrom.pwd != 123456 ||this.logfrom.logtab !=1){
                    alert("帐号密码不匹配")
                }else {
                    this.$router.push('/home');  
                }
            }
        },
        denglujb(e){
            console.log(123);
            if(e.keyCode==13){
                this.denglu()
            }
        }

    },
    mounted(){
        window.addEventListener('keydown',this.denglujb)
    },
    destroyed(){
        window.removeEventListener('keydown',this.denglujb,false)
    }
}
</script>

<style  scoped lang="scss">
.main-wrap{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .content{
        margin: 0;
        padding: 0;
        color: #666666;
        background-color: white;
        background: url(../../assets/login/right_corner.png) no-repeat center;
        background-position: right bottom;
        background-size: 120px 164px;
        background-color: #eef2ff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 102px;
        .inner-body{
            width: 90%;
            margin: 0 auto;
            height: 100%;
            position: relative;
            img{
                height: 100%;
                vertical-align: middle;
            }
            .l-logo img{
                height: 80px;
                position: absolute;
                top: 25px;
                left: 0;
            }
            .wrap{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);  
                left: 50%;  
                width: 35%;
                display: inline-block;
                .title{
                    min-width: 320px;
                    font-size: 16px;
                    color: #151515;
                    letter-spacing: -0.56px;
                    font-weight: bold;
                    height: 60px;
                    line-height: 46px;
                    margin-bottom: 20px;
                    width: 100%;
                    .icon-css{
                        width: 46px;
                        height: 46px;
                        background-image: linear-gradient(45deg, #f1f5ff 2%, #edf1ff 100%);
                        box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.12), -4px -4px 8px 0 #ffffff;
                        border-radius: 46px;
                        float: left;
                        margin-right: 12px;
                        position: relative;
                        span{
                            position: absolute;
                            width: 22px;
                            height: 22px;
                            left: 12px;
                            top: 12px;
                            background-image: linear-gradient(-145deg, #fe7686 0%, #4a81fe 100%);
                            box-shadow: 2px 2px 4px 0 #8eb5db;
                            border-radius: 22px;
                        }
                    }
                    .blue{
                        color: #2a6ae9;
                    }
                }
                .content-form{
                    min-width: 320px;
                    min-height: 430px;
                    text-align: center;
                    background-image: linear-gradient(45deg, #f3f6ff 0%, #f2f5fe 100%);
                    box-shadow: -20px -20px 30px 0 #ffffff, 20px 20px 30px 0 rgba(0, 0, 0, 0.1);
                    border-radius: 12px;
                    overflow: hidden;   
                    .login-type{
                        color: #666666;
                        letter-spacing: -0.26px;
                        cursor: pointer;
                        margin-bottom: 40px;
                        span{
                            display: inline-block;
                            width: 50%;
                            height: 60px;
                            line-height: 60px;
                            text-align: center;
                            background: #e8ecfb;
                        }
                        .active {
                            background: none;
                            color: #333333;
                            font-weight: bold;
                        }
                    }
                    .login{
                        margin: 0 auto;
                        width: 100%;
                        .form-item{
                            line-height: 50px;
                            height: 50px;
                            margin-top: 5px;
                            position: relative;
                            margin-bottom: 15px;
                            width: 90%;
                            left: 5%;    
                            input{
                                border-radius: 6px;
                                width: 100%;
                                height: 50px;
                                padding-left: 40px;
                                box-sizing: border-box;
                                outline: none;
                                border: none;
                            }
                            .account{
                                background: url(../../assets/login/login.png) no-repeat left center #fff;
                                background-position: left 15px top 18px;
                                background-size: 14px;
                            }
                            .pwd{
                                background: url(../../assets/login/password.png) no-repeat left center #fff;
                                background-position: left 15px top 18px;
                                background-size: 14px;
                            }
                            .verify{
                                background: url(../../assets/login/security.png) no-repeat left center #fff;
                                background-position: left 15px top 18px;
                                background-size: 14px;
                                padding-top: 5px;
                            }
                            .sendVcode {
                                position: absolute;
                                width: 136px;
                                height: 50px;
                                font-size: 14px;
                                box-sizing: border-box;
                                color: #4b80fd;
                                border: none;
                                outline: none;
                                margin-left: 34px;
                                line-height: 50px;
                                right: 5%;
                                img{
                                    width: 80px;
                                    height: 30px;
                                    vertical-align: middle;
                                    margin-top: 10px;
                                }
                            }

                        }
                        .btn{
                            border: none !important;
                            outline: none;
                            width: 90%;
                            height: 50px;
                            background-image: linear-gradient(-135deg, #fe6f7f 0%, #4981ff 100%);
                            box-shadow: -8px -8px 8px 0 #ffffff, 8px 8px 8px 0 rgba(51, 51, 51, 0.1);
                            border-radius: 6px;
                            font-size: 16px;
                            color: #ffffff;
                            letter-spacing: 4.14px;
                            margin-top: 20px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        //公共尾部
        .foot-container{
            width: 100%;
            text-align: center;
            background-color: #333333;
            font-size: 12px;
            color: #ffffff;
            padding: 18px 0;
            line-height: 22px;
            .link-wrap{
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                .beian{
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}
</style>