<template>
  <div class="hzsy">
    <div class="img">
        <img src="../../assets/gywm/social-banner.png" alt="">
    </div>
    <div class="search">
        <div class="clearfix">
            <p for="">工作地点：</p>
            <div class="leibiao">
                <span @click="listTab(0)" :class="{'active':xiehuan===0}">不限</span>
                <span @click="listTab(1)" :class="{'active':xiehuan===1}">深圳</span>
                <span @click="listTab(2)" :class="{'active':xiehuan===2}">北京</span>
                <span @click="listTab(3)" :class="{'active':xiehuan===3}">上海</span>
                <span @click="listTab(4)" :class="{'active':xiehuan===4}">广州</span>
                <span @click="listTab(5)" :class="{'active':xiehuan===5}">天津</span>
                <span @click="listTab(6)" :class="{'active':xiehuan===6}">杭州</span>
                <span @click="listTab(7)" :class="{'active':xiehuan===7}">郑州</span>
                <span @click="listTab(8)" :class="{'active':xiehuan===8}">南京</span>
            </div>
            
        </div>
        <div class="xian"></div>
        <div class="clearfix">
            <p for="">职位列别：</p>
            <div class="leibiao">
                <span @click="xianzong(0)" :class="{'active':xzlx===0}">不限</span>
                <span @click="xianzong(1)" :class="{'active':xzlx===1}">人力资源类</span>
                <span @click="xianzong(2)" :class="{'active':xzlx===2}">项目管理类</span>
                <span @click="xianzong(3)" :class="{'active':xzlx===3}">保险类</span>
                <span @click="xianzong(4)" :class="{'active':xzlx===4}">金融类</span>
                <span @click="xianzong(5)" :class="{'active':xzlx===5}">行政后勤类</span>
            </div>
        </div>
        <div class="xian"></div>
    </div>
    <div class="sousuo">
        <input type="text" placeholder="可搜索职位名称，职位类别，专业公司，工作地点">
        <span>搜索</span>
    </div>
    <div class="commission-detail-wrap">
        <el-table
        :data="tuigxq"
        :header-cell-style="{'text-align':'center'}"
        border
        style="width: 100%">
        <el-table-column
            prop="name"
            align="center"
            label="职位名称">
        </el-table-column>
        <el-table-column
            prop="zygs"
            align="center"
            label="专业公司">
        </el-table-column>
        <el-table-column
            prop="zwlb"
            align="center"
            label="职位类别">
        </el-table-column>
        <el-table-column
            prop="gzdd"
            align="center"
            label="工作地点">
        </el-table-column>
        <el-table-column
            prop="zprs"
            align="center"
            label="招聘人数">
        </el-table-column>
        <el-table-column
            prop="sj"
            align="center"
            label="更新时间">
        </el-table-column>
        </el-table>
    </div>
    <!-- 公共尾部 -->
    <div class="foot-container">
        <div class="link-wrap">
                保镜科技有限公司 版权所有 
            <a href="javascript:;" style="cursor:pointer;color: #ffffff;"> 京ICP备17058300号-1</a>
            <div class="beian">
                <img style="width: 16px;vertical-align: middle;" src="../../assets/souye/wangan.png" alt="">
                <span style="display: inline-block;vertical-align: middle;">京公网安备 11010502038814号 | 未经许可不得转载</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
        xzlx:0,
        xiehuan:0,
        tuigxq:[
            {
                name:"寿险渠道专员",
                zygs:"陆控",
                zwlb:'金融类',
                gzdd:'唐山市',
                zprs:1,
                sj:'2024-04-23'
            },
            {
                name:"寿险渠道专员2团",
                zygs:"陆控",
                zwlb:'金融类',
                gzdd:'唐山市',
                zprs:3,
                sj:'2024-04-23'
            },
            {
                name:"客户经理",
                zygs:"平安产险",
                zwlb:'保险类',
                gzdd:'定西市',
                zprs:1,
                sj:'2024-04-23'
            },
            {
                name:"客户经理",
                zygs:"平安产险",
                zwlb:'保险类',
                gzdd:'定西市',
                zprs:1,
                sj:'2024-04-23'
            },
            {
                name:"客户经理",
                zygs:"平安产险",
                zwlb:'保险类',
                gzdd:'定西市',
                zprs:1,
                sj:'2024-04-23'
            },
            {
                name:"客户经理",
                zygs:"平安产险",
                zwlb:'保险类',
                gzdd:'定西市',
                zprs:1,
                sj:'2024-04-23'
            },
            
        ],
    };
  },
  computed:{
  },
  watch:{
  },
  methods: {
    listTab(i){
        this.xiehuan=i
    },
    xianzong(i){
        this.xzlx=i
    },
  },
};
</script>

<style scoped lang="scss">
.hzsy{
    margin-top: 80px;
    .img{
        width: 100%;
        height: 30%;
        img{
            width: 100%;
            height: 30%;
        }
    }
    .search{
            width: 100%;
            margin-top: 20px;
            background-color: #fff;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
            border-radius: 10px;
            overflow: hidden;
            text-align: left;
            .clearfix{
                overflow: hidden;
                font-size: 14px;
                color: #666666;
                line-height: 30px;
                padding-top: 12px;
                display: flex;
                flex-direction: row;
                white-space: nowrap;
                align-content: flex-start;
                flex-wrap: nowrap;
                align-items: flex-start;
                p{
                    font-size: 14px;
                    color: #333333;
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    margin-left: 24px;
                    margin-right: 24px;
                }
                .leibiao{
                    height: 42px;
                    overflow: hidden;
                    span{
                        background: #ffffff;
                        border: 1px solid #dcdcdc;
                        border-radius: 4px;
                        // float: left;
                        font-size: 12px;
                        width: 100px;
                        height: 30px;
                        text-align: center;
                        line-height: 30px;
                        margin-right: 12px;
                        cursor: pointer;
                        display: inline-block;
                        margin-bottom: 10px;
                    }
                    span:hover{
                        background: rgb(252, 103, 3);
                        border-radius: 4px;
                        color: #fff;
                        border: 1px solid #dfefff;
                    }
                    .active{
                        background: rgb(252, 103, 3);
                        border-radius: 4px;
                        color: #fff;
                        border: 1px solid #dfefff;
                    }
                }
   
            }
            .xian{
                width: 100%;
                height: 1px;
                border-bottom: 1px solid #F0EFF5;
            }
            .total{
                font-size: 14px;
                color: #666666;
                letter-spacing: -0.58px;
                height: 50px;
                line-height: 50px;
                padding-left: 24px;
                span{
                    color: rgb(252, 103, 3);
                }
            }
        }
    .sousuo{
        width: 60%;
        margin:  30px auto;
        display: flex;
        align-items: center;
        input{
            width: 90%;
            height: 40px;
            border: none;
            outline: none;
            text-indent: 10px;
        }
        span{
            margin-left: 5px;
            width: 84px;
            height: 40px;
            line-height: 37px;
            font-size: 12px;
            color: #fff;
            background: #424656;
            border-radius: 2px;
            text-align: center;
            cursor: pointer;
            display: inline-block;
            box-sizing: border-box;
        }
    }
}
/* 公共尾部 */  
.foot-container{
    width: 100%;
    text-align: center;
    background-color: #333333;
    font-size: 12px;
    color: #ffffff;
    padding: 18px 0;
    line-height: 22px;
    position: fixed;
    bottom: 0;
    z-index: 99;
    .link-wrap{
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        .beian{
            display: flex;
            align-items: center;
        }
    }
}
/* 修改表格样式 */ 
:deep .has-gutter .is-leaf{
  color: #666;
  font-size: 12px;
  background-color: #E5F2FF;
  height: 40px;
}
:deep .el-table .el-table__cell{
  margin: 0;
  padding: 0;
}
:deep .el-table__body-wrapper .el-table__cell{
  font-size: 12px;
  a{
    color: #428bca;
  }
}
:deep .el-table .cell  {
  padding: 0 5px;

}
:deep .el-table__row .cell{
  display: grid;  
  align-items: center;
  
}
:deep .el-table__row{
    height: 40px;
}
:deep .el-table th.el-table__cell>.cell{
  padding: 0 5px;
}
:deep .el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: rgb(244, 244, 244) !important;
} 
</style>
