<template>
  <div  class="xiaoxi">
    <div style="height: 60px;"></div>
    <div class="notice-pane">
        <div class="position">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><a href="/home">平台首页</a></el-breadcrumb-item>
                <el-breadcrumb-item  v-if="xxlb===1"><a href="/home/xiaoxi">消息通知</a></el-breadcrumb-item>
                <el-breadcrumb-item  v-if="xxlb===0">消息通知</el-breadcrumb-item>
                <el-breadcrumb-item v-if="xxlb===1">消息列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="noticebox" v-if="xxlb===0">
            <div class="notice_cat flex-sb">
                <div class="notice_cat_left flex-sb">
                    <img src="../../assets/xiaoxi/icon_tz1.jpg" alt="">消息通知
                </div>
            </div>
            <div class="notice_wrap">
                <div class="notice_search">
                    <div class="operation ">
                        <div class="fl form-item">
                            <input type="text" class="search-name-input fl" placeholder="请输入标题关键词">
                        </div>
                        <div class="fl form-item">
                            <span class="label fl">时间区间：</span>
                            <div class="fl form-data-wrap">
                                <div class="fl icon-arrow-wrap">
                                    <el-date-picker
                                        v-model="value1"
                                        type="date"
                                        placeholder="开始时间">
                                    </el-date-picker>
                                </div>
                                <div class="fl separator"> - </div>
                                <div class="fl icon-arrow-wrap">
                                    <el-date-picker
                                        v-model="value2"
                                        type="date"
                                        placeholder="结束时间">
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="fl form-item">
                            <div class="fl select-wrap icon-arrow-wrap ">
                                <select name="" id="">
                                    <!-- <option value="全部消息">全部消息</option> -->
                                    <option value="系统通知">系统通知</option>
                                    <!-- <option value="官网推送">官网推送</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="fl form-item">
                            <button class="fl btn search">搜索</button>
                            <button class="fl btn reset">重置</button>
                        </div>
                    </div>
                </div>
                <div class="list_notice">
                    <ul>
                        <li>
                            <div class="flex">
                                <div class="title">
                                    <span class="zt ">通知</span>
                                    <a href="javascript:;" @click="xxlb=1">【官方通知】SZ-太平洋雇主1-4类</a>
                                    <span class="time">2024-03-13 19:03:48</span>
                                </div>
                            </div>
                            <div class="desc">放假通知</div>
                        </li>
                        <li>
                            <div class="flex">
                                <div class="title">
                                    <span class="zt ">通知</span>
                                    <a href="">【官方通知】SZ-太平洋雇主1-4类</a>
                                    <span class="time">2024-03-13 19:03:48</span>
                                </div>
                            </div>
                            <div class="desc">放假通知</div>
                        </li>
                    </ul>
                </div>
                <div class="page-total">共 4 页 / 35 条数据</div>
            </div>
            <div class="page">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="34">
                </el-pagination>
            </div>
        </div>
        <div class="noticebox" v-if="xxlb===1">
            <div class="notice_cat flex-sb">
                <div class="notice_cat_left flex-sb">
                    <img @click="xxlb=0" src="../../assets/xiaoxi/nav_back.png" alt="">消息通知
                </div>
            </div>
            <div class="notice_wrap">
                <div class="notice-info">
                    <div class="title">SZ-太平洋雇主1-4类</div>
                    <div class="time">
                        <span class="public-com">发布者：超级管理员</span>
                        <span class="public-time">2024-03-13 19:03:48</span>
                    </div>
                    <div class="notice-body">
                        <p>
                            <span style="color: rgb(112, 116, 120); background-color: rgb(255, 255, 255); font-size: 24px;"></span>
                            <span style="color: rgb(225, 60, 57); background-color: rgb(255, 255, 255); font-size: 29px;">SZ-太平洋雇主1-4类</span>
                        </p>
                        <p>
                            <span style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255); font-size: 29px;">3月15日 17点</span>
                        </p>
                        <p>
                            <span style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255); font-size: 29px;">1、 关闭工标10%投保功能；</span>
                        </p>
                        <p>
                            <span style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255); font-size: 29px;">2、关闭山东，重庆省份投保；</span>
                        </p>
                        <p>
                            <span style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255); font-size: 29px;">3、关闭建筑行业，保洁行业投保；</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '消息',
  data() {
    return {
        value1:'',
        value2:'',
        xxlb:0,
    };
  },
  computed:{
  },
  watch:{
  },
  methods: {},
};
</script>

<style scoped lang="scss">

.xiaoxi{
    .notice-pane {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: calc(100% - 100px);
        padding: 10px;
        .position{
            padding: 10px 0;
            color: #999;
        }
    }
    .noticebox {
        background: white;
        box-shadow: 0 1px 0 0 #f0eff5;
        border-radius: 6px;
        .notice_cat {
            font-size: 18px;
            padding: 18px 24px;
            font-weight: bold;
            border-bottom: 1px solid #F0EFF5;
            img {
                width: 24px;
                vertical-align: middle;
                margin-right: 12px;
            }
        }
        .notice_wrap{
            padding: 0 50px;
            overflow: hidden;
            clear: both;
            .notice_search{
                margin: 25px 0;
                .operation {
                    overflow: hidden;
                    .form-item{
                        margin-right: 10px;
                        margin-bottom: 20px;
                        .search-name-input{
                            width: 290px;
                            height: 32px;
                            line-height: 32px;
                            border: 1px solid #DCDCDC;
                            border-radius: 4px;
                            padding: 0 10px;
                            margin-right: 10px;
                            font-size: 12px;
                            outline: none;
                        }
                        .label{
                            height: 32px;
                            line-height: 32px;
                            font-size: 12px;
                            font-weight: bold;
                            color: #333333;
                        }
                        .form-data-wrap{
                            height: 32px;
                            position: relative;
                            margin-left: 8px;
                            .separator{
                                padding: 0 4px;
                                height: 40px;
                                line-height: 40px;
                                font-size: 14px;
                                color: #333333;
                            }
                            .icon-arrow-wrap {
                                position: relative;

                            }

                            .icon-arrow-wrap::after{
                                content: '';
                                position: absolute;
                                width: 18px;
                                height: 10px;
                                top: 50%;
                                margin-top: -5px;
                                right: 12px;
                                background: url(../../assets/xiaoxi/icon_dropdown.png) no-repeat;
                                background-position: center center;
                                background-size: 100% 100%;
                            }
                        }
                        .select-wrap{
                            width: 130px;
                            height: 32px;
                            margin-left: 8px;
                            position: relative;
                            select {
                                width: 100%;
                                height: 32px;
                                border: 1px solid #DCDCDC;
                                border-radius: 4px;
                                font-size: 12px;
                                color: #333;
                                background: none;
                                appearance: none;
                                padding: 0 10px;
                                background-color: #FFF;
                                outline: none;
                            }
                        }
                        .select-wrap::after{
                                content: '';
                                position: absolute;
                                width: 18px;
                                height: 10px;
                                top: 50%;
                                margin-top: -5px;
                                right: 12px;
                                background: url(../../assets/xiaoxi/icon_dropdown.png) no-repeat;
                                background-position: center center;
                                background-size: 100% 100%;
                        }
                         .btn {
                            border: none;
                            width: 100px;
                            height: 32px;
                            text-align: center;
                            line-height: 32px;
                            border-radius: 6px;
                            color: #FFF;
                            font-size: 14px;
                            margin-right: 15px;
                            cursor: pointer;
                            user-select: none;
                        }
                        .btn.reset {
                            background-color: #FF4700;
                        }
                        .btn.search {
                            background-color: #3399FF;
                        }
                    }
                }
            }
            ul{
                li{
                    padding: 18px 0;
                    border-bottom: 1px solid #E3E1EE;
                    font-size: 12px;
                    .flex{
                        .title{
                            font-size: 16px;
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            span.zt {
                                width: 40px;
                                height: 17px;
                                line-height: 17px;
                                text-align: center;
                                display: inline-block;
                                border-radius: 2px;
                                color: #fff;
                                background: #39f;
                                font-size: 12px;
                                vertical-align: middle;
                                margin-top: -2px;
                            }
                            a{
                                color: #333;
                            }

                            span.time {
                                color: #999;
                                float: right;
                                font-size: 14px;
                            }
                        }
                        .title:hover a{
                            color: #39f;     
                        }
                    }
                    .desc{
                        color: #999;
                    }
                }
            }
            .page-total{
                font-size: 14px;
                color: #666666;
                letter-spacing: -0.58px;
                line-height: 50px;
            }
            .notice-info{
                padding: 15px 0 30px 0;
                .title{
                    font-size: 16px;
                    font-weight: bold;
                    margin: 15px 0;
                }
                .time{
                    .public-com{
                        padding: 0 0 15px 0;
                        font-size: 14px;
                        color: #111111;
                    }
                    .public-time{
                        margin-left: 20px;
                        font-size: 12px;
                        color: #666666;
                    }
                }
                .notice-body{
                    padding: 20px 0 20px 0;
                    font-size: 12px;
                    color: #666666;
                    line-height: 24px;
                }
            }
        }
        .page{
            line-height: 35px;
            margin: 10px auto;
            text-align: center;
        }

    }
}
.flex-sb {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.fl{
    float: left;
}
// 面包屑样式
:deep .el-breadcrumb {
    font-size: 12px;
}
// 时间选择器样式
:deep .el-date-editor.el-input{
    width: 142px;
    float: left;
    .el-input__inner {
        height: 34px;
        padding: 0 10px;
        color: #333;
    }
    .el-input__prefix{
        display: none;
    }
    .el-input--prefix .el-input__inne{
        padding: 0;
    }

}
</style>
