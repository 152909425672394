<template>
  <div class="liji">
    <div id="page-container">
        <div class="content">
            <div class="product_info">
                <div class="block-1">
                    <!-- 顶部保险名称 -->
                    <div class="title">
                        <div class="logo left"><img src="../../assets/souye/paic.jpg" alt=""></div>
                        <div class="left">
                            <div class="title_name">平安团体意外险（互联网）(JS)</div>
                            <div class="title_company">产品由中国平安财产保险股份有限公司承保并负责理赔</div>
                        </div>
                    </div>
                    <!-- 投保方案 -->
                    <div class="calc">
                        <div class="calc_title">
                            <div class="title_text">投保方案</div>
                        </div>
                        <div class="plan-item">
                            <div class="item-name">投保方案：</div>
                            <div class="option-container">
                                <div class="option " @click="list.a1=1" :class="{'active':list.a1===1}">行标/工标5</div>
                                <div class="option" @click="list.a1=2" :class="{'active':list.a1===2}">工标10</div>
                                <div style="clear:both;" class="zhuyis">
                                    <div style="margin-right:20px;font-size:12px;display:inline-block;">
                                        推广费：<span style="color:#FF3333;font-size:16px;">28%</span>
                                    </div>
                                    <div class="zhuyi">
                                        <img src="../../assets/souye/icon_remind.png" alt="" style="width:20px;height:20px;vertical-align:middle;">
                                        <span style="color:#999999;font-size:12px;margin-left:4px;">特别提示：选择不同方案会影响推广费，请认真核对。</span>                                        

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="plan-item w600">
                            <div class="item-name">投保人：</div>
                            <input type="text" placeholder="请填写单位名称">
                        </div>
                        <div class="plan-item">
                            <div class="item-name">地区等级：</div>
                            <div class="option-container">
                                <div class="option" @click="list.a2=1" :class="{'active':list.a2===1}">普通地区</div>
                                <div class="option" @click="list.a2=2" :class="{'active':list.a2===2}">风险地区</div>
                            </div>
                        </div>
                        <div class="plan-item">
                            <div class="item-name">保险期间：</div>
                            <div class="option-container">
                                <div class="option" @click="list.a3=1" :class="{'active':list.a3===1}">1年</div>
                                <div class="option" @click="list.a3=2" :class="{'active':list.a3===2}">1个月</div>
                                <div class="option" @click="list.a3=3" :class="{'active':list.a3===3}">3个月</div>
                                <div class="option" @click="list.a3=4" :class="{'active':list.a3===4}">6个月</div>
                            </div>
                        </div>
                        <div class="plan-item">
                            <div class="item-name">职业类别：</div>
                            <div class="option-container">
                                <div class="option" @click="list.a4=1" :class="{'active':list.a4===1}">一类</div>
                                <div class="option" @click="list.a4=2" :class="{'active':list.a4===2}">二类</div>
                                <div class="option" @click="list.a4=3" :class="{'active':list.a4===3}">三类</div>
                                <div class="option" @click="list.a4=4" :class="{'active':list.a4===4}">四类</div>
                                <div class="option" @click="list.a4=5" :class="{'active':list.a4===5}">五类</div>
                            </div>
                        </div>
                        <div class="plan-item">
                            <div class="item-name">保障内容：</div>
                            <div class="option-container">
                                <div class="option" @click="list.a5=1" :class="{'active':list.a5===1}">10万+1万</div>
                                <div class="option" @click="list.a5=2" :class="{'active':list.a5===2}">20万+2万</div>
                                <div class="option" @click="list.a5=3" :class="{'active':list.a5===3}">30万+3万</div>
                                <div class="option" @click="list.a5=4" :class="{'active':list.a5===4}">40万+4万</div>
                                <div class="option" @click="list.a5=5" :class="{'active':list.a5===5}">50万+5万</div>
                                <div class="option" @click="list.a5=6" :class="{'active':list.a5===6}">60万+6万</div>
                                <div class="option" @click="list.a5=7" :class="{'active':list.a5===7}">70万+7万</div>
                                <div class="option" @click="list.a5=8" :class="{'active':list.a5===8}">80万+8万</div>
                                <div class="option" @click="list.a5=9" :class="{'active':list.a5===9}">90万+9万</div>
                                <div class="option" @click="list.a5=10" :class="{'active':list.a5===10}">100万+10万</div>
                            </div>
                        </div>
                        <div class="plan-item">
                            <div class="item-name">意外住院津贴：</div>
                            <div class="option-container">
                                <div class="option" @click="list.a6=1" :class="{'active':list.a6===1}">不购买</div>
                                <div class="option" @click="list.a6=2" :class="{'active':list.a6===2}">50元/天</div>
                                <div class="option" @click="list.a6=3" :class="{'active':list.a6===3}">100元/天</div>
                                <div class="option" @click="list.a6=4" :class="{'active':list.a6===4}">150元/天</div>
                            </div>
                        </div>
                        <div class="plan-item">
                            <div class="item-name">伤残比例：</div>
                            <div class="option-container">
                                <div class="option" @click="list.a7=1" :class="{'active':list.a7===1}">行标十级10%起</div>
                                <div class="option" @click="list.a7=2" :class="{'active':list.a7===2}">工标10级5%起</div>
                                <div class="option active" :class="{'disabled':list.a1===1}">工标10级10%起</div>
                            </div>
                        </div>
                        <div class="plan-item">
                            <div class="item-name">民航客机意外：</div>
                            <div class="option-container">
                                <div class="option" @click="list.a8=1" :class="{'active':list.a8===1}">不购买</div>
                                <div class="option" @click="list.a8=2" :class="{'active':list.a8===2}">20万</div>
                                <div class="option" @click="list.a8=3" :class="{'active':list.a8===3}">50万</div>
                                <div class="option" @click="list.a8=4" :class="{'active':list.a8===4}">100万</div>
                            </div>
                        </div>  
                        <div class="plan-item">
                            <div class="item-name">客运轨道交通意外：</div>
                            <div class="option-container">
                                <div class="option" @click="list.a9=1" :class="{'active':list.a9===1}">不购买</div>
                                <div class="option" @click="list.a9=2" :class="{'active':list.a9===2}">10万</div>
                                <div class="option" @click="list.a9=3" :class="{'active':list.a9===3}">20万</div>
                                <div class="option" @click="list.a9=4" :class="{'active':list.a9===4}">30万</div>
                            </div>
                        </div> 
                        <div class="plan-item">
                            <div class="item-name">客运轨道交通意外：</div>
                            <div class="option-container">
                                <div class="option" @click="list.a10=1" :class="{'active':list.a10===1}">不购买</div>
                                <div class="option" @click="list.a10=2" :class="{'active':list.a10===2}">10万</div>
                                <div class="option" @click="list.a10=3" :class="{'active':list.a10===3}">20万</div>
                                <div class="option" @click="list.a10=4" :class="{'active':list.a10===4}">30万</div>
                            </div>
                        </div> 
                        <div class="plan-item">
                            <div class="item-name">客运轮船意外：</div>
                            <div class="option-container">
                                <div class="option" @click="list.a11=1" :class="{'active':list.a11===1}">不购买</div>
                                <div class="option" @click="list.a11=2" :class="{'active':list.a11===2}">10万</div>
                                <div class="option" @click="list.a11=3" :class="{'active':list.a11===3}">20万</div>
                                <div class="option" @click="list.a11=4" :class="{'active':list.a11===4}">30万</div>
                            </div>
                        </div> 
                        <div class="plan-item">
                            <div class="item-name">客运机动车意外：</div>
                            <div class="option-container">
                                <div class="option" @click="list.a12=1" :class="{'active':list.a12===1}">不购买</div>
                                <div class="option" @click="list.a12=2" :class="{'active':list.a12===2}">10万</div>
                                <div class="option" @click="list.a12=3" :class="{'active':list.a12===3}">20万</div>
                            </div>
                        </div> 
                    </div>
                    <!-- 保障权益 -->
                    <div class="clause">
                        <div class="clause_title">
                            <div class="title_text">保障权益</div>
                        </div>
                        <div class="clause_item">
                            <div class="item_title" @click="yiwais=!yiwais">
                                <div class="item_name">意外伤害（身故/残疾）</div>
                                <div class="item_value">
                                    <div class="accident_die">10万</div>
                                    <div class="dropdown">
                                        <img src="../../assets/souye/explain.png" alt="">
                                    </div>
                                    <img class="arrow-down" v-if="yiwais===true" src="../../assets/souye/graphical.png" alt="">
                                </div>
                            </div>
                            <div class="item_desc" v-if="yiwais===true">在保险期间内，被保险人因遭受意外伤害事故导致身故或伤残 保险人按照保单约定给付保险金。</div>
                        </div>
                        <div class="clause_item">
                            <div class="item_title" @click="yiwaiy=!yiwaiy">
                                <div class="item_name">意外医疗</div>
                                <div class="item_value">
                                    <div class="accident_die">1万</div>
                                    <div class="dropdown">
                                        <img src="../../assets/souye/explain.png" alt="">
                                    </div>
                                    <img class="arrow-down" v-if="yiwaiy===true" src="../../assets/souye/graphical.png" alt="">
                                </div>
                            </div>
                            <div class="item_desc" v-if="yiwaiy===true">每次事故绝对免赔100元，超过部分在医保核算范围按100%赔付。</div>
                        </div>
                        <div class="clause_item">   
                            <div class="item_title" @click="yiwaiz=!yiwaiz">
                                <div class="item_name">意外住院津贴</div>
                                <div class="item_value">
                                    <div class="accident_die"></div>
                                    <div class="dropdown">
                                        <img src="../../assets/souye/explain.png" alt="">
                                    </div>
                                    <img class="arrow-down" v-if="yiwaiz===true" src="../../assets/souye/graphical.png" alt="">
                                </div>
                            </div>
                            <div class="item_desc" v-if="yiwaiz===true">每次事故最高赔偿天数90天，保险期限内累计赔偿天数180天，绝对免赔3天。</div>
                        </div>
                    </div>
                </div>
                <ul class="navr">
                    <li @click="xq=1" :class="{'active':xq===1}"><a href="javascript:;">产品特色</a></li>
                    <li @click="xq=2" :class="{'active':xq===2}"><a href="javascript:;">投保须知</a></li>
                    <li @click="xq=3" :class="{'active':xq===3}"><a href="javascript:;">产品材料</a></li>
                    <li @click="xq=4" :class="{'active':xq===4}"><a href="javascript:;">客户告知书</a></li>
                    <li @click="xq=5" :class="{'active':xq===5}"><a href="javascript:;">对公账户</a></li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane" style="text-align: center" v-if="xq===1">
                        <img src="../../assets/souye/feature.jpg" alt="">
                    </div>
                    <div class="tab-pane" style="user-select: text; line-height: 24px;" v-if="xq===2">
                        <strong style="font-size: 16px;line-height: 24px;">投保须知</strong>
                        <div>1、本产品承保1-5类职业，若被保险人从事6类及拒保职业，保险公司不承担赔偿责任，职业分类详见平安《2014版职业类别表》。本保单附加《平安附加赔付比例特约保险》条款，保险事故发生时，若被保险人实际职业类别危险程度超出投保时职业类别时，保险公司不承担赔偿责任。单位名称中含以下关键词的不予承保：钢丝绳、钢铁、危险品、石油、幕墙、防腐、脚手架、疏浚、铁搭、拆、拆除工人、电梯、船舶、船厂、渔业、空调、通信、宽带、爆破、高压电、航空、桥隧工人、电力设施架设相关行业。本产品不承保钢骨结构工人、鹰架架设工人、钢结构安装工。</div>
                        <div>1、本产品承保1-5类职业，若被保险人从事6类及拒保职业，保险公司不承担赔偿责任，职业分类详见平安《2014版职业类别表》。本保单附加《平安附加赔付比例特约保险》条款，保险事故发生时，若被保险人实际职业类别危险程度超出投保时职业类别时，保险公司不承担赔偿责任。单位名称中含以下关键词的不予承保：钢丝绳、钢铁、危险品、石油、幕墙、防腐、脚手架、疏浚、铁搭、拆、拆除工人、电梯、船舶、船厂、渔业、空调、通信、宽带、爆破、高压电、航空、桥隧工人、电力设施架设相关行业。本产品不承保钢骨结构工人、鹰架架设工人、钢结构安装工。</div>
                        <div>1、本产品承保1-5类职业，若被保险人从事6类及拒保职业，保险公司不承担赔偿责任，职业分类详见平安《2014版职业类别表》。本保单附加《平安附加赔付比例特约保险》条款，保险事故发生时，若被保险人实际职业类别危险程度超出投保时职业类别时，保险公司不承担赔偿责任。单位名称中含以下关键词的不予承保：钢丝绳、钢铁、危险品、石油、幕墙、防腐、脚手架、疏浚、铁搭、拆、拆除工人、电梯、船舶、船厂、渔业、空调、通信、宽带、爆破、高压电、航空、桥隧工人、电力设施架设相关行业。本产品不承保钢骨结构工人、鹰架架设工人、钢结构安装工。</div>
                        <div>1、本产品承保1-5类职业，若被保险人从事6类及拒保职业，保险公司不承担赔偿责任，职业分类详见平安《2014版职业类别表》。本保单附加《平安附加赔付比例特约保险》条款，保险事故发生时，若被保险人实际职业类别危险程度超出投保时职业类别时，保险公司不承担赔偿责任。单位名称中含以下关键词的不予承保：钢丝绳、钢铁、危险品、石油、幕墙、防腐、脚手架、疏浚、铁搭、拆、拆除工人、电梯、船舶、船厂、渔业、空调、通信、宽带、爆破、高压电、航空、桥隧工人、电力设施架设相关行业。本产品不承保钢骨结构工人、鹰架架设工人、钢结构安装工。</div>
                    </div>
                    <div class="tab-panes" v-if="xq===3">
                        <div class="file">
                            <div class="icon-file"><img src="../../assets/souye/file.png" alt=""></div>
                            <a class="file-name" href="javascript:;">平安产险意外伤害保险（互联网版）条款.pdf</a>
                        </div>
                        <div class="file">
                            <div class="icon-file"><img src="../../assets/souye/file.png" alt=""></div>
                            <a class="file-name" href="javascript:;">平安产险附加意外伤害住院津贴保险（互联网版）条款.pdf</a>
                        </div>
                        <div class="file">
                            <div class="icon-file"><img src="../../assets/souye/file.png" alt=""></div>
                            <a class="file-name" href="javascript:;">平安产险附加意外伤害医疗费用补偿保险（互联网版）条款.pdf</a>
                        </div>
                        <div class="file">
                            <div class="icon-file"><img src="../../assets/souye/file.png" alt=""></div>
                            <a class="file-name" href="javascript:;">平安产险交通工具意外伤害保险（互联网版）条款.pdf</a>
                        </div>                        
                        <div class="file">
                            <div class="icon-file"><img src="../../assets/souye/file.png" alt=""></div>
                            <a class="file-name" href="javascript:;">平安产险附加及时通知保险条款.pdf</a>
                        </div>
                        <div class="file">
                            <div class="icon-file"><img src="../../assets/souye/file.png" alt=""></div>
                            <a class="file-name" href="javascript:;">职业类别表</a>
                        </div>
                    </div>
                    <div  class="tab-panes" style="user-select: text; line-height: 24px;" v-if="xq===4">
                        尊敬的投保人： <br>
                        感谢您在我公司（北京宏利保险代理有限公司）办理保险业务。为了保护您的合法权益，按照《保险法》、《保险代理人监管规定》、《互联网保险业务监管办法》等法律法规的要求，本公司应履行客户告知义务，现将有关事项告知如下，请仔细阅读。 <br>
                        一、公司基本信息 <br>
                        （一）公司名称：北京宏利保险代理有限公司 <br>
                        （二）公司住所地：北京市朝阳区阜通东大街1号院6号楼9层3单元231008<br>
                        （三）保险中介业务许可证编码：【201981000000800】 <br>
                        （四）许可证有效期：长期 <br>
                        （五）业务范围：在全国区域内（港、澳、台除外）代理销售保险产品；代理收取保险 费；代理相关保险业务的损失勘查和理赔；中国银保监会批准的其他业务。 <br>

                    </div>
                    <div class="tab-panes" v-if="xq===5">
                        户名：中国平安财产保险股份有限公司江苏分公司<br>
                        开户行：中国银行股份有限公司南京城南支行<br>
                        账号： 550878888602 <br>
                        联行号：104301005017 
                    </div>
                </div>
                <div style="height: 50px;"></div>
            </div>
            <div class="immediatelyJoin">
                <div class="clearfix" style="width: 1160px;margin: 0 auto;">
                    <div class="apply_btn" style="float: right;" @click="ljtb">立即投保</div>
                    <div class="money">
                        <em class="premium_title">保险费：</em>
                        <span>102</span>
                        <em class="unit">元/人</em>
                    </div>
                </div>
                <div class="xiangshang" @click="wtop" v-show="gotop" >^</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            xq:1,
            v1:this.$route.query.id,
            yiwais:false,
            yiwaiy:false,
            yiwaiz:false,
            gotop: false,
            list:{
                a1:1,
                a2:1,
                a3:1,
                a4:1,
                a5:1,
                a6:1,
                a7:1,
                a8:1,
                a9:1,
                a10:1,
                a11:1,
                a12:1,
            }
        }
    },
    mounted() {
        // 此处true需要加上，不加滚动事件可能绑定不成功
        window.addEventListener("scroll", this.handleScroll, true);
    },
    methods :{
        // 当顶部为300px是显示和隐藏
        handleScroll() {
            let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
            scrolltop > 300 ? (this.gotop = true) : (this.gotop = false);
        },
        // 缓慢回到顶部
        wtop(){
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            const timeTop = setInterval(() => {
            document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
            if (top <= 0) {
                clearInterval(timeTop);
                }
            }, 10);
        },
        // 点击跳转并且传值
        ljtb(){
            const paramValue = this.v1; // 你要传递的值
            this.$router.push({name: 'toubao', params: { param: paramValue } })
        }
    }
}
</script>

<style  scoped lang="scss">
.left {
    float: left;
}
.w600{
    width: 100%;
    max-width: 600px;
}
.liji{
    height: 100%;
    font-size: 14px;
    background: #F2F2F2;
    #page-container{
        width: 1160px;
        margin-left: auto;
        margin-right: auto;
        min-width: 800px;
        padding-top: 24px;
        .content{
            .product_info{
                width: 100%;
                padding: 0;
                box-sizing: border-box;
                margin: 0 auto;
                box-shadow: #eee 0 0 10px;
                text-decoration: none;
                background: transparent;
                cursor: default;
                user-select: none;
                .block-1{
                    border-radius: 10px;
                    overflow: hidden;
                    //顶部保险名称
                    .title{
                        padding: 20px 40px 20px 24px;
                        overflow: hidden;
                        border-bottom: 1px solid #F0EFF5;
                        background-color: #FFF;
                        .logo{
                            width: 60px;
                            height: 60px;
                            margin-right: 15px;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .title_name {
                            margin-top: 5px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #333;
                        }
                        .title_company{
                            margin-top: 5px;
                            font-size: 12px;
                            font-weight: 400;
                            color: #999;
                        }
                    }
                    //投保方案
                    .calc{
                        background: white;
                        overflow: hidden;
                        .calc_title{
                            padding: 17px 24px;
                            border-bottom: 1px solid #F0EFF5;
                            .title_text{
                                height: 14px;
                                line-height: 14px;
                                padding-left: 10px;
                                border-left: solid 4px #fc6703;
                                color: #333;
                                font-weight: 400;
                                font-size: 14px;
                            }
                        }
                        .plan-item {
                            padding-top: 10px;
                            vertical-align: top;
                            overflow: hidden;
                            .item-name{
                                width: 120px;
                                font-size: 12px;
                                color: #333333;
                                font-weight: 400;
                                margin-left: 24px;
                                padding: 5px 0;
                                line-height: 20px;
                                float: left;
                                position: relative;
                                cursor: default;
                            }
                            .option-container{
                                width: calc(100% - 160px);
                                margin-left: 10px;
                                overflow: hidden;
                                float: left;
                                .option{
                                    min-width: 100px;
                                    border-radius: 4px;
                                    text-align: center;
                                    font-size: 12px;
                                    line-height: 20px;
                                    color: #666666;
                                    box-sizing: border-box;
                                    border: 1px solid #DCDCDC;
                                    margin-right: 15px;
                                    margin-bottom: 10px;
                                    cursor: pointer;
                                    padding: 5px 15px;
                                    float: left;
                                }
                                .active{
                                    background-color: white;
                                    border: 1px solid #fc6703;
                                    color: #fc6703;
                                    background-image: url(../../assets/souye/icon.png);
                                    background-position: 110% -150%;
                                    background-size: 25px 25px;
                                    background-repeat: no-repeat;
                                }
                                .zhuyi{
                                    float: left;
                                    display: flex;
                                    align-items: center;
                                    background-color: #FFF7F8;
                                    color: #666666;
                                    font-size: 14px;
                                    padding: 9px;
                                    border-radius: 10px;
                                }
                                .zhuyis{
                                    float: left;
                                    display: flex;
                                    align-items: center;
                                }
                                .disabled{
                                    background: #DCDCDC;
                                    border: #DCDCDC solid 1px;
                                    color: white;
                                    cursor: not-allowed;
                                }
                            }
                            input{
                                width: calc(100% - 220px);
                                line-height: 30px;
                                height: 30px;
                                border: solid 1px #DCDCDC;
                                outline: none;
                                border-radius: 4px;
                                padding: 0 5px;
                                margin-left: 10px;
                            }
                        }
                    }
                    //保障权益
                    .clause{
                        background: white;
                        .clause_title{
                            padding: 17px 24px;
                            border-bottom: 1px solid #F0EFF5;
                            border-top: 1px solid #F0EFF5;
                            .title_text{
                                height: 14px;
                                line-height: 14px;
                                padding-left: 10px;
                                border-left: solid 4px #fc6703;
                                color: #333;
                                font-weight: 400;
                                font-size: 13px;
                            }
                        }
                        .clause_item {
                            .item_title{
                                padding: 9px 16px;
                                overflow: hidden;
                                border-bottom: 1px solid #F0EFF5;
                                .item_name{
                                float: left;
                                width: 50%;
                                font-size: 12px;
                                color: #333;
                                }
                                .item_value{
                                    float: right;
                                    position: relative;
                                    padding-right: 20px;
                                    text-align: right;
                                    .accident_die{
                                        padding: 2px 4px 1px 4px;
                                        font-size: 14px;
                                        color: #fc6703;
                                        border-radius: 4px;
                                    }
                                    .dropdown{
                                        position: absolute;
                                        right: 0;
                                        top: 2px;
                                        height: 18px;
                                        width: 18px;
                                        img{
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                    .arrow-down{
                                        position: absolute;
                                        width: 14px;
                                        height: 8px;
                                        z-index: 100;
                                        bottom: -9px;
                                        right: 2px;
                                        display: none;
                                    }
                                }
                            }
                            .item_desc{
                                padding: 5px 16px;
                                line-height: 20px;
                                font-size: 12px;
                                color: #999;
                                background-color: #F0EFF5;
                            }

                        }
                    }
                }
                .navr{
                    margin-top: 14px;
                    background-color: transparent;
                    overflow: hidden;
                    li{
                        width: 140px;
                        text-align: center;
                        height: 40px;
                        line-height: 40px;
                        float: left;
                        a{
                            margin-right: 5px;
                            line-height: 20px;
                        }

                    }
                    .active{
                        color: #fc6703;
                        position: relative;
                        background-color: #FFF;
                        border-radius: 10px 10px 0 0;
                    }
                    .active::after{
                        content: '';
                        position: absolute;
                        width: 30px;
                        height: 2px;
                        background-color: #fc6703;
                        bottom: 0;
                        left: 50%;
                        margin-left: -15px;
                    }
                    li:hover{
                        color: #fc6703;
                        position: relative;
                        background-color: #FFF;
                        border-radius: 10px 10px 0 0;
                        a::after{
                            content: '';
                            position: absolute;
                            width: 30px;
                            height: 2px;
                            background-color: #fc6703;
                            bottom: 0;
                            left: 50%;
                            margin-left: -15px;
                        }
                    }
                }
                .tab-content{
                    border-radius: 0 10px 10px 10px; 
                    padding: 15px;
                    margin-bottom: 30px;
                    background: #fff;
                    .tab-pane{
                        text-align: center;
                        
                        img{
                            max-width: 100%;
                            height: auto;
                            margin: 0 auto;
                        }
                    }
                    
                }
                .tab-panes{
                    border-radius: 0 10px 10px 10px; 
                    padding: 15px;
                    margin-bottom: 30px;
                    .file{
                        user-select: none;
                        line-height: 40px;
                        border-bottom: solid 1px #DCDCDC;
                        box-sizing: border-box;
                        font-size: 16px;
                        color: #333;
                        overflow: hidden;
                        .icon-file{
                            width: 14px;
                            height: 16px;
                            margin-top: 12px;
                            margin-right: 10px;
                            float: left;
                            font-size: 0;
                            img{
                                width: 100%;
                                height: 100%;
                                vertical-align: top;
                            }
                        }
                        .file-name{
                            float: left;
                            color: #333;
                            text-decoration: none;
                        }
                    }
                }
            }
            .immediatelyJoin{
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 60px;
                background: white;
                .clearfix{
                    .apply_btn{
                        width: 160px;
                        height: 45px;
                        line-height: 45px;
                        margin-top: 7.5px;
                        background-color: #fc6703;
                        color: #FFF;
                        font-size: 16px;
                        text-align: center;
                        cursor: pointer;
                        user-select: none;
                        border-radius: 6px;
                    }
                    .money{
                        color: #666;
                        font-size: 16px;
                        margin-top: 12px;
                        float: right;
                        margin-right: 30px;
                        .premium_title{
                            font-style: normal;
                        }
                        span{
                            margin-top: 0px;
                            font-size: 20px;
                            color: #FF3333;
                            position: relative;
                            bottom: -1px;
                        }
                        .unit{
                            font-style: normal;
                            font-size: 14px;
                            color: #FF3333;
                            position: relative;
                            bottom: 0px;
                            left: 2px;
                        }
                    }
                }
                .xiangshang{
                    width: 28px;
                    height: 28px;
                    color: #fff;
                    background: #00acac;
                    border-color: #00acac;
                    position: absolute;
                    right: 40px;
                    top: 20px;
                    display: flex;
                    justify-content: center;
                    font-size: 14px;
                    border-radius: 50%;
                    padding-top: 8px;
                    box-sizing: border-box;
                    cursor: pointer;
                }
            }

        }
    }
}

</style>