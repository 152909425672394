<template>
  <div class="hzsy">
    <div class="hzsy-top">
        <h2>
            <p>200000+</p>
            <p>家客户倾心之选</p>
        </h2>
        <p style="font-size: 20px;width: 70%;">我们注重客户需求，提供量身定制的保险解决方案，帮助客户在风险来临时获得及时的经济支持和保障。 作为一家专业的保险公司，我们拥有一支经验丰富、专业精湛的团队，他们深谙保险行业的运作规律，能够为客户提供专业的风险评估和咨询服务。我们坚持以客户为中心，不断提升服务质量和客户满意度，赢得了广大客户的信赖和好评。 </p>
    </div>
    <div class="jrwm">
        <div class="jrwms">加入我们</div>
        <div>
            <input type="text" placeholder="姓名">
        </div>
        <div>
            <input type="text" placeholder="手机号">
        </div>
        <div>
            <input type="text" placeholder="公司名称">
        </div>
        <div class="tj">提交</div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
    };
  },
  computed:{
  },
  watch:{
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.hzsy{
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/gywm/case_banner.jpg);
    background-size: 100% 100%;
    box-sizing: border-box;
    .hzsy-top{
        width: 80%;
        margin: 0 auto;
        display: flex;
        padding-top: 160px;
        color: #fff;
        h2{
            width: 30%;
            font-size: 40px;
        }
    }
    .jrwm{
        width: 40%;
        height: 60%;
        margin: 50px auto;
        background-color: #fff;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 50px;
        .jrwms{
            width: 100%;
            text-align: center;
            font-size: 30px;
        }
        input{
            width: 100%;
            height: 40px;
            margin-top: 40px;
            text-indent: 20px;
            outline: none;
        }
        .tj{
            width: 200px;
            height: 40px;
            border-radius: 10px;
            background-color: rgb(252, 103, 3);
            color: #fff;
            border: none;
            margin:50px auto;
            line-height: 40px;
            text-align: center;
        }
    }
}
</style>
