<template>
  <div class="gywm">
    <img src="../../assets/gywm/img.jpg" alt="" class="img">
    <div class="jtjs">
        <div class="jtjs-top">
            <div class="text">集团介绍</div>
            <div class="yingwen">BaoJing Tech</div>
        </div>
        <div class="jjname">
            保险公司是一家专注于为客户提供全方位保险服务的金融机构。我们致力于通过创新的保险产品和专业的风险管理，帮助客户在面临不确定的风险时能够安心前行。
            我们拥有丰富的保险产品线，涵盖人寿保险、财产保险、健康保险等多个领域，满足不同客户的个性化需求。我们注重客户需求，提供量身定制的保险解决方案，帮助客户在风险来临时获得及时的经济支持和保障。
            作为一家专业的保险公司，我们拥有一支经验丰富、专业精湛的团队，他们深谙保险行业的运作规律，能够为客户提供专业的风险评估和咨询服务。我们坚持以客户为中心，不断提升服务质量和客户满意度，赢得了广大客户的信赖和好评。
            在未来，我们将继续秉承“客户至上”的经营理念，不断创新和完善保险产品与服务，为客户提供更加全面、优质的保险保障，助力客户实现财富增值和人生安全。
            在未来，我们将继续秉承“客户至上”的经营理念，不断创新和完善保险产品与服务，为客户提供更加全面、优质的保险保障，助力客户实现财富增值和人生安全。
            在未来，我们将继续秉承“客户至上”的经营理念，不断创新和完善保险产品与服务，为客户提供更加全面、优质的保险保障，助力客户实现财富增值和人生安全。
            在未来，我们将继续秉承“客户至上”的经营理念，不断创新和完善保险产品与服务，为客户提供更加全面、优质的保险保障，助力客户实现财富增值和人生安全。
        </div>
    </div>
        <!-- 公共尾部 -->
        <div class="foot-container">
        <div class="link-wrap">
                保镜科技有限公司 版权所有 
            <a href="javascript:;" style="cursor:pointer;color: #ffffff;"> 京ICP备17058300号-1</a>
            <div class="beian">
                <img style="width: 16px;vertical-align: middle;" src="../../assets/souye/wangan.png" alt="">
                <span style="display: inline-block;vertical-align: middle;">京公网安备 11010502038814号 | 未经许可不得转载</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
    };
  },
  computed:{
  },
  watch:{
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.gywm{
    margin-top: 50px;
    .img{
        width: 100%;
        margin: 0 auto;
    }
    .jtjs{
        width: 80%;
        margin: 30px auto;
        display: flex;

        .jtjs-top{
            width: 20%;
            margin-top: 100px;
            .text{
                font-weight: bold;
                color: #000;
                font-size: 35px;
            }
            .yingwen{
                color: #000;
                font-size: 30px;
            }
        }
        .jjname{
            width: 80%;
            color: #000;
            line-height: 2.2;
            font-weight: 900;
            font-size: 16px;
        }
    }
}
/* 公共尾部 */  
.foot-container{
    width: 100%;
    text-align: center;
    background-color: #333333;
    font-size: 12px;
    color: #ffffff;
    padding: 18px 0;
    line-height: 22px;
    position: fixed;
    bottom: 0;
    .link-wrap{
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        .beian{
            display: flex;
            align-items: center;
        }
    }
}
</style>
